import styled from 'styled-components';

interface ColorItem {
  id: number;
  color: string;
}

interface ColorPaletteProps {
  className?: string;
  data?: ColorItem[];
}
const ColorPalette = ({ data = [], className }: ColorPaletteProps) => (
  <div className={className}>
    {data.map(({ id, color }) => (
      <div key={id} style={{ backgroundColor: color }} />
    ))}
  </div>
);

ColorPalette.defaultProps = {
  className: '',
  data: [],
};

const ColorPaletteStyled = styled(ColorPalette)`
  display: flex;
  gap: 0.0625rem;
  margin-bottom: 1rem;
  height: 1.5rem;
  border-radius: 0.1875rem;
  overflow: hidden;

  div {
    flex: 1;
    height: 100%;
  }
`;

export default ColorPaletteStyled;
