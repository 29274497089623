import styled from 'styled-components';

import { ReactComponent as IconExternalLink } from '../../images/icons/external-link.svg';

interface Props {
  className?: string;
  title?: string;
  subtitle?: string;
  linkUrl?: string;
}

const ExternalLink = ({ className, title, subtitle, linkUrl }: Props) => (
  <a href={linkUrl} target="_blank" className={className} rel="noreferrer">
    <div>
      <span className="title">{title}</span>
      <p>{subtitle}</p>
    </div>
    <IconExternalLink className="icon-link" />
  </a>
);

ExternalLink.defaultProps = {
  className: '',
  title: '',
  subtitle: '',
  linkUrl: '',
};

const ExternalLinkStyled = styled(ExternalLink)`
  border-radius: 0 0 0.5rem 0.5rem;
  background-color: var(--f2f3f4, #f2f3f4);
  border-top: 0.125rem solid var(--b3b8bf, #b3b8bf);
  padding: 0.875rem 1rem 1rem;
  text-decoration: none;
  color: var(--5c636e, #5c636e);
  min-height: 10.25rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  transition:
    background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out,
    box-shadow 0.3s ease-in-out;

  .title {
    font-size: 1rem;
    line-height: 1.25;
    letter-spacing: -0.01rem;
    font-weight: 600;
    display: block;
    margin-bottom: 0.5rem;
    margin-top: 0;
    transition: color 0.3s ease-in-out;
    color: var(--5c636e, #5c636e);
  }

  .icon-link {
    color: var(--2d6e8d, #2d6e8d);
    align-self: flex-end;
  }

  @media (hover: hover) {
    &:hover {
      border-top: 0.125rem solid var(--2685a1, #2685a1);
      background-color: var(--ffffff, #ffffff);
      box-shadow: 0 0 0.5rem 0.125rem var(--141c2c0f, #141c2c0f);

      .title {
        color: var(--005770, #005770);
      }
    }
  }
`;

export default ExternalLinkStyled;
