import styled from 'styled-components';

const backgroundColors: { [key: string]: string } = {
  primary: 'var(--00143f, #00143f)',
  secondary: 'var(--2d6e8d1f, #2d6e8d1f)',
  default: 'var(--5f687714, #5f687714)',
};

const backgroundHoverColors: { [key: string]: string } = {
  primary: 'var(--000631, #000631)',
  secondary: 'var(--2d6e8d33, #2d6e8d33)',
  default: 'var(--5f68771f, #5f68771f)',
};

const backgroundActiveColors: { [key: string]: string } = {
  primary: 'var(--000631, #000631)',
  secondary: 'var(--2d6e8d3d, #2d6e8d3d)',
  default: 'var(--5f687729, #5f687729)',
};

const backgroundDisabledColors: { [key: string]: string } = {
  primary: 'var(--5f687714, #5f687714)',
  secondary: 'var(--eff0f1, #eff0f1)',
  default: 'var(--fff, #fff)',
};

const textColors: { [key: string]: string } = {
  primary: 'var(--fff, #fff)',
  secondary: 'var(--155676, #155676)',
  default: 'var(--000005, #000005)',
};

const textHoverColors: { [key: string]: string } = {
  primary: 'var(--81a8bb, #81a8bb)',
  secondary: 'var(--003e5f, #003e5f)',
  default: 'var(--000005, #000005)',
};

const textActiveColors: { [key: string]: string } = {
  primary: 'var(--578ba4, #578ba4)',
  secondary: 'var(--003e5f, #003e5f)',
  default: 'var(--000005, #000005)',
};

const lineColors: { [key: string]: string } = {
  primary: 'var(--9fa4ad66, #9fa4ad66)',
  secondary: 'var(--2d6e8d33, #2d6e8d33)',
  default: 'var(--9fa4ad66, #9fa4ad66)',
};

const buttonSizes: { [key: string]: string } = {
  XS: '0.25rem 0.5rem',
  S: '0.375rem 0.625rem',
  M: '0.625rem',
};

const buttonRadius: { [key: string]: string } = {
  XS: '0.1875rem',
  S: '0.25rem',
  M: '0.375rem',
};

const fontSizes: { [key: string]: string } = {
  XS: '0.75rem',
  S: '0.875rem',
  M: '0.875rem',
};

const lineHeights: { [key: string]: string } = {
  XS: '1.333333333333333',
  S: '1.428571428571429',
  M: '1.428571428571429',
};

const Button = styled.button<{ $primary?: boolean; color?: string; size?: string }>`
  background-color: ${(props) => backgroundColors[props.color || 'primary']};
  font-size: ${(props) => fontSizes[props.size || 'primary']};
  line-height: ${(props) => lineHeights[props.size || 'primary']};
  font-weight: 500;
  padding: ${(props) => buttonSizes[props.size || 'M']};
  border: none;
  border-radius: ${(props) => buttonRadius[props.size || 'M']};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1.25rem;
  position: relative;
  transition: background-color 0.3s ease-in-out;

  .button {
    &-name {
      color: ${(props) => textColors[props.color || 'primary']};
      transition: color 0.3s ease-in-out;
      text-wrap: nowrap;
    }

    &-icon {
      color: ${(props) => textColors[props.color || 'primary']};
      transition: color 0.3s ease-in-out;
      display: flex;
      align-items: center;

      &::before {
        content: '';
        background-color: ${(props) => lineColors[props.color || 'primary']};
        position: absolute;
        width: 0.0625rem;
        top: 50%;
        height: 1rem;
        margin-left: -0.5625rem;
        transform: translateY(-50%);
        transition:
          top 0.3s ease-in-out,
          height 0.3s ease-in-out;
      }
    }
  }

  &:hover {
    background-color: ${(props) => backgroundHoverColors[props.color || 'primary']};

    .button {
      &-name {
        color: ${(props) => textHoverColors[props.color || 'primary']};
      }

      &-icon {
        color: ${(props) => textHoverColors[props.color || 'primary']};

        &::before {
          height: 100%;
        }
      }
    }
  }

  &:active {
    background-color: ${(props) => backgroundActiveColors[props.color || 'primary']};

    .button {
      &-name {
        color: ${(props) => textActiveColors[props.color || 'primary']};
      }

      &-icon {
        color: ${(props) => textActiveColors[props.color || 'primary']};
      }
    }
  }

  &:disabled {
    background-color: ${(props) => backgroundDisabledColors[props.color || 'primary']};
    cursor: not-allowed;
    pointer-events: none;

    .button {
      &-name {
        color: var(--9fa4ad, #9fa4ad);
      }

      &-icon {
        color: var(--9fa4ad, #9fa4ad);

        &::before {
          background-color: var(--9fa4ad33, #9fa4ad33);
        }
      }
    }
  }
`;

export default Button;
