import styled from 'styled-components';

interface Props {
  className?: string;
  id?: number;
  title?: string;
  image?: string;
  icon?: React.ReactNode;
}

const Chart = ({ className, id, image, title, icon }: Props) => (
  <div className={className} key={id}>
    <img src={image} alt="" />
    <div className="info">
      <div className="icon">{icon}</div>
      <span>{title}</span>
    </div>
  </div>
);

Chart.defaultProps = {
  className: '',
  id: 0,
  title: '',
  image: '',
  icon: null,
};

const ChartStyled = styled(Chart)`
  border: 0.0625rem solid var(--dfe1e4, #dfe1e4);
  overflow: hidden;
  border-radius: 0.5rem;

  .info {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 0.75rem;
    background: var(--5f687714, #5f687714);
    font-size: 1rem;
    line-height: 1.25;
    color: var(--030404, #030404);
  }

  .icon {
    color: var(--2d6e8d, #2d6e8d);
    padding: 0.125rem;
    background: var(--fff, #fff);
    border-radius: 0.25rem;
    box-shadow: 0rem 0.125rem 0.25rem 0.0625rem var(--141c2c0a, #141c2c0a);
  }

  img {
    margin: 0 auto;
    display: block;
    max-width: 100%;
    height: auto;
  }

  svg {
    display: block;
  }
`;

export default ChartStyled;
