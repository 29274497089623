import Scrollspy from 'react-scrollspy-ez';
import styled from 'styled-components';

import BadgeStyled from '../../../components/Badge';
import ContentHeaderStyled from '../../../components/ContentHeader';
import ExternalLinkGroupStyled from '../../../components/ExternalLinkGroup';
import Header from '../../../components/Header';
import PaginationStyled from '../../../components/Pagination';
import SidebarStyled from '../../../components/Sidebar';
import { ReactComponent as IconDanger } from '../../../images/icons/icon-danger.svg';
import { ReactComponent as IconSuccess } from '../../../images/icons/icon-success.svg';
import commonPageContentStyles from '../../../styles/mixins/_commonPageContentStyles';
import navigationStyles from '../../../styles/mixins/navigationMenuStyles';

interface Props {
  className?: string;
}

const dataExternalLinkGroups = [
  {
    id: 1,
    title: 'Government accessibility resources',
    cards: [
      {
        title: 'The Section 508 accessibility program',
        subtitle: '[section508.gov]',
        linkUrl: '#',
      },
      {
        title: 'Accessibility for Teams guide',
        subtitle: '[accessibility.digital.gov]',
        linkUrl: '#',
      },
      {
        title: 'Find your Section 508 Coordinator',
        subtitle: '[section508.gov]',
        linkUrl: '#',
      },
      {
        title: 'Section 508 ICT Testing Baseline',
        subtitle: '[section508coordinators.github.com]',
        linkUrl: '#',
      },
      {
        title: 'Learn about writing in plain language ',
        subtitle: '[plainlanguage.gov]',
        linkUrl: '#',
      },
    ],
  },
];

const CommunicationStructure = ({ className }: Props) => (
  <div className={className}>
    <Header className="header" />
    <SidebarStyled className="sidebar" />
    <div className="scrollable-content">
      <div className="content">
        <ContentHeaderStyled
          title="Communication"
          breadcrumbs={[
            { label: 'General Components', url: '/general-components' },
            { label: 'Communication', url: '/' },
          ]}
        />
        <div className="page-container">
          <div className="page-content">
            <div className="page-content-with-navigation-wrapper">
              <div className="page-content-with-navigation">
                <div className="page-content-item">
                  <h1 className="h1" id="overview">
                    Overview
                  </h1>
                  <h3 className="h3" id="definition">
                    Definition
                  </h3>
                  <p className="mb-40">
                    In design systems, badges, tooltips, and chart tooltips serve as essential
                    communication elements, providing concise indicators, contextual information,
                    and supplementary details to enhance user understanding and interaction within
                    the interface. These elements streamline the user experience by delivering
                    pertinent information clearly and intuitively, contributing to an effective
                    design system.
                  </p>
                  <h3 className="h3" id="accessibility">
                    Accessibility
                  </h3>
                  <ul className="disc-list font-sm">
                    <li>
                      Use badges in conjunction with a single item or label, to avoid ambiguity
                      around which item is being quantified.
                    </li>
                    <li>
                      Don&apos;t rely on color alone to signify whether a value is positive or
                      negative.
                    </li>
                    <li>
                      Number values are grouped and separated differently in many countries and
                      regions. Use your product&apos;s internationalization library, or the
                      browser&apos;s internationalization with the user&apos;s locale set correctly
                      so that number values show in a familiar format.
                    </li>
                  </ul>
                </div>
                <h1 className="h1 divider" id="specifications">
                  Specifications
                </h1>
                <div className="page-content-item">
                  <h3 className="h3" id="tag-badge">
                    Tag &amp; Badge
                  </h3>
                  <p className="mb-40">
                    A badge is a visual label or indicator used to convey status or highlight
                    content. Use tags to label, categorize, or organize items using keywords that
                    describe them. Use tags to label, categorize, or organize items using keywords
                    that describe them.
                  </p>
                </div>
                <div className="number-cards mb-40">
                  <div className="number-card">
                    <div className="number-card-img">
                      <span className="number-circle">1</span>
                      <img
                        src="/images/general-components-communication/screen-tag-badge-01@3x.jpg"
                        alt="Heading"
                      />
                    </div>
                    <div className="number-card-desc">
                      <p>1. Tag</p>
                    </div>
                  </div>
                  <div className="number-card">
                    <div className="number-card-img">
                      <span className="number-circle">2</span>
                      <img
                        src="/images/general-components-communication/screen-tag-badge-02@3x.jpg"
                        alt="Body"
                      />
                    </div>
                    <div className="number-card-desc">
                      <p>2. Badge</p>
                    </div>
                  </div>
                </div>
                <h3 className="h3 divider" id="tooltip">
                  Tooltip
                </h3>
                <div className="page-content-item">
                  <p className="mb-40">
                    Tooltips display additional information upon hover or focus that is contextual,
                    helpful, and nonessential while providing the ability to communicate and give
                    clarity to a user.
                  </p>
                </div>
                <img
                  src="/images/general-components-communication/screen-tooltip@3x.jpg"
                  alt=""
                  className="mb-40 img-responsive"
                />
                <h1 className="h1 divider" id="guidelines">
                  Guidelines
                </h1>
                <div className="page-content-item">
                  <h3 className="h3" id="tag-badge-usage">
                    Tag &amp; Badge Usage
                  </h3>
                  <p className="mb-40">
                    Tags are components that are often used to label different items, create
                    categorization, filter data, select or deselect options, and include
                    functionality to disclose several related tags in another view. To support these
                    different use cases, tags come in four variants—read-only, dismissible,
                    selectable, and operational.
                  </p>
                </div>
                <table className="table table-variants mb-40">
                  <thead>
                    <tr>
                      <th style={{ width: '15.625rem' }}>Component</th>
                      <th>Use case</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td valign="top">Badge</td>
                      <td>
                        The badge component indicates a status or count. Badges are read-only status
                        indicators or labels and are not interactive.
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">Tag</td>
                      <td>
                        Use tags to allow users to group, sort, or filter information. Tags
                        frequently contain keywords or metadata. They provide quick access to
                        specific categories and support content classification and navigation.
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="page-content-item">
                  <h3 className="visually-hidden" id="tooltip-usage">
                    Usage
                  </h3>
                  <h3 className="h3">Tooltip Usage</h3>
                  <ul className="disc-list font-sm">
                    <li>To expose names of controls, like icon buttons, that lack visual labels</li>
                    <li>
                      When an element can take focus and supplying additional information is useful
                      in helping a user make decisions
                    </li>
                    <li>When an element needs more context or explanation</li>
                    <li>Use when defining a term or inline item </li>
                  </ul>
                </div>
                <div className="ex-cards">
                  <div className="ex-card true">
                    <img
                      className="ex-card-img"
                      src="/images/general-components-communication/screen-tooltip-usage-01@3x.jpg"
                      alt=""
                    />
                    <div className="ex-card-desc">
                      <BadgeStyled className="success" iconLeft={<IconSuccess />}>
                        Do
                      </BadgeStyled>
                      <p>
                        Use consistent language for the button and other text descibing the same
                        action.
                      </p>
                    </div>
                  </div>
                  <div className="ex-card false">
                    <img
                      className="ex-card-img"
                      src="/images/general-components-communication/screen-tooltip-usage-02@3x.jpg"
                      alt=""
                    />
                    <div className="ex-card-desc">
                      <BadgeStyled className="danger" iconLeft={<IconDanger />}>
                        Don&apos;t
                      </BadgeStyled>
                      <p>Use different words to refer to the same action.</p>
                    </div>
                  </div>
                </div>
                <div className="page-content-item">
                  <h3 className="h3" id="tooltip-in-data-visualization">
                    Tooltip in Data Visualization
                  </h3>
                  <p className="mb-40">
                    Use the Small Tooltip when there is one data set. Since only one set of data is
                    being shown, the label and color border are not necessary.
                  </p>
                </div>
                <figure className="figure-sm">
                  <img
                    src="/images/general-components-communication/screen-tooltip-in-data-visualization-01@3x.jpg"
                    alt=""
                    className="img-responsive"
                  />
                  <figcaption>
                    Use the Small Tooltip when there is one data set. Since only one set of data is
                    being shown, the label and color border are not necessary.
                  </figcaption>
                </figure>
                <figure className="figure-sm">
                  <img
                    src="/images/general-components-communication/screen-tooltip-in-data-visualization-02@3x.jpg"
                    alt=""
                    className="img-responsive"
                  />
                  <figcaption>
                    The Medium Tooltip is used in scenarios where two or more sets of data are being
                    compared. The top border color on the Tooltip should match the color of the data
                    it is representing, as shown in the key. The label can either be referring to
                    the corresponding name in the key or the intersecting data increment on the
                    x-axis.
                  </figcaption>
                </figure>
                <div className="page-content-item">
                  <h3 className="h3" id="placement">
                    Placement
                  </h3>
                  <p className="mb-40">
                    Tooltip directions, by default, are set to auto. Upon opening, tooltips can
                    detect the edges of the browser to be placed in view so the container does not
                    get cutoff. Tooltips can instead use specific directions and may be positioned
                    right, left, bottom, or top of the trigger item. Do not cover related content
                    that is essential to the user&apos;s tasks. Tooltips should not bleed off page
                    or behind other content.
                  </p>
                </div>
                <div className="number-cards mb-24">
                  <div className="number-card">
                    <div className="number-card-img">
                      <span className="number-circle">1</span>
                      <img
                        src="/images/general-components-communication/screen-placement-01@3x.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="number-card">
                    <div className="number-card-img">
                      <span className="number-circle">2</span>
                      <img
                        src="/images/general-components-communication/screen-placement-02@3x.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="number-card">
                    <div className="number-card-img">
                      <span className="number-circle">3</span>
                      <img
                        src="/images/general-components-communication/screen-placement-03@3x.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="number-card">
                    <div className="number-card-img">
                      <span className="number-circle">4</span>
                      <img
                        src="/images/general-components-communication/screen-placement-04@3x.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="page-content-item">
                  <p className="mb-40">
                    Tooltip containers can be automatically or manually positioned to the right,
                    left, bottom, or top
                  </p>
                </div>
                <h1 className="h1 divider" id="references">
                  References
                </h1>
                {dataExternalLinkGroups.map((externalLinkGroup) => (
                  <ExternalLinkGroupStyled
                    key={externalLinkGroup.id}
                    title={externalLinkGroup.title}
                    cards={externalLinkGroup.cards}
                  />
                ))}
              </div>
              <div>
                <div className="page-navigation">
                  <h4>Contents</h4>
                  <Scrollspy
                    ids={[
                      'overview',
                      'definition',
                      'accessibility',
                      'specifications',
                      'tag-badge',
                      'tooltip',
                      'guidelines',
                      'tooltip-usage',
                      'placement',
                      'references',
                    ]}
                    offset={0}
                    activeItemClassName="active"
                    itemContainerClassName="multilevel-link-list"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <PaginationStyled
          previousLink="/general-components/form-elements"
          previous="Form Elements"
          nextLink="/general-components/navigation"
          next="Navigation"
        />
      </div>
    </div>
  </div>
);

CommunicationStructure.defaultProps = {
  className: '',
};

const Communication = styled(CommunicationStructure)`
  ${commonPageContentStyles}
  ${navigationStyles([2, 3, 5, 6, 8, 9])}
`;

export default Communication;
