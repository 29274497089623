import Scrollspy from 'react-scrollspy-ez';
import styled from 'styled-components';

import BadgeStyled from '../../../components/Badge';
import ContentHeaderStyled from '../../../components/ContentHeader';
import ExternalLinkGroupStyled from '../../../components/ExternalLinkGroup';
import Header from '../../../components/Header';
import PaginationStyled from '../../../components/Pagination';
import SidebarStyled from '../../../components/Sidebar';
import { ReactComponent as IconDanger } from '../../../images/icons/icon-danger.svg';
import { ReactComponent as IconSuccess } from '../../../images/icons/icon-success.svg';
import commonPageContentStyles from '../../../styles/mixins/_commonPageContentStyles';
import navigationStyles from '../../../styles/mixins/navigationMenuStyles';

interface Props {
  className?: string;
}

const dataExternalLinkGroups = [
  {
    id: 1,
    title: 'UI Interstate Connection Network (UI ICON)',
    cards: [
      {
        title: 'IBM Web Accessibility Checklist',
        subtitle: 'plainlanguage.gov',
        linkUrl: '#',
      },
      {
        title: 'Government usability testing resources',
        subtitle: '[digital.gov]',
        linkUrl: '#',
      },
      {
        title: 'Find your Section 508 Coordinator',
        subtitle: '[section508.gov]',
        linkUrl: '#',
      },
    ],
  },
];

const InteractionsAndAnimationsStructure = ({ className }: Props) => (
  <div className={className}>
    <Header className="header" />
    <SidebarStyled className="sidebar" />
    <div className="scrollable-content">
      <div className="content">
        <ContentHeaderStyled
          title="Interactions and Animations"
          breadcrumbs={[
            { label: 'Branding Styleguide', url: '/branding-styleguide' },
            { label: 'Interactions and Animations' },
          ]}
        />
        <div className="page-container">
          <div className="page-content">
            <div className="page-content-with-navigation-wrapper">
              <div className="page-content-with-navigation">
                <div className="page-content-item">
                  <h1 className="h1" id="overview">
                    Overview
                  </h1>
                  <h3 className="h3" id="principles">
                    Principles
                  </h3>
                  <p className="mb-40">
                    When selecting images, consider your target audience and only use imagery that
                    is compatible with the product&apos;s visual identity. States communicate the
                    status of UI elements to the user. Each state should be visually similar and not
                    drastically alter a component, but must have clear affordances that distinguish
                    it from other states and the surrounding layout.
                  </p>
                </div>
                <h1 className="h1 divider" id="specifications">
                  Specifications
                </h1>
                <div className="page-content-item">
                  <h3 className="visually-hidden" id="usage-states-types">
                    Usage &amp; states
                  </h3>
                  <h3 className="h3 mb-40">Usage &amp; states types</h3>
                </div>
                <div className="number-cards">
                  <div className="number-card">
                    <div className="number-card-img">
                      <span className="number-circle">1</span>
                      <img
                        src="/images/branding-styleguide-interactions-and-animations/usage-states-types-01@3x.jpg"
                        alt=""
                      />
                    </div>
                    <div className="number-card-desc">
                      <strong>Default</strong>
                      <p>
                        The default button state is how a button appears or behaves on a user
                        interface before any interaction.
                      </p>
                    </div>
                  </div>
                  <div className="number-card">
                    <div className="number-card-img">
                      <span className="number-circle">2</span>
                      <img
                        src="/images/branding-styleguide-interactions-and-animations/usage-states-types-02@3x.jpg"
                        alt=""
                      />
                    </div>
                    <div className="number-card-desc">
                      <strong>Disabled</strong>
                      <p>A disabled state communicates a noninteractive component or element.</p>
                    </div>
                  </div>
                  <div className="number-card">
                    <div className="number-card-img">
                      <span className="number-circle">3</span>
                      <img
                        src="/images/branding-styleguide-interactions-and-animations/usage-states-types-03@3x.jpg"
                        alt=""
                      />
                    </div>
                    <div className="number-card-desc">
                      <strong>Hover</strong>
                      <p>
                        A hover state communicates when a user has placed a cursor above an
                        interactive element.
                      </p>
                    </div>
                  </div>
                  <div className="number-card">
                    <div className="number-card-img">
                      <span className="number-circle">4</span>
                      <img
                        src="/images/branding-styleguide-interactions-and-animations/usage-states-types-04@3x.jpg"
                        alt=""
                      />
                    </div>
                    <div className="number-card-desc">
                      <strong>Active</strong>
                      <p>
                        A focused state communicates when a user has highlighted an element, using
                        an input method such as a keyboard or voice.
                      </p>
                    </div>
                  </div>
                  <div className="number-card">
                    <div className="number-card-img">
                      <span className="number-circle">5</span>
                      <img
                        src="/images/branding-styleguide-interactions-and-animations/usage-states-types-05@3x.jpg"
                        alt=""
                      />
                    </div>
                    <div className="number-card-desc">
                      <strong>Selected</strong>
                      <p>A selected state communicates a user choice.</p>
                    </div>
                  </div>
                  <div className="number-card">
                    <div className="number-card-img">
                      <span className="number-circle">6</span>
                      <img
                        src="/images/branding-styleguide-interactions-and-animations/usage-states-types-06@3x.jpg"
                        alt=""
                      />
                    </div>
                    <div className="number-card-desc">
                      <strong>Activated</strong>
                      <p>
                        An activated state communicates a highlighted destination, whether initiated
                        by the user or by default.
                      </p>
                    </div>
                  </div>
                  <div className="number-card">
                    <div className="number-card-img">
                      <span className="number-circle">9</span>
                      <img
                        src="/images/branding-styleguide-interactions-and-animations/usage-states-types-09@3x.jpg"
                        alt=""
                      />
                    </div>
                    <div className="number-card-desc">
                      <strong>Error</strong>
                      <p>An error state communicates a user or system mistake.</p>
                    </div>
                  </div>
                </div>
                <h1 className="divider h1" id="guidelines">
                  Guidelines
                </h1>
                <div className="page-content-item">
                  <h3 className="h3" id="behavior">
                    Behavior
                  </h3>
                  <p className="mb-40">
                    Disabled components don’t change state when tapped or hovered over.
                  </p>
                </div>
                <div className="ex-cards">
                  <div className="ex-card true">
                    <img
                      className="ex-card-img"
                      src="/images/branding-styleguide-interactions-and-animations/screen-behavior-01@3x.jpg"
                      alt=""
                    />
                    <div className="ex-card-desc">
                      <BadgeStyled className="success" iconLeft={<IconSuccess />}>
                        Do
                      </BadgeStyled>
                      <p>
                        Disabled components can be hovered over, but won&apos;t display state
                        changes to the component or cursor.
                      </p>
                    </div>
                  </div>
                  <div className="ex-card true">
                    <img
                      className="ex-card-img"
                      src="/images/branding-styleguide-interactions-and-animations/screen-behavior-02@3x.jpg"
                      alt=""
                    />
                    <div className="ex-card-desc">
                      <BadgeStyled className="success" iconLeft={<IconSuccess />}>
                        Do
                      </BadgeStyled>
                      <p>Disabled components don&apos;t change state when tapped.</p>
                    </div>
                  </div>
                  <div className="ex-card false">
                    <img
                      src="/images/branding-styleguide-interactions-and-animations/screen-behavior-03@3x.jpg"
                      alt=""
                    />
                    <div className="ex-card-desc">
                      <BadgeStyled className="danger" iconLeft={<IconDanger />}>
                        Don&apos;t
                      </BadgeStyled>
                      <p>Disabled components cannot be hovered, focused.</p>
                    </div>
                  </div>
                  <div className="ex-card false">
                    <img
                      src="/images/branding-styleguide-interactions-and-animations/screen-behavior-04@3x.jpg"
                      alt=""
                    />
                    <div className="ex-card-desc">
                      <BadgeStyled className="danger" iconLeft={<IconDanger />}>
                        Don&apos;t
                      </BadgeStyled>
                      <p>Disabled components cannot be dragged or pressed.</p>
                    </div>
                  </div>
                </div>
                <h1 id="references" className="divider h1">
                  References
                </h1>
                {dataExternalLinkGroups.map((externalLinkGroup) => (
                  <ExternalLinkGroupStyled
                    key={externalLinkGroup.id}
                    title={externalLinkGroup.title}
                    cards={externalLinkGroup.cards}
                  />
                ))}
              </div>
              <div>
                <div className="page-navigation">
                  <h4>Contents</h4>
                  <Scrollspy
                    ids={[
                      'overview',
                      'principles',
                      'specifications',
                      'usage-states-types',
                      'guidelines',
                      'behavior',
                      'references',
                    ]}
                    offset={0}
                    activeItemClassName="active"
                    itemContainerClassName="multilevel-link-list"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <PaginationStyled
          previousLink="/branding-styleguide/imagery"
          previous="Imagery"
          nextLink="/general-components"
          next="General Components"
        />
      </div>
    </div>
  </div>
);

InteractionsAndAnimationsStructure.defaultProps = {
  className: '',
};

const InteractionsAndAnimations = styled(InteractionsAndAnimationsStructure)`
  ${commonPageContentStyles}
  ${navigationStyles([2, 4, 6])}
`;

export default InteractionsAndAnimations;
