import styled from 'styled-components';

import CardGroupStyled from '../../components/CardGroup';
import ContentNavigationStyled from '../../components/ContentNavigation';
import Header from '../../components/Header';
import HeroStyled from '../../components/Hero';
import LatestNewsStyled from '../../components/LatestNews';
import PaginationStyled from '../../components/Pagination';
import SidebarStyled from '../../components/Sidebar';
import { ReactComponent as IconAccessibility } from '../../images/icons/accessibility-56.svg';
import { ReactComponent as IconChartMatch } from '../../images/icons/chart-match-56.svg';
import { ReactComponent as IconColorPalette } from '../../images/icons/color-palette-56.svg';
import { ReactComponent as IconComponents } from '../../images/icons/components-56.svg';
import { ReactComponent as IconDashboardTemplates } from '../../images/icons/dashboard-templates-56.svg';
import { ReactComponent as IconIconography } from '../../images/icons/iconography-56.svg';
import { ReactComponent as IconLogosUsage } from '../../images/icons/logos-usage-56.svg';
import { ReactComponent as IconTools } from '../../images/icons/tools-56.svg';
import { ReactComponent as IconUIKits } from '../../images/icons/ui-kits-56.svg';

const dataCardGroups = [
  {
    id: 1,
    title: 'Branding',
    content:
      'Our brand reflects who we are and how we want our users to feel when they use our products.',
    linkUrl: '#',
    cards: [
      {
        title: 'Logos Usage',
        content: 'Our brand and product logos are clear, impactful, and recognizable.',
        linkUrl: '#',
        icon: <IconLogosUsage />,
      },
      {
        title: 'Color Palette',
        content:
          'Color distinguishes our brand and reinforces consistent experiences across products.',
        linkUrl: '#',
        icon: <IconColorPalette />,
      },
      {
        title: 'Iconography',
        content:
          'Icons are visual representations of commands, devices, directories, or common actions.',
        linkUrl: '#',
        icon: <IconIconography />,
      },
    ],
  },
  {
    id: 2,
    title: 'Design',
    content:
      'Design principles serve as guiding notions that shape the design of the DHCS products.',
    linkUrl: '#',
    cards: [
      {
        title: 'ChartMatch',
        content: 'Find the most appropriate chart for your project by taking a simple quiz.',
        linkUrl: '#',
        icon: <IconChartMatch />,
      },
      {
        title: 'Components',
        content: 'Components are the reusable building blocks of our design system.',
        linkUrl: '#',
        icon: <IconComponents />,
      },
      {
        title: 'Accessibility',
        content:
          'Accessible design lets people of all abilities interact with, understand, and navigate our products.',
        linkUrl: '#',
        icon: <IconAccessibility />,
      },
    ],
  },
  {
    id: 3,
    title: 'Development',
    content: 'Implement DHCS Design System with code and developer documentation.',
    linkUrl: '#',
    cards: [
      {
        title: 'UI Kits',
        content: 'Rapidly build beautiful and accessible experiences with all resources you need.',
        linkUrl: '#',
        icon: <IconUIKits />,
      },
      {
        title: 'Dashboard Templates',
        content: 'Pre-made dashboards that will help you implement designs.',
        linkUrl: '#',
        icon: <IconDashboardTemplates />,
      },
      {
        title: 'Tools',
        content: 'Extensions, plugins, and other tools to help build with DHCS.',
        linkUrl: '#',
        icon: <IconTools />,
      },
    ],
  },
];

interface Props {
  className?: string;
}

const MainPageStructure = ({ className }: Props) => (
  <div className={className}>
    <Header className="header" />
    <SidebarStyled className="sidebar" />
    <div className="scrollable-content">
      <div className="content">
        <ContentNavigationStyled />
        <HeroStyled
          title="A Design System for a Human-Centered DHCS"
          buttonName="Get Started"
          buttonLink="#"
        />
        {dataCardGroups.map((cardGroup) => (
          <CardGroupStyled
            key={cardGroup.id}
            title={cardGroup.title}
            content={cardGroup.content}
            linkUrl={cardGroup.linkUrl}
            cards={cardGroup.cards}
          />
        ))}
        <LatestNewsStyled
          newsImage="images/news-image@3x.jpg"
          newsTitle="Design Evolved: Explore Our New Design System!"
          newsDate="9 Feb, 2024"
          newsContent="Introducing our brand new design system, meticulously crafted to elevate user experience to unprecedented heights. Drawing from the latest in design innovation and user feedback. Dive in and explore the next frontier of digital design."
        />
        <PaginationStyled nextLink="/branding-styleguide" next="Branding Styleguide" />
      </div>
    </div>
  </div>
);

MainPageStructure.defaultProps = {
  className: '',
};

const MainPage = styled(MainPageStructure)`
  display: grid;
  grid-gap: 0;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    'header  header'
    'sidebar content';
  overflow: hidden;
  height: 100vh;

  .header {
    grid-area: header;
    padding: 2rem;
    max-height: 4rem;
  }
  .sidebar {
    grid-area: sidebar;
    overflow-x: hidden;
    height: calc(100vh - 4rem);
  }
  .scrollable-content {
    grid-area: content;
    padding: 2.5rem 1.25rem 0;
    overflow-x: hidden;
    height: calc(100vh - 4rem);
  }

  .content {
    width: 54.25rem;
    margin: 0 auto;

    @media (min-width: 1620px) {
      width: 75rem;
    }
  }
`;

export default MainPage;
