import { useState } from 'react';
import styled from 'styled-components';

interface TabButtonProps {
  id: string;
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
}

interface TabContentProps {
  id: string;
  children: React.ReactNode;
  className?: string;
}

const TabButton = ({ id, children, onClick, className }: TabButtonProps) => {
  const handleKeyDown = (event: React.KeyboardEvent) => {
    // Check if the key pressed was 'Enter' or 'Space'
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      onClick?.();
    }
  };

  return (
    <div
      className={`tab-header ${className}`}
      id={id}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      tabIndex={0} // Make the div focusable
      role="button" // Indicate that the div is a button
    >
      {children}
    </div>
  );
};

TabButton.defaultProps = {
  onClick: () => {},
  className: '',
};

const TabContent = ({ id, children, className }: TabContentProps) => (
  <div className={`tab-content ${className}`} id={id}>
    {children}
  </div>
);

TabContent.defaultProps = {
  className: '',
};

const Tab = ({ data }: { data: { id: string; content: React.ReactNode; title: string }[] }) => {
  const [activeTab, setActiveTab] = useState(data[0].id);

  return (
    <div className="tab">
      <div className="tab-header-list">
        {data.map((tab) => (
          <TabButton
            key={tab.id}
            id={tab.id}
            onClick={() => setActiveTab(tab.id)}
            className={activeTab === tab.id ? 'active' : ''}
          >
            {tab.title}
          </TabButton>
        ))}
      </div>
      <div className="tab-content-list">
        {data.map((tab) => (
          <TabContent key={tab.id} id={tab.id} className={activeTab === tab.id ? 'active' : ''}>
            {tab.content}
          </TabContent>
        ))}
      </div>
    </div>
  );
};

const TabStyled = styled(Tab)`
  .tab {
    &-header {
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.428571428571429;
      padding: 0.5rem 1rem;
      transition:
        color 0.3s ease-in-out,
        background-color 0.3s ease-in-out;

      &-list {
        display: inline-flex;
        align-items: center;
        background-color: var(--5f687714, #5f687714);
        border-radius: 0.375rem;
        padding: 0.125rem;
        margin-bottom: 2.5rem;
      }

      &:not(.active) {
        color: var(--5f6877, #5f6877);

        &:hover {
          color: var(--000005, #000005);
          cursor: pointer;
        }
      }

      &.active {
        background-color: var(--fff, #fff);
        color: var(--000005, #000005);
        box-shadow: 0rem 0.125rem 0.25rem 0.0625rem var(--141c2c0a, #141c2c0a);
        border-radius: 0.25rem;
      }
    }

    &-content {
      display: none;

      &.active {
        display: block;
      }
    }
  }
`;

export default TabStyled;
