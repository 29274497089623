import Scrollspy from 'react-scrollspy-ez';
import styled from 'styled-components';

import ChangelogStyled from '../../../components/Changelog';
import ChartCardStyled from '../../../components/ChartCard';
import ColorPaletteStyled from '../../../components/ColorPalette';
import ColorPreviewStyled from '../../../components/ColorPreview';
import ColorVisionStyled from '../../../components/ColorVision';
import ContentHeaderStyled from '../../../components/ContentHeader';
import Header from '../../../components/Header';
import NotificationStyled from '../../../components/Notification';
import PaginationStyled from '../../../components/Pagination';
import RelatedPlotsStyled from '../../../components/RelatedPlots';
import SidebarStyled from '../../../components/Sidebar';
import TabStyled from '../../../components/Tab';
import { ReactComponent as ChartQuiz } from '../../../images/icons/charts-quiz-icon.svg';
import { ReactComponent as Checkmark } from '../../../images/icons/checkmark.svg';
import { ReactComponent as InfoCircle } from '../../../images/icons/info-circle.svg';
import commonPageContentStyles from '../../../styles/mixins/_commonPageContentStyles';
import navigationStyles from '../../../styles/mixins/navigationMenuStyles';

const dataChart = [
  {
    id: 1,
    title: 'Map - Scatter',
    image: '/images/data-viz-maps/map-image-scatter@3x.jpg',
    icon: <ChartQuiz />,
  },
  {
    id: 2,
    title: 'Map - Bubble',
    image: '/images/data-viz-maps/map-image-bubble@3x.jpg',
    icon: <ChartQuiz />,
  },
  {
    id: 3,
    title: 'Map - Pikes',
    image: '/images/data-viz-maps/map-image-pikes@3x.jpg',
    icon: <ChartQuiz />,
  },
];

const dataColorPalette1 = [
  { id: 1, color: '#084594' },
  { id: 2, color: '#2171b5' },
  { id: 3, color: '#4292c6' },
  { id: 4, color: '#6baed6' },
  { id: 5, color: '#9ecae1' },
  { id: 6, color: '#c6dbef' },
  { id: 7, color: '#eff3ff' },
];

const dataColorPalette2 = [
  { id: 1, color: '#8c510a' },
  { id: 2, color: '#d8b365' },
  { id: 3, color: '#f6e8c3' },
  { id: 4, color: '#f5f5f5' },
  { id: 5, color: '#c7eae5' },
  { id: 6, color: '#5ab4ac' },
  { id: 7, color: '#01665e' },
];

const dataColorVision = [
  { id: 1, title: 'Normal', color: '#2d6e8d' },
  { id: 2, title: 'Protanopia', color: '#526dbc' },
  { id: 3, title: 'Deuteranopia', color: '#4770b7' },
  { id: 4, title: 'Tritanopia', color: '#517881' },
];

const dataBadge = [
  {
    id: 1,
    title: '1.03.0',
    date: '09.13.2023',
    color: 'info',
    badgeTitle: 'Changed',
    description:
      'Hover state now has a stronger border, thus resolving readability problem encountered by some users.',
  },
  {
    id: 2,
    title: '1.02.0',
    date: '08.07.2023',
    color: 'warning',
    badgeTitle: 'Fixed',
    description: `Fixed animation on hover when moving mouse quickly on Map's borders.`,
  },
  {
    id: 3,
    title: '1.01.0',
    date: '06.10.2023',
    color: 'success',
    badgeTitle: 'Added',
    description: 'Added tooltips on hover with customisable data.',
  },
  {
    id: 4,
    title: '1.03.0',
    date: '09.13.2023',
    color: 'info',
    badgeTitle: 'Changed',
    description:
      'Hover state now has a stronger border, thus resolving readability problem encountered by some users.',
  },
];

const dataTabs = [
  {
    id: 'tab-use-case',
    title: 'Use Case',
    content: (
      <div className="page-content-with-navigation-wrapper">
        <div className="page-content-with-navigation">
          <div className="page-content-item mb-40">
            <h2 className="h2" id="definition">
              Definition
            </h2>
            <p>
              A choropleth map is a data visualization tool that represents geographic areas with
              colors or shading based on a numerical variable, enabling the study of variable trends
              across a region. While powerful, it has a bias favoring larger regions in
              interpretation.
            </p>
          </div>
          <div className="page-content-item mb-40">
            <h2 className="h2" id="usage">
              Usage
            </h2>
            <p>Choropleth maps are suitable for data that:</p>
            <ul className="ordered-list-badge-num">
              <li>
                <strong>Relate to Enumeration Units:</strong> Your data is associated with specific
                geographic areas like counties, provinces, or countries.
              </li>
              <li>
                <strong>Standardized for Rates or Ratios:</strong> The data is standardized to
                depict rates or ratios rather than raw counts. Raw data counts are not suitable for
                choropleth mapping.
              </li>
              <li>
                <strong>Represent a Continuous Statistical Surface:</strong> The data represents a
                continuous statistical surface, meaning it can be conceptually measured anywhere in
                space, even if the measurement is zero.
              </li>
            </ul>
          </div>
          <div className="page-content-item mb-40">
            <h2 className="h2" id="limitations">
              Limitations
            </h2>
            <p>
              Choropleth are a valuable data visualization tool, but they do have limitations that
              should be considered when deciding whether to use them for a particular data set or
              analysis:
            </p>
            <ul className="ordered-list-badge-num">
              <li>
                <strong>Normalize variable:</strong> Direct comparisons between regions with varying
                sizes or populations using raw numbers can be misleading.
              </li>
              <li>
                <strong>Standardized for Rates or Ratios:</strong> The data is standardized to
                depict rates or ratios rather than raw counts. Raw data counts are not suitable for
                choropleth mapping.
              </li>
              <li>
                <strong>Represent a Continuous Statistical Surface:</strong> The data represents a
                continuous statistical surface, meaning it can be conceptually measured anywhere in
                space, even if the measurement is zero.
              </li>
            </ul>
          </div>
          <div className="page-content-item mb-40">
            <h2 className="h2" id="colors">
              Colors
            </h2>
            <p>
              Color selection is crucial in choropleth maps. Typically, a sequential color ramp is
              used, with lighter colors indicating smaller values and darker colors for larger
              values. Alternatively, a diverging color palette can be employed when there&apos;s a
              meaningful zero point in the data.
            </p>
            <NotificationStyled
              icon={<InfoCircle />}
              title="For in-depth information on this topic, please refer to the Colors page."
              color="blue"
            />
          </div>
          <div className="mb-40">
            <h4>Sequential Palette - Continuous Values</h4>
            <p>To showcase categorical values.</p>
            <ColorPaletteStyled data={dataColorPalette1} />
          </div>
          <div className="mb-40">
            <h4>Diverging Palette - Meaningful Central Value</h4>
            <ColorPaletteStyled data={dataColorPalette2} />
          </div>
          <div className="page-content-item">
            <h2 className="h2">Challenges</h2>
            <p>Unclassed choropleth maps present some challenges</p>
            <ul className="ordered-list-badge-num">
              <li>
                <strong>Information Overload:</strong> Unclassed maps can overwhelm with excessive
                detail, making it challenging to distinguish significant patterns amidst the noise.
                Classification can help filter out irrelevant variations and emphasize major
                differences.
              </li>
              <li>
                <strong>Legibility and Interpretation:</strong> Users often struggle to match colors
                on unclassed maps with those in the legend due to the multitude of subtly different
                shades. This hinders the ability to estimate values or extract specific data from
                the map.
              </li>
              <li>
                <strong>Printing Issues:</strong> Users often struggle to match colors on unclassed
                maps with those in the legend due to the multitude of subtly different shades. This
                hinders the ability to estimate values or extract specific data from the map.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div className="page-navigation">
            <h4>Contents</h4>
            <Scrollspy
              ids={['definition', 'usage', 'limitations', 'colors', 'related-plots']}
              offset={0}
              activeItemClassName="active"
              itemContainerClassName="multilevel-link-list"
            />
          </div>
        </div>
      </div>
    ),
  },
  {
    id: 'tab-usability',
    title: 'Usability',
    content: (
      <>
        <div className="page-content-item">
          <h2 className="h2" id="definition">
            Readability
          </h2>
          <p>
            The color of any text should have a contrast ratio of at least 4.5:1 against the
            background color for standard text and 3:1 for large text. You can measure the color
            contrast with a tool such as the{' '}
            <a href="/" title="">
              <strong>WebAIM Contrast Checker</strong>
            </a>
            . For text legibility, aim for a minimum font size of 12pt for most text. Larger text
            (e.g., 18pt or larger) may be needed for data labels or annotations.
          </p>
        </div>
        <table className="table mb-40">
          <thead>
            <th>Usage</th>
            <th>Text Size</th>
            <th>Text Style</th>
            <th>Text Color</th>
            <th>Text Color</th>
            <th>Contrast Ratio</th>
          </thead>
          <tbody>
            <tr>
              <td>Axis Label</td>
              <td>
                12 <span>pt</span>
              </td>
              <td>Regular</td>
              <td aria-label="colorPreview">
                <ColorPreviewStyled color="2f3237" />
              </td>
              <td aria-label="colorPreview">
                <ColorPreviewStyled color="ffffff" />
              </td>
              <td>
                <div className="checkmark-container">
                  <div>6.06:1</div>
                  <Checkmark className="checkmark" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="page-content-item mb-40">
          <h2 className="h2">Contrast</h2>
          <p>
            When designing a plot, it&apos;s crucial to ensure strong contrast both against the
            background and between individual elements. Maintain a contrast ratio of{' '}
            <strong>at least 3:1</strong>. For instance, if showcasing two bars in a bar graph with
            colors like red and blue, their contrast should meet or exceed this 3:1 threshold.
          </p>
        </div>
        <h4>Color Contrast Guidelines</h4>
        <table className="table mb-40">
          <thead>
            <th>Usage</th>
            <th>Element Color</th>
            <th>Opacity</th>
            <th>Background Color</th>
            <th>Contrast Ratio</th>
          </thead>
          <tbody>
            <tr>
              <td>Axis Label</td>
              <td aria-label="colorPreview">
                <ColorPreviewStyled color="5c6bc0" />
              </td>
              <td>
                100 <span>%</span>
              </td>
              <td aria-label="colorPreview">
                <ColorPreviewStyled color="ffffff" />
              </td>
              <td>
                <div className="checkmark-container">
                  <div>4.86:1</div>
                  <Checkmark className="checkmark" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <h4>Color Vision Deficiency Testing</h4>
        <ColorVisionStyled data={dataColorVision} />
      </>
    ),
  },
  {
    id: 'tab-changelog',
    title: 'Changelog',
    content: <ChangelogStyled data={dataBadge} />,
  },
];

interface Props {
  className?: string;
}

const MapChloroplethStructure = ({ className }: Props) => (
  <div className={className}>
    <Header className="header" />
    <SidebarStyled className="sidebar" />
    <div className="scrollable-content">
      <div className="content">
        <ContentHeaderStyled
          title="Map - Chloropleth"
          breadcrumbs={[
            { label: 'Data Vizualization', url: '/data-vizualization' },
            { label: 'Maps', url: '/data-vizualization' },
            { label: 'Map - Chloropleth', url: '/maps/map-cloropleth' },
          ]}
        />
        <ChartCardStyled imageLink="/images/data-viz-maps/map-chart@3x.jpg" />
        <div className="page-container">
          <div className="page-content">
            <TabStyled
              data={dataTabs.map((item) => ({ ...item, content: item.content, title: item.title }))}
            />
          </div>
        </div>
        <RelatedPlotsStyled
          title="Related Plots"
          description="These plots may serve as a substitution to this Line Chart."
          data={dataChart}
        />
        <PaginationStyled
          previousLink="/data-vizualization/bars"
          previous="Bars"
          nextLink="/data-vizualization/maps/bubble-map"
          next="Bubble Map"
        />
      </div>
    </div>
  </div>
);

MapChloroplethStructure.defaultProps = {
  className: '',
};

const MapChloropleth = styled(MapChloroplethStructure)`
  ${commonPageContentStyles}
  ${navigationStyles([])}

    .tab {
    &-header {
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.428571428571429;
      padding: 0.25rem 1rem;
      transition:
        color 0.3s ease-in-out,
        background-color 0.3s ease-in-out;

      &-list {
        display: inline-flex;
        align-items: center;
        background-color: var(--5f687714, #5f687714);
        border-radius: 0.375rem;
        padding: 0.125rem;
        margin-bottom: 2.5rem;
      }

      &:not(.active) {
        color: var(--5f6877, #5f6877);

        &:hover {
          color: var(--000005, #000005);
          cursor: pointer;
        }
      }

      &.active {
        background-color: var(--fff, #fff);
        color: var(--000005, #000005);
        box-shadow: 0rem 0.125rem 0.25rem 0.0625rem var(--141c2c0a, #141c2c0a);
        border-radius: 0.25rem;
      }
    }

    &-content {
      display: none;

      &.active {
        display: block;
      }
    }
  }
`;

export default MapChloropleth;
