import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Sora&display=swap');

  *,
  *::before,
  *::after { 
   // reset browser default styles
   box-sizing: border-box;
   margin: 0;
   padding: 0;
  }

  /* custom scroll */
  ::-webkit-scrollbar {
    width: 0.25rem;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: var(--f1f1f1, #f1f1f1); 
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--ccc, #ccc); 
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--888, #888); 
  }

  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    margin: 0;
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;

  }

  button {
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-size: inherit;
  }

  .mb-0 {
    margin-bottom: 0 !important;
  }

  .mb-24 {
    margin-bottom: 1.5rem !important;
  }
  
  .mb-40 {
    margin-bottom: 2.5rem !important;
  }

  .mb-80 {
    margin-bottom: 5rem;
  }

  .font-xs {
    font-size: 0.75rem !important;
    line-height: 1.6666666667 !important;
  }

  .font-sm {
    font-size: 0.875rem !important;
    line-height: 1.4285714286 !important;
  }

  .font-medium {
    font-weight: 500 !important;
  }

  .visually-hidden {
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    opacity: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
  }
`;

export default GlobalStyle;
