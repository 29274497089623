import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as ArrowLeft } from '../../images/icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../images/icons/arrow-right.svg';

interface Props {
  className?: string;
  previous?: string;
  previousLink: string;
  next?: string;
  nextLink: string;
  previousDisabled?: string;
  nextDisabled?: string;
}

const Pagination = ({
  className,
  previous,
  previousLink,
  next,
  nextLink,
  previousDisabled,
  nextDisabled,
}: Props) => (
  <div className={className}>
    <div className="pagination">
      <div>
        {previous && (
          <Link to={previousLink} className={`pagination-previous ${previousDisabled}`}>
            <ArrowLeft />
            <span>{previous}</span>
          </Link>
        )}
      </div>
      <div>
        {next && (
          <Link to={nextLink} className={`pagination-next ${nextDisabled}`}>
            <span>{next}</span>
            <ArrowRight />
          </Link>
        )}
      </div>
    </div>
  </div>
);

Pagination.defaultProps = {
  className: '',
  previous: '',
  next: '',
  previousDisabled: '',
  nextDisabled: '',
};

const PaginationStyled = styled(Pagination)`
  border-top: 0.0625rem solid var(--dfe1e4, #dfe1e4);
  padding: 1.5rem 0;
  margin-top: 2.5rem;

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;

    a {
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.428571428571429;
      background: none;
      border: none;
      display: flex;
      align-items: center;
      gap: 1rem;
      cursor: pointer;
      text-decoration: none;
      padding: 0;

      &.disabled {        
        span{
          color: var(--9fa4ad, #9fa4ad);
        }
  
        svg {
          color: var(--8e95a0, #8e95a0);
        }
      }
    }

    &-previous,
    &-next {
      color: var(--155676, #155676);
      transition: color 0.3s ease-in-out;

      &:hover {
        color: var(--003e5f, #003e5f);
      }
    }
  }
}
`;

export default PaginationStyled;
