import Scrollspy from 'react-scrollspy-ez';
import styled from 'styled-components';

import BadgeStyled from '../../../components/Badge';
import ContentHeaderStyled from '../../../components/ContentHeader';
import ExternalLinkGroupStyled from '../../../components/ExternalLinkGroup';
import Header from '../../../components/Header';
import PaginationStyled from '../../../components/Pagination';
import SidebarStyled from '../../../components/Sidebar';
import { ReactComponent as IconDanger } from '../../../images/icons/icon-danger.svg';
import { ReactComponent as IconSuccess } from '../../../images/icons/icon-success.svg';
import commonPageContentStyles from '../../../styles/mixins/_commonPageContentStyles';
import navigationStyles from '../../../styles/mixins/navigationMenuStyles';

interface Props {
  className?: string;
}

const dataExternalLinkGroups = [
  {
    id: 1,
    title: 'Government Usability Testing Resources:',
    cards: [
      {
        title: 'Usability testing on Digital.gov',
        subtitle: '[digital.gov]',
        linkUrl: '#',
      },
      {
        title: 'Getting started with usability testing',
        subtitle: '[digital.gov]',
        linkUrl: '#',
      },
      {
        title: 'Understanding universal design',
        subtitle: '[section508.gov]',
        linkUrl: '#',
      },
    ],
  },
  {
    id: 2,
    title: 'Government Accessibility Resources:',
    cards: [
      {
        title: 'The Section 508 accessibility program',
        subtitle: '[section508.gov]',
        linkUrl: '#',
      },
      {
        title: 'Accessibility for Teams guide',
        subtitle: '[accessibility.digital.gov]',
        linkUrl: '#',
      },
      {
        title: 'Humane by design',
        subtitle: '[humanebydesign.com]',
        linkUrl: '#',
      },
    ],
  },
  {
    id: 3,
    title: 'Non-Government Accessibility Resources',
    cards: [
      {
        title: 'Inclusive design principles',
        subtitle: '[inclusivedesignprinciples.org]',
        linkUrl: '#',
      },
      {
        title: 'Accessibility fundamentals with Rob Dodson',
        subtitle: '[youtube.com]',
        linkUrl: '#',
      },
    ],
  },
];

const ContentStructure = ({ className }: Props) => (
  <div className={className}>
    <Header className="header" />
    <SidebarStyled className="sidebar" />
    <div className="scrollable-content">
      <div className="content">
        <ContentHeaderStyled
          title="Content"
          breadcrumbs={[{ label: 'Get Started', url: '/' }, { label: 'Content' }]}
        />
        <div className="page-container">
          <div className="page-content">
            <div className="page-content-with-navigation-wrapper">
              <div className="page-content-with-navigation">
                <div className="page-content-item">
                  <h1 className="h1" id="overview">
                    Overview
                  </h1>
                  <h3 className="h3" id="definition">
                    Definition
                  </h3>
                  <p className="mb-40">
                    The Content Writing Page within our Design System provides comprehensive
                    guidance on crafting clear, concise, and accessible content for our digital
                    products. It outlines best practices, voice guidelines, and error message design
                    principles to ensure a seamless user experience.
                  </p>
                  <h3 className="h3" id="rationale">
                    Rationale
                  </h3>
                  <p className="mb-40">
                    Effective content writing is essential for facilitating user understanding,
                    accessibility, and trust in our digital products. Without clear and
                    well-structured content, users may struggle to navigate our platforms, leading
                    to frustration, decreased engagement, and loss of credibility.
                  </p>
                </div>
                <h1 className="divider h1" id="guidelines">
                  Guidelines
                </h1>
                <div className="page-content-item">
                  <h3 className="h3" id="best-practices">
                    Best Practices
                  </h3>
                  <ul className="ordered-list">
                    <li>
                      <strong>Voice Guidelines:</strong> Organizing content to prevent duplication,
                      which can confuse users and undermine credibility.
                    </li>
                    <li>
                      <strong>Tone Guidelines:</strong> Tailoring tone to the user&apos;s emotional
                      state, from motivational to supportive.
                    </li>
                    <li>
                      <strong>Error Message Design:</strong> Communicating error messages clearly,
                      providing explanations and actionable steps for resolution.
                    </li>
                  </ul>
                </div>
                <figure>
                  <img
                    src="/images/get-started-content/use-sentence-case-capitalization@3x.jpg"
                    alt="Use sentence—case capitalization"
                  />
                  <figcaption>Use sentence—case capitalization</figcaption>
                </figure>
                <div className="page-content-item mb-40">
                  <h3 className="visually-hidden" id="capitalization">
                    Capitalization
                  </h3>
                  <h3 className="h3">Do not use title case capitalization</h3>
                  <p>
                    Title (or headline) case capitalization is where the first letter of most words
                    is capitalized, but not articles, conjunctions, or prepositions (except if any
                    of these are the first or last word in the sentence or phrase).
                  </p>
                  <p>
                    It requires everyone who writes any copy to understand and follow fairly complex
                    grammatical rules about which words should and shouldn&apos;t be capitalized,
                    and It relies on a subjective viewpoint of what is considered
                    &quot;important&quot; or &quot;special&quot; and that viewpoint being
                    communicated or understood somehow.
                  </p>
                </div>
                <div className="ex-cards">
                  <div className="ex-card true">
                    <img
                      className="ex-card-img"
                      src="/images/get-started-content/screen-not-title-case-capitalization@3x.jpg"
                      alt="Only capitalize proper nouns, such as product names."
                    />
                    <div className="ex-card-desc">
                      <BadgeStyled className="success" iconLeft={<IconSuccess />}>
                        Do
                      </BadgeStyled>
                      <p>Only capitalize proper nouns, such as product names.</p>
                    </div>
                  </div>
                  <div className="ex-card false">
                    <img
                      className="ex-card-img"
                      src="/images/get-started-content/screen-title-case-capitalization@3x.jpg"
                      alt="Only capitalize proper nouns, such as product names."
                    />
                    <div className="ex-card-desc">
                      <BadgeStyled className="danger" iconLeft={<IconDanger />}>
                        Don&apos;t
                      </BadgeStyled>
                      <p>Only capitalize proper nouns, such as product names.</p>
                    </div>
                  </div>
                </div>
                <div className="page-content-item">
                  <h3 className="h3" id="principles">
                    Do not use all caps capitalization
                  </h3>
                  <p>
                    <strong>All caps (or uppercase) capitalization</strong> is where every letter is
                    capitalized. <br />
                    All caps capitalization has been shown to be slower to read (especially when
                    text is more than a few words) as individual letter shapes are less
                    distinguishable from each other—they are the same height and have no ascenders
                    or descenders. This style also typically requires more space in the IJI per
                    letter than sentence case does.
                  </p>
                </div>
                <div className="ex-cards">
                  <div className="ex-card true">
                    <img
                      className="ex-card-img"
                      src="/images/get-started-content/screen-not-use-all-caps-capitalization@3x.jpg"
                      alt="Only capitalize proper nouns, such as product names"
                    />
                    <div className="ex-card-desc">
                      <BadgeStyled className="success" iconLeft={<IconSuccess />}>
                        Do
                      </BadgeStyled>
                      <p>Only capitalize proper nouns, such as product names.</p>
                    </div>
                  </div>
                  <div className="ex-card false">
                    <img
                      className="ex-card-img"
                      src="/images/get-started-content/screen-use-all-caps-capitalization@3x.jpg"
                      alt="Only capitalize proper nouns, such as product names"
                    />
                    <div className="ex-card-desc">
                      <BadgeStyled className="danger" iconLeft={<IconDanger />}>
                        Don&apos;t
                      </BadgeStyled>
                      <p>Only capitalize proper nouns, such as product names.</p>
                    </div>
                  </div>
                  <div className="ex-card false">
                    <img
                      className="ex-card-img"
                      src="/images/get-started-content/screen-error-toast@3x.jpg"
                      alt="Toast messages"
                    />
                    <div className="ex-card-desc">
                      <BadgeStyled className="danger" iconLeft={<IconDanger />}>
                        Don&apos;t
                      </BadgeStyled>
                      <p>
                        Although error toast is still available, we discourage its use. Toast
                        messages are too short to adequately explain what went wrong and how to fix
                        the problem. Because the toast component appears at the bottom of the screen
                        and disappears after 3 seconds.
                      </p>
                    </div>
                  </div>
                  <div className="ex-card false">
                    <img
                      className="ex-card-img"
                      src="/images/get-started-content/screen-modal-dialogs@3x.jpg"
                      alt="Modal dialogs"
                    />
                    <div className="ex-card-desc">
                      <BadgeStyled className="danger" iconLeft={<IconDanger />}>
                        Don&apos;t
                      </BadgeStyled>
                      <p>
                        Modal dialogs are a good way to ask to confirm a destructive action but not
                        to tell them an error has occurred. Modals block merchants until a decision
                        is made is likely to make merchants feel pressured.{' '}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="page-content-item">
                  <h3 className="h3" id="considerations">
                    Considerations
                  </h3>
                  <ul className="ordered-list">
                    <li>
                      <strong>Diverse Abilities:</strong> Crafting content that is accessible to
                      users with varying abilities, including those with visual impairments or
                      cognitive disabilities.
                    </li>
                    <li>
                      <strong>Mobile:</strong> Optimizing content for mobile devices, ensuring
                      readability and usability on smaller screens.
                    </li>
                    <li>
                      <strong>Big Data Sets:</strong> Organizing large volumes of content to
                      maintain clarity and ease of navigation.
                    </li>
                  </ul>
                  <h3 className="h3" id="actionable-language">
                    Actionable language
                  </h3>
                  <p>
                    Headings and subheadings are titles and subtitles that refer to sections of the
                    interface.
                    <br />
                    Headings and subheadings should be:
                  </p>
                  <ul className="disc-list">
                    <li>Keep headings to a single sentence</li>
                    <li>Use simple, clear language</li>
                    <li>Avoid using punctuation such as periods, commas, or semicolons</li>
                    <li>
                      Write in sentence case (capitalize the first word and proper nouns only)
                    </li>
                  </ul>
                </div>
                <div className="reccomendations mb-40">
                  <div className="reccomendation-item">
                    <BadgeStyled className="success" iconLeft={<IconSuccess />}>
                      Do
                    </BadgeStyled>
                    <ul className="disc-list">
                      <li>Online store dashboard</li>
                      <li>Custom reports</li>
                      <li>Sell your products in person</li>
                    </ul>
                  </div>
                  <div className="reccomendation-item">
                    <BadgeStyled className="danger" iconLeft={<IconDanger />}>
                      Don&apos;t
                    </BadgeStyled>
                    <ul className="disc-list">
                      <li>This is your online store dashboard</li>
                      <li>Custom Reports</li>
                      <li>Sell your products in person!</li>
                    </ul>
                  </div>
                  <div className="reccomendation-item">
                    <BadgeStyled className="success" iconLeft={<IconSuccess />}>
                      Do
                    </BadgeStyled>
                    <ul className="disc-list">
                      <li>Secure your account with two-step authentication</li>
                    </ul>
                  </div>
                  <div className="reccomendation-item">
                    <BadgeStyled className="danger" iconLeft={<IconDanger />}>
                      Don&apos;t
                    </BadgeStyled>
                    <ul className="disc-list">
                      <li>Two-step authentication</li>
                    </ul>
                  </div>
                  <div className="reccomendation-item">
                    <BadgeStyled className="success" iconLeft={<IconSuccess />}>
                      Do
                    </BadgeStyled>
                    <ul className="disc-list">
                      <li>Activate Apple pay</li>
                      <li>Explore free themes</li>
                      <li>View shipping settings</li>
                    </ul>
                  </div>
                  <div className="reccomendation-item">
                    <BadgeStyled className="danger" iconLeft={<IconDanger />}>
                      Don&apos;t
                    </BadgeStyled>
                    <ul className="disc-list">
                      <li>Try Apple Pay</li>
                      <li>Free themes</li>
                      <li>Settings</li>
                    </ul>
                  </div>
                </div>
                <h1 className="h1 divider" id="references">
                  References
                </h1>
                {dataExternalLinkGroups.map((externalLinkGroup) => (
                  <ExternalLinkGroupStyled
                    key={externalLinkGroup.id}
                    title={externalLinkGroup.title}
                    cards={externalLinkGroup.cards}
                  />
                ))}
                <div className="page-content-item">
                  <p>
                    By adhering to these content writing guidelines and considerations, we can
                    ensure that our digital products are inclusive, accessible, and user-friendly,
                    providing a better experience for all users.
                  </p>
                </div>
              </div>
              <div>
                <div className="page-navigation">
                  <h4>Contents</h4>
                  <Scrollspy
                    ids={[
                      'overview',
                      'definition',
                      'rationale',
                      'guidelines',
                      'best-practices',
                      'capitalization',
                      'considerations',
                      'actionable-language',
                      'references',
                    ]}
                    offset={0}
                    activeItemClassName="active"
                    itemContainerClassName="multilevel-link-list"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <PaginationStyled
          previousLink="/get-started/accessibility"
          previous="Accessibility"
          nextLink="/get-started/voice-and-tone"
          next="Voice and Tone"
        />
      </div>
    </div>
  </div>
);

ContentStructure.defaultProps = {
  className: '',
};

const Content = styled(ContentStructure)`
  ${commonPageContentStyles}
  ${navigationStyles([2, 3, 5, 6, 7, 8])}
`;

export default Content;
