import ReactPlayer from 'react-player';
import styled from 'styled-components';

import Article from '../../../components/Article';
import Button from '../../../components/Button';
import { ReactComponent as BoxesIcon } from '../../../images/icons/boxes.svg';
import { ReactComponent as ChevronRightIcon } from '../../../images/icons/chevron-right.svg';
import { ReactComponent as ListIcon } from '../../../images/icons/list.svg';
import tutorialsBg from '../../../images/tutorial-bg.jpg';

interface Props {
  className?: string;
}

const TutorialTab = ({ className }: Props) => (
  <section className={className}>
    <div className="tabs-content-left">
      <div className="tab-content-header">
        <h3>In-Depth Learning and Comprehensive Support Resources</h3>
        <div className="tabs-content-left__actions">
          <Button
            onClick={() => (window.location.href = 'https://dhcs.fuselabcreative.com/sign-in')}
            color="secondary"
            size="M"
            type="button"
          >
            <span className="button-name">Get Started</span>
            <span className="button-icon">
              <ChevronRightIcon />
            </span>
          </Button>
        </div>
      </div>

      <div className="articles-container">
        <div>
          <Article
            boldWordsCount={2}
            icon={<BoxesIcon />}
            content="Step-by-Step Guides Interactive tutorials guiding users through creating and customizing visualizations. From initial setup to advanced customization, these tutorials are designed to make the process intuitive and straightforward."
          />
        </div>
        <div>
          <Article
            boldWordsCount={2}
            icon={<ListIcon />}
            content="User-Friendly Documentation Easily accessible help articles and FAQs for quick troubleshooting. Quickly find detailed articles and frequently asked questions designed to assist with common issues and guide users through troubleshooting steps. "
          />
        </div>
      </div>
    </div>

    <div className="tabs-content-right">
      <ReactPlayer
        className="react-player"
        width="100%"
        height="100%"
        controls
        disablepictureinpicture
        url="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
        config={{
          file: {
            attributes: {
              controlsList: ['nodownload noplaybackrate'],
              disablepictureinpicture: '',
            },
          },
        }}
      />
    </div>
  </section>
);

TutorialTab.defaultProps = {
  className: '',
};

const StyledTutorialTab = styled(TutorialTab)`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  flex-direction: column-reverse;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-wrap: nowrap;
    flex-direction: row;
    min-height: 100%;
  }

  > .tabs-content-left {
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      flex-basis: 50%;
    }

    > .tab-content-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 16px;
      gap: 16px;

      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        flex-wrap: nowrap;
      }

      @media (min-width: ${({ theme }) => theme.breakpoints['2xl']}) {
        margin-bottom: 40px;
      }

      > h3 {
        font-size: ${({ theme }) => theme.fontSizes.base};
        font-weight: 600;
        line-height: ${({ theme }) => theme.lineHeights.base};
        color: ${({ theme }) => theme.colors.neutral.text};
      }

      > .tabs-content-left__actions {
        display: flex;
        gap: 24px;
      }
    }

    > .articles-container {
      display: flex;
      flex-direction: column;

      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        flex-direction: row;

        > div {
          flex-basis: 50%;
        }
      }
    }
  }

  > .tabs-content-right {
    display: none;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      display: flex;
      align-items: end;
      justify-content: end;
      flex-basis: 50%;
      background-image: url(${tutorialsBg});
      background-repeat: no-repeat;
      background-size: contain;
      background-position: bottom right;
      padding: 0 25px 25px;

      > .react-player {
        @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
          max-width: 555px;
          height: auto !important;

          > video {
            border: 8px solid ${({ theme }) => theme.colors.white};
            border-radius: 12px;
          }
        }
      }
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      padding: 0 43px 25px 0;
    }
  }
`;

export default StyledTutorialTab;
