import styled from 'styled-components';

interface Props {
  className?: string;
}

const Footer = ({ className }: Props) => (
  <footer className={className}>
    <ul>
      <li>About DHCS Visualization Portal</li>
      <li>News</li>
      <li>FAQs</li>
      <li>Support & Feedback</li>
    </ul>
  </footer>
);

Footer.defaultProps = {
  className: '',
};

const StyledFooter = styled(Footer)`
  padding: 14px 20px;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral.border};
  background-color: ${({ theme }) => theme.colors.neutral['background-medium']};

  > ul {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    list-style: none;
    justify-content: center;
    > li {
      color: ${({ theme }) => theme.colors.primary['text-hover']};
      font-size: ${({ theme }) => theme.fontSizes.sm};
      line-height: ${({ theme }) => theme.lineHeights.sm};
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      gap: 48px;
    }
  }
`;

export default StyledFooter;
