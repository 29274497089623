import styled from 'styled-components';

interface ColorPreviewProps {
  color?: string;
  className?: string;
}

const ColorPreview = ({ className, color }: ColorPreviewProps) => (
  <div className={`${className}-container`}>
    <div className={className} style={{ backgroundColor: `#${color}` }} />
    <div>
      <span>#</span>
      {color}
    </div>
  </div>
);

ColorPreview.defaultProps = {
  color: '',
  className: '',
};

const ColorPreviewStyled = styled(ColorPreview)`
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 0.125rem;
  border: 0.0625rem solid var(--dadce0, #dadce0);
  position: relative;

  &-container {
    display: inline-flex;
    gap: 0.5rem;
    align-items: center;
    text-transform: uppercase;
  }
`;

export default ColorPreviewStyled;
