import Scrollspy from 'react-scrollspy-ez';
import styled from 'styled-components';

import Button from '../../../components/Button';
import ContentHeaderStyled from '../../../components/ContentHeader';
import Header from '../../../components/Header';
import PaginationStyled from '../../../components/Pagination';
import SidebarStyled from '../../../components/Sidebar';
import { ReactComponent as Download } from '../../../images/icons/download.svg';
import commonPageContentStyles from '../../../styles/mixins/_commonPageContentStyles';
import navigationStyles from '../../../styles/mixins/navigationMenuStyles';

interface Props {
  className?: string;
}

const ExcelLibraryStructure = ({ className }: Props) => (
  <div className={className}>
    <Header className="header" />
    <SidebarStyled className="sidebar" />
    <div className="scrollable-content">
      <div className="content">
        <ContentHeaderStyled
          title="Excel Library"
          breadcrumbs={[
            { label: 'Dev Resources', url: '/dev-resources' },
            { label: 'Excel Library', url: '/' },
          ]}
        />
        <div className="page-container">
          <div className="page-content">
            <div className="page-content-with-navigation-wrapper">
              <div className="page-content-with-navigation">
                <div className="title-with-download-button">
                  <h1 className="h1" id="import-excel-chart-templates">
                    Import the .crtx (Excel Chart Templates) files
                  </h1>
                  <Button color="secondary" size="M" type="button" data-link="#">
                    <span className="button-name">Download Excel Library</span>
                    <span className="button-icon">
                      <Download />
                    </span>
                  </Button>
                </div>
                <div className="page-content-item mb-40">
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">1</span>
                    <p>
                      <strong>Open a blank Excel worksheet and insert data.</strong> Select the data
                      range and navigate to the Insert Tab -&gt; Recommended Charts -&gt; All Charts
                      -&gt; Templates -&gt; Manage Templates
                    </p>
                  </div>
                </div>
                <img
                  src="/images/dev-resources-excel-library/excel-library-1@3x.jpg"
                  alt="Excel Library"
                  className="mb-40 img-responsive"
                />
                <div className="page-content-item mb-40">
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">2</span>
                    <p>
                      <strong>An empty local folder</strong> will pop up.
                    </p>
                  </div>
                </div>
                <img
                  src="/images/dev-resources-excel-library/excel-library-2@3x.jpg"
                  alt="Excel Library"
                  className="mb-40 img-responsive"
                />
                <div className="page-content-item mb-40">
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">3</span>
                    <p>
                      <strong>Open the Excel Kit folder</strong> and copy-paste the chart templates
                      from the Excel Kit folder to the empty local folder that popped up under the
                      previous step.
                    </p>
                  </div>
                </div>
                <img
                  src="/images/dev-resources-excel-library/excel-library-3@3x.jpg"
                  alt="Excel Library"
                  className="mb-40 img-responsive"
                />
                <div className="page-content-item mb-40">
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">4</span>
                    <p>
                      <strong>Close all folders and files.</strong> No need to save anything.
                    </p>
                  </div>
                </div>
                <img
                  src="/images/dev-resources-excel-library/excel-library-4@3x.jpg"
                  alt="Excel Library"
                  className="mb-40 img-responsive"
                />
                <h1 className="h1 divider" id="use-the-crtx">
                  Use the .crtx (Excel Chart Templates) files
                </h1>
                <div className="page-content-item mb-40">
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">1</span>
                    <p>
                      <strong>Open a new Excel workbook</strong> and insert the data you are
                      interested in.
                    </p>
                  </div>
                </div>
                <img
                  src="/images/dev-resources-excel-library/excel-library-5@3x.jpg"
                  alt="Excel Library"
                  className="mb-40 img-responsive"
                />
                <div className="page-content-item">
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">2</span>
                    <p>
                      <strong>Select the data</strong> for which you want to create a chart.
                      Navigate to the Insert tab and click on Recommended Chart.
                    </p>
                  </div>
                </div>
                <img
                  src="/images/dev-resources-excel-library/excel-library-6@3x.jpg"
                  alt="Excel Library"
                  className="mb-40 img-responsive"
                />
                <div className="page-content-item">
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">3</span>
                    <p>
                      <strong>Click on All Charts -&gt; Templates.</strong> Select the chart type
                      you are interested in and click on OK.
                    </p>
                  </div>
                </div>
                <img
                  src="/images/dev-resources-excel-library/excel-library-7@3x.jpg"
                  alt="Excel Library"
                  className="mb-40 img-responsive"
                />
                <div className="page-content-item">
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">4</span>
                    <p>
                      <strong>Click on the chart,</strong> navigate to the Design tab and click on
                      Select Data in order to adjust chart data.
                    </p>
                  </div>
                </div>
                <img
                  src="/images/dev-resources-excel-library/excel-library-8@3x.jpg"
                  alt="Excel Library"
                  className="mb-40 img-responsive"
                />
                <div className="page-content-item">
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">5</span>
                    <p>
                      <strong>Click on the Edit button</strong> under Horizontal (Category) Axis
                      Labels in order to adjust the X-axis.
                    </p>
                  </div>
                </div>
                <img
                  src="/images/dev-resources-excel-library/excel-library-9@3x.jpg"
                  alt="Excel Library"
                  className="mb-40 img-responsive"
                />
                <div className="page-content-item">
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">6</span>
                    <p>
                      <strong>Select the data range</strong> that contains the labels for the X-axis
                      and click on OK.
                    </p>
                  </div>
                </div>
                <img
                  src="/images/dev-resources-excel-library/excel-library-10@3x.jpg"
                  alt="Excel Library"
                  className="mb-40 img-responsive"
                />
                <div className="page-content-item">
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">7</span>
                    <p>Click on OK.</p>
                  </div>
                </div>
                <img
                  src="/images/dev-resources-excel-library/excel-library-11@3x.jpg"
                  alt="Excel Library"
                  className="mb-40 img-responsive"
                />

                <div className="page-content-item">
                  <div className="pseudo-list">
                    <span className="pseudo-list-nr">8</span>
                    <p>The chart values will update accordingly</p>
                  </div>
                </div>
                <img
                  src="/images/dev-resources-excel-library/excel-library-12@3x.jpg"
                  alt="Excel Library"
                  className="mb-40 img-responsive"
                />
                <div className="page-content-item">
                  <div className="pseudo-list mb-0">
                    <span className="pseudo-list-nr">9</span>
                    <p className="mb-0">
                      <strong>Proceed to update</strong> the Chart Title and Axis Titles.
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div className="page-navigation">
                  <h4>Contents</h4>
                  <Scrollspy
                    ids={['import-excel-chart-templates', 'use-the-crtx']}
                    offset={0}
                    activeItemClassName="active"
                    itemContainerClassName="multilevel-link-list"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <PaginationStyled
          previousLink="/dev-resources/powerbikit"
          previous="Power BI Library"
          nextLink="/dev-resources/esrikit"
          next="ESRI Library"
        />
      </div>
    </div>
  </div>
);

ExcelLibraryStructure.defaultProps = {
  className: '',
};

const ExcelLibrary = styled(ExcelLibraryStructure)`
  ${commonPageContentStyles}
  ${navigationStyles([])}
`;

export default ExcelLibrary;
