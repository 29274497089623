import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import Footer from '../Footer';
import Header from '../Header';
import SidebarStyled from '../Sidebar';

import MenuMobile from './components/MenuMobile';

interface Props {
  className?: string;
}

const LayoutWithSidebar = ({ className }: Props) => (
  <div className={className}>
    <Header className="header desktop-menu" />
    <MenuMobile className="header mobile-menu" />

    <SidebarStyled className="sidebar" />
    <div className="content">
      <main className="content__main">
        <Outlet />
      </main>

      <Footer className="footer" />
    </div>
  </div>
);

LayoutWithSidebar.defaultProps = {
  className: '',
};

const StyledLayoutWithSidebar = styled(LayoutWithSidebar)`
  display: grid;
  grid-gap: 0;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    'header  header'
    'sidebar content'
    'sidebar footer';
  overflow: hidden;

  > .header {
    grid-area: header;

    &.mobile-menu {
      display: block;
      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        display: none;
      }
    }

    &.desktop-menu {
      display: none;
      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        display: flex;
      }
    }
  }

  > .sidebar {
    display: none;
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      display: block;
      overflow-x: hidden;
      grid-area: sidebar;
      height: calc(100vh - 65px);
    }
  }

  > .content {
    width: 100%;
    grid-area: content;
    overflow-x: hidden;
    height: calc(100vh - 48px);

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      height: calc(100vh - 65px);
    }

    > .content__main {
      min-height: calc(100% - 45px);
      display: flex;
      justify-content: center;
    }

    > .footer {
      grid-area: footer;
    }
  }
`;

export default StyledLayoutWithSidebar;
