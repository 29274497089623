const commonPageContentStyles = () => `
  display: grid;
  grid-gap: 0;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    'header  header'
    'sidebar content';
  overflow: hidden;
  height: 100vh;

  .header {
    grid-area: header;
    padding: 2rem;
    max-height: 4rem;
  }

  .sidebar {
    grid-area: sidebar;
    overflow-x: hidden;
    height: calc(100vh - 4rem);
  }

  .scrollable-content {
    grid-area: content;
    overflow-x: hidden;
    height: calc(100vh - 4rem);
  }
  
  .content {
    margin: 0 auto;
    padding: 24px 16px 32px 16px;
    
   @media (min-width: 768px) {
     padding: 2.5rem 1.25rem 0;
    }

    @media (min-width: 1620px) {
      width: 75rem;
    }
  }

  .page-container {
    display: flex;
    justify-content: space-between;
    gap: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .page-content {
    flex: 1;
    position: relative;

    .h1 {
      font-size: 2rem;
      font-weight: 300;
      line-height: 1.25;
      letter-spacing: -0.03em;
      color: var(--030404, #030404);
      margin-top: 0;
      margin-bottom: 2.5rem;
    }

    .divider {
        border-top: 0.0625rem dashed var(--dadce0, #dadce0);
        padding-top: 2.5rem;
    }
    
    .h2 {
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 1.333333333333333;
        letter-spacing: -0.02em;
        color: var(--030404, #030404);
        margin-top: 0;
        margin-bottom: 1rem;
    }
    
    .h3 {
        font-size: 1.25rem;
        font-weight: 600;
        margin-bottom: 1rem;
        line-height: 1.2;
        letter-spacing: -0.02em;
        color: var(--2f3237, #2f3237);
        margin-top: 0;
    }
    
    h4 {
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.25;
      letter-spacing: -0.01em;
      color: var(--20262d, #20262d);
      margin-top: 0;
      margin-bottom: 0.25rem;
    }

    .h4 {
      color: var(--5c636e, #5c636e);
      margin-bottom: 1rem;
    }
  
    p {
      line-height: 1.5;
      color: var(--5c636e, #5c636e);
      margin-top: 0;
    }
  
    strong {
      color: var(--5c636e, #5c636e);
      font-weight: 600;
    }
  
    a {
      color: var(--006b8a, #006b8a);
      text-decoration: none;
  
      &:hover {
         color: var(--155676, #155676);
      }
    }
  
    ul {
      line-height: 1.5;
      color: var(--5c636e, #5c636e);
      list-style: none;
      padding: 0;
      margin-bottom: 2.5rem;

      li {
        margin-bottom: 1rem;

        strong {
        font-weight: 600;
        }
      }
    }

    ol {
      line-height: 1.5;
      color: var(--5c636e, #5c636e);
      padding: 0;
      margin-bottom: 1.5rem;

      li {
        margin-bottom: 0.5rem;
      }

      &.lower-latin {
        list-style-type: lower-latin;
        list-style-position: inside;
      }

      &.disc {
        list-style-type: disc;
        list-style-position: inside;
      }
    }

    .ordered-list {
      padding-left: 1.75rem;
      counter-reset: num;

      li {
        position: relative;

        &::before {
          content: counter(num) '. ';
          counter-increment: num;
          position: absolute;
          left: -1.25rem;
          top: 0;
          font-size: 1rem;
          line-height: 1.5;
          font-weight: 600;
          color: var(--5c636e, #5c636e);
        }
      }
    }

    .ordered-list-badge-num {
        font-size: 0.875rem;
        line-height: 1.4285714286;
        padding-left: 2.5rem;
        counter-reset: num;
  
        li {
          position: relative;
  
          &::before {
            content: counter(num);
            counter-increment: num;
            position: absolute;
            left: -1.75rem;
            top: 0;
            width: 1.25rem;
            height: 1.25rem;
            border-radius: 0.1875rem;
            text-align: center;
            font-family: 'Sora', sans-serif;
            font-size: 0.6875rem;
            line-height: 1.8;
            color: var(--155676, #155676);
            background: var(--2d6e8d3d, #2d6e8d3d);
          }
        }
      }

      .ordered-list-badge-latin {
        font-size: 0.875rem;
        line-height: 1.4285714286;
        padding-left: 2.5rem;
        counter-reset: num;
  
        li {
          position: relative;
  
          &::before {
            content: counter(num, upper-latin);
            counter-increment: num;
            position: absolute;
            left: -1.75rem;
            top: 0;
            width: 1.25rem;
            height: 1.25rem;
            border-radius: 0.1875rem;
            text-align: center;
            font-family: 'Sora', sans-serif;
            font-size: 0.6875rem;
            line-height: 1.8;
            color: var(--155676, #155676);
            background: var(--2d6e8d3d, #2d6e8d3d);
          }
        }
      }

      .inline-latin {
        display: flex;
        gap: 1.875rem;
        font-size: 0.875rem;
        margin-top: 0.5rem;
        margin-bottom: 0;
      }

    .inside-list {
      list-style-position: inside;
    }
      
    .disc-list {
      list-style-type: disc;
      padding-left: 1.75rem;

      li {
        margin-bottom: 0;
      }
    }

    .page-navigation {
      position: sticky;
      top: 5rem;
      left: 100%;
      width: 11.25rem;

      ul {
        border-left: 0.125rem solid var(--dadce0, #dadce0);
        font-size: 0.875rem;
        margin: 0;

        li {
          display: block; 
          padding: 0.5rem 0.5rem 0.5rem 1.375rem;
          text-decoration: none;
          cursor: pointer;
          transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out;
          
          &.active {
            border-color: var(--2d6e8d, #2d6e8d);
            color: var(--155676, #155676);
          }
          
          &:not(.active) {
            color: var(--5c636e, #5c636e);

            &:hover {
              color: var(--155676, #155676);
              border-color: var(--2d6e8d, #2d6e8d);
            }
          }
        }
      }

      li {
        font-size: 0.875rem;
        margin: 0;
        display: block; 
        padding: 0.5rem 0.5rem 0.5rem 1.375rem;
        text-decoration: none;
        transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out;
        
        &.active {
          border-color: var(--2d6e8d, #2d6e8d);
          color: var(--155676, #155676);
        }
        
        &:not(.active) {
          color: var(--5c636e, #5c636e);

          &:hover {
            color: var(--155676, #155676);
            border-color: var(--2d6e8d, #2d6e8d);
          }
        }

        ul {
          margin-left: 1.5rem;
          margin-bottom: 0;
          border-left: 0.0625rem solid var(--dadce0, #dadce0);
          padding-left: 0.9375rem;
          border-left: 0.0625rem solid transparent;
          margin-left: -0.0625rem;

          li.active {
            border-color: var(--2d6e8d, #2d6e8d);
            color: var(--155676, #155676);
          }
        }

        > li {
          > a {
            border-left: 0.125rem solid transparent;
            margin-left: -0.125rem;
          }
        }

      }
    }

    .page-content-with-navigation {
      flex: 1;
      max-width: 62.5rem;

      &-wrapper {
        position: relative;
        display: flex;
        gap: 2.5rem;
        justify-content: space-between;
      }
    }

    .page-content-with-quiz {
      flex: 1;
      max-width: 78.75rem;

      &-wrapper {
        position: relative;
        display: flex;
        gap: 4.2rem;
        justify-content: space-between;
        align-items: flex-start;
      }
    }
  }

  .page-content-item {
    max-width: 43.75rem;
  }

  .table {
    border-top: 0.0625rem solid var(--dfe1e4, #dfe1e4);
    border-bottom: 0.0625rem dashed var(--dfe1e4, #dfe1e4);
    border-collapse: collapse;
    width: 100%;

    thead {
      border-bottom: 0.0625rem solid var(--dfe1e4, #dfe1e4);
      background: var(--f5f5f5, #f5f5f5);
    }

    th {
      font-size: 0.6875rem;
      line-height: 1.272727272727273;
      font-weight: 400;
      color: var(--5f6877, #5f6877);
      padding: 0.3125rem 0.5rem;
      border-right: 0.0625rem solid var(--dfe1e4, #dfe1e4);
      white-space: nowrap;
      text-align: start;

      &:last-child {
        border-right: none;
      }
    }

    td {
      font-size: 0.75rem;
      line-height: 1.166666666666667;
      font-weight: 400;
      color: var(--000005, #000005);
      padding: 0.875rem 1rem 0.875rem 0.5rem;
    }

    span {
      color: var(--5c636e, #5c636e);
      margin-right: 0.125rem;
    }

    &-colors {
        border-bottom: none;

        tr {
            border-bottom: 1px solid var(--dadce0, #dadce0);

            &:last-child {
                border-bottom: none;
            }
        }

        td {
            font-size: 0.875rem;
            line-height: 1.428571428571429;
            color: var(--5c636e, #5c636e);
            padding: 1.5rem 0.5rem;
        }

        .role-color {
            display: inline-flex;
            align-items: center;
            gap: 0.5rem;
            background-color: var(--f2f3f4, #f2f3f4);
            border-radius: 0.5rem;
            padding: 0.375rem;
            margin-left: 0.75rem;
        }

        .color {
            border-radius: 0.208125rem;
            width: 1.25rem;
            height: 1.25rem;
            display: block;
        }
    }

    &-variants {

      tr {
        border-bottom: 1px solid var(--dadce0, #dadce0);
      }
      
      td {
        font-size: 0.875rem;
        color: var(--2f3237, #2f3237);
      }

      .role-color {
        font-size: 0.75rem;
        font-weight: 500;
        line-height: 1.166666666666667;
        background-color: var(--5f687714, #5f687714);
        display: inline-block;
        padding: 0.3125rem 0.375rem;
        border-radius: 0.1875rem;
      }
    }
  }

  .checkmark-container {
    display: inline-flex;
    gap: 0.5rem;
    align-items: center;
    text-transform: uppercase;

    .checkmark {
      color: var(--578659, #578659);
    }
  }

  .content-item {
    max-width: 62.5rem;
    border-bottom: 1px dashed var(--dadce0, #dadce0);
    margin-bottom: 1.5rem;
  }

  .ex-card {
    height: 100%;
    display: flex;
    flex-direction: column;

    &s {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      column-gap: 1.5rem;
      row-gap: 2.5rem;
      margin-bottom: 2.5rem;

      .true {
        border-top: 0.125rem solid var(--138660, #138660);
      }

      .false {
        border-top: 0.125rem solid var(--c53660, #c53660);
      }

      p {
        line-height: 1.25;
        letter-spacing: -0.01em;
        margin-bottom: 0;
      }

      figure {
        margin-bottom: 0;
      }
    }

    &-img {
      display: block;
      overflow: hidden;
    }

    &-desc {
      flex-direction: column;
      align-items: flex-start;
      display: flex;
      gap: 1rem;
      padding: 1.5rem;
      border: 0.0625rem solid var(--dadce0, #dadce0);
      border-top: transparent;
      border-radius: 0 0 0.5rem 0.5rem;
      flex: 1;

      .disc-list {
        margin: 0;
      }
    }
  }

  .number-card {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &s {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      column-gap: 1.5rem;
      row-gap: 2.5rem;
      margin-bottom: 2.5rem;

      img {
        max-width: 100%;
        height: auto;
      }

      strong {
        display: block;
        font-size: 1rem;
        letter-spacing: -0.01em;
      }

      p {
        font-size: 0.875rem;
        line-height: 1.428571428571429;
        margin-top: 0.5rem;
        margin-bottom: 1.25rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &-desc-row {
      strong {
        font-size: 0.875rem;
        display: inline-block;
      }

      p {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &-img {
      display: flex;
      position: relative;
    }

    .number-circle {
      position: absolute;
      top: 1rem;
      left: 1rem;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      background: var(--2685a1, #2685a1);
      color: var(--ffffff, #ffffff);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.142857142857143;
    }
  }

  .examples-wrapper {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 1.5rem;
    row-gap: 2rem;
    margin-bottom: 2.5rem;

    &-item {
      display: flex;
      gap: 1rem;
      flex-direction: column;
      margin-bottom: 0;
    }

    p {
      font-size: 0.875rem;
      line-height: 1.428571428571429;
      margin-bottom: 0;
    }
  }

  figure {
    display: grid;
    gap: 1rem;
    margin: 0 0 2.5rem;

    img {
      max-width: 100%;
      height: auto;
    }

    figcaption {
      letter-spacing: -0.01em;
      color: var(--8e95a0, #8e95a0);
    }
  }

  .figure-sm {
    gap: 1.5rem;

    figcaption {
      max-width: 43.75rem;
      font-size: 0.875rem;
      line-height: 1.428571428571429;
      color: var(--5c636e, #5c636e);
    }
  }

  .reccomendation {
    &s {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 2.5rem 1.5rem;
    }

    &-item {
      border: 1px solid var(--dadce0, #dadce0);
      border-radius: 0.5rem;
      box-shadow: 0px 2px 2px 0px var(--141c2c0a, #141c2c0a);
      padding: 1.5rem 2rem;
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 1.5rem;

      .disc-list {
        margin: 0;
      }
    }
  }

  .img-responsive {
    max-width: 100%;
    height: auto;
  }

  .pseudo-list {
    margin: 0 0 1.5rem;
    position: relative;
    padding-left: 1.75rem;
    font-size: 0.875rem;

    p,
    li {
      margin-bottom: 1rem;
      line-height: 1.428571428571429;
    }

    &-nr {
      position: absolute;
      left: 0;
      top: 0;
      width: 1.25rem;
      height: 1.25rem;
      border-radius: 0.1875rem;
      text-align: center;
      font-family: 'Sora', sans-serif;
      font-size: 0.6875rem;
      line-height: 1.8;
      color: var(--155676, #155676);
      background: var(--2d6e8d3d, #2d6e8d3d);
      font-optical-sizing: auto;
    }
  }

  .title-with-download-button {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    align-items: center;
    margin-bottom: 1.5rem;
    
    h1 {
      margin-bottom: 0 !important;
    }
  }

  .download-assets {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--dadce0, #dadce0);
    border-radius: 0.375rem;
    padding: 0.6875rem 1rem;

    &-buttons,
    &-info {
      display: flex;
      gap: 0.5rem;
    }

    &-icon {
      padding: 0.25rem;

      svg {
        display: block;
      }
    }

    &-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 0.25rem;

      strong {
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 1.428571428571429;
        color: var(--5c636e, #5c636e);
      }

      p {
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.428571428571429;
        color: var(--8e95a0, #8e95a0);
        margin-bottom: 0;

        span {
          color: var(--5c636e, #5c636e);
        }
      }
    }
  }

  .background-color {
    display: block;
    width: 100%;
    height: 1.25rem;
    border-radius: 0.25rem;

    &s {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      column-gap: 1.5rem;
      row-gap: 2.5rem;
      margin-bottom: 2.5rem;
    }

    &s-item {
      display: flex;
      gap: 1rem;
      flex-direction: column;
      margin-bottom: 0;

      strong {
        display: block;
        letter-spacing: -0.01em;
      }

      p {
        font-size: 0.875rem;
        line-height: 1.428571428571429;
        margin-bottom: 0;

        span {
          font-weight: 500;
        }
      }
    }
  }

  .news {
    border: 1px solid var(--dfe1e4, #dfe1e4);
    border-radius: 0.25rem;
    display: grid;
    gap: 1.5rem;
    padding: 2.5rem 2.8125rem;
    margin-bottom: 2.5rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    @media (min-width: 1620px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    &-item {
      display: flex;
      flex-direction: column;
      gap: 1.25rem;
      align-items: flex-start;
    }

    &-img {
      display: block;
      overflow: hidden;
      border-radius: 0.5rem;
    }

    &-desc {
      display: flex;
      flex-direction: column;
      gap: 0.625rem;
      align-items: flex-start;

      strong {
        font-size: 1rem;
        font-weight: 700;
        line-height: 1.5;
        color: var(--030404, #030404);
      }

      p {
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.428571428571429;
        margin-bottom: 0;
      }
    }

    &-data {
      font-size: 0.625rem;
      font-weight: 400;
      line-height: 1.2;
      color: var(--5c636e, #5c636e);
      padding: 0.375rem 0.625rem;
      border-radius: 0.875rem;
      border: 1px solid var(--dadce0, #dadce0);
    }
  }
`;
export default commonPageContentStyles;
