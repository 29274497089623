import styled from 'styled-components';

import { ReactComponent as ChevronDown } from '../../images/icons/chevron-down.svg';
import Button from '../Button';

interface Props {
  className?: string;
  imageLink: string;
}

const ChartCard = ({ className, imageLink }: Props) => (
  <div className={className}>
    <div className="chart-header">
      <div>
        <p>
          Please select a kit from the dropdown menu to change the chart’s view and access the
          appropriate kit page.
        </p>
        <p>
          Follow the provided instructions to download the kit, locate your plot, and complete the
          installation process.
        </p>
      </div>
      <Button color="secondary" size="M" type="button" data-link="#">
        <span className="button-name">Continue to Download</span>
        <span className="button-icon">
          <ChevronDown />
        </span>
      </Button>
    </div>
    <img className="chart-image img-responsive" src={imageLink} alt="Chart" />
  </div>
);

ChartCard.defaultProps = {
  className: '',
};

const ChartCardStyled = styled(ChartCard)`
  border: 0.0625rem solid var(--dadce0, #dadce0);
  border-radius: 0.375rem;
  margin-bottom: 2.5rem;

  .chart-header {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    padding: 0.6875rem 1rem 0.75rem;
    background: var(--747c8914, #747c8914);
    border-bottom: 0.0625rem solid var(--dadce0, #dadce0);

    p {
      max-width: 43.75rem;
      color: var(--36404d, #36404d);
      margin: 0;
      font-size: 0.75rem;
      line-height: 1.333333333333333;

      strong {
        font-weight: 500;
      }
    }
  }
`;

export default ChartCardStyled;
