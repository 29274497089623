export const MENU_ROUTES = {
  builder: {
    name: 'Builder',
    path: '/builder',
  },
  overview: {
    name: 'Overview',
    path: '/overview',
  },
  designSystem: {
    name: 'Design System',
    path: '/',
  },
};
