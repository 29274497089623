import Scrollspy from 'react-scrollspy-ez';
import styled from 'styled-components';

import BadgeStyled from '../../../components/Badge';
import ContentHeaderStyled from '../../../components/ContentHeader';
import ExternalLinkGroupStyled from '../../../components/ExternalLinkGroup';
import Header from '../../../components/Header';
import PaginationStyled from '../../../components/Pagination';
import SidebarStyled from '../../../components/Sidebar';
import { ReactComponent as IconDanger } from '../../../images/icons/icon-danger.svg';
import { ReactComponent as IconSuccess } from '../../../images/icons/icon-success.svg';
import commonPageContentStyles from '../../../styles/mixins/_commonPageContentStyles';
import navigationStyles from '../../../styles/mixins/navigationMenuStyles';

interface Props {
  className?: string;
}

const dataExternalLinkGroups = [
  {
    id: 1,
    title: 'Government accessibility resources',
    cards: [
      {
        title: 'Find your Section 508 Coordinator',
        subtitle: '[section508.gov]',
        linkUrl: '#',
      },
      {
        title: 'Plain language resources',
        subtitle: '[plainlanguage.gov]',
        linkUrl: '#',
      },
      {
        title: '18F’s accessibility guide ',
        subtitle: '[accessibility.18f.gov]',
        linkUrl: '#',
      },
    ],
  },
];

const FormElementsStructure = ({ className }: Props) => (
  <div className={className}>
    <Header className="header" />
    <SidebarStyled className="sidebar" />
    <div className="scrollable-content">
      <div className="content">
        <ContentHeaderStyled
          title="Form Elements"
          breadcrumbs={[
            { label: 'General Components', url: '/general-components' },
            { label: 'Form Elements' },
          ]}
        />
        <div className="page-container">
          <div className="page-content">
            <div className="page-content-with-navigation-wrapper">
              <div className="page-content-with-navigation">
                <div className="page-content-item">
                  <h1 className="h1" id="overview">
                    Overview
                  </h1>
                  <h3 className="h3" id="usage">
                    Usage
                  </h3>
                  <p className="mb-40">
                    Use forms when input is required to complete an action. Forms direct people to
                    provide and submit relevant information, such as changing personal settings or
                    creating a Jira issue. <br />
                    Common form components include text fields, selects, radio buttons, checkboxes,
                    date pickers, toggles, and buttons.
                  </p>
                  <h3 className="h3" id="behaviors">
                    Behaviors
                  </h3>
                  <p className="mb-40">
                    Forms items can be labeled as either optional or required depending on several
                    factors. A common distinction in IBM products for using required or optional is
                    the forms complexity.
                  </p>
                  <h1 className="h1" id="specifications">
                    Specifications
                  </h1>
                  <h3 className="h3" id="anatomy">
                    Anatomy
                  </h3>
                  <ul className="font-sm">
                    <li>
                      <strong>1. Header (optional):</strong> The addition of a title and description
                      as a header that can provide the user with
                    </li>
                    <li>
                      <strong>2. Form body:</strong> Area where users provide information.
                      Information can be entered through various input fields, including text
                      fields, checkboxes, and many other types. Input components contain labels and
                      helper text that the user submits correct data.
                    </li>
                    <li>
                      <strong>3. Footer:</strong> Provides actions that allow users to submit or
                      cancel the data entered in the form.
                    </li>
                  </ul>
                </div>
                <img
                  src="/images/general-components-form-elements/screen-alignement@3x.jpg"
                  alt=""
                  className="mb-40 img-responsive"
                />
                <div className="page-content-item">
                  <h3 className="h3" id="alignment">
                    Alignment
                  </h3>
                  <p>
                    In a form, input components should vertically align with all other form
                    components. Whether a form aligns flush to grid columns or hangs in the gutters
                    depends on the style of form input you are using as well as the gutter mode.
                  </p>
                  <p className="mb-40">
                    Default forms typically use a wide gutter mode and align flush to the grid
                    columns prioritizing vertical label alignment. Fluid forms use the condensed
                    grid and can hang into the gutter without causing label misalignment. Fluid
                    forms are architectural and remain cohesive by never allowing vertical or
                    horizontal space between inputs.
                  </p>
                </div>
                <div className="ex-cards">
                  <div className="ex-card true">
                    <img
                      className="ex-card-img"
                      src="/images/general-components-form-elements/screen-alignment-01@3x.jpg"
                      alt=""
                    />
                    <div className="ex-card-desc">
                      <BadgeStyled className="success" iconLeft={<IconSuccess />}>
                        Do
                      </BadgeStyled>
                      <p>
                        Do align default form inputs and labels to the grid columns using the wide
                        gutter mode.
                      </p>
                    </div>
                  </div>
                  <div className="ex-card false">
                    <img
                      className="ex-card-img"
                      src="/images/general-components-form-elements/screen-alignment-02@3x.jpg"
                      alt=""
                    />
                    <div className="ex-card-desc">
                      <BadgeStyled className="danger" iconLeft={<IconDanger />}>
                        Don&apos;t
                      </BadgeStyled>
                      <p>
                        Don&apos;t hang default inputs into the grid gutters. Doing so will cause
                        labels to misalign with other form elements.
                      </p>
                    </div>
                  </div>
                </div>
                <h1 className="h1 divider" id="guidelines">
                  Guidelines
                </h1>
                <div className="page-content-item">
                  <h3 className="h3" id="form-controls">
                    Form controls
                  </h3>
                  <p className="mb-40">
                    Carbon provides a variety of data input components that enable a user to enter
                    or select data. The arbor rrrrires a rrrietr of rata rut rorrrronte that ershlo
                    a ucor to ontor or colort rate The components are divided into three different
                    interaction tvdes.
                  </p>
                </div>
                <table className="table table-variants mb-40">
                  <thead>
                    <tr>
                      <th style={{ width: '15.625rem' }}>State</th>
                      <th>When to use</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td valign="top">Free form inputs</td>
                      <td>
                        When a text input is live but a user is not directly interacting with it.
                        This is commonly referred to as the default or normal state of the
                        component. An enabled text input field can contain no content, placeholder
                        text, or user generate content.
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">Selection controls</td>
                      <td>
                        Selection controls offer users a selection from pre-determined options.
                        Common selection controls include: checkboxes, radio buttons, file
                        uploaders, toggles, and select lists (combo box and multiselect).
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">Bound entry. controls</td>
                      <td>
                        Selection controls offer users a selection from pre-determined options.
                        Common selection controls include: checkboxes, radio buttons, file
                        uploaders, toggles, and select lists (combo box and multiselect).
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="page-content-item">
                  <h3 className="h3" id="states">
                    States
                  </h3>
                  <p className="mb-40">
                    The following are common states that can be applied to components when used in a
                    form.
                  </p>
                </div>
                <div className="number-cards">
                  <div className="number-card">
                    <div className="number-card-img">
                      <span className="number-circle">1</span>
                      <img
                        src="/images/general-components-form-elements/screen-states-01@3x.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="number-card">
                    <div className="number-card-img">
                      <span className="number-circle">2</span>
                      <img
                        src="/images/general-components-form-elements/screen-states-02@3x.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="number-card">
                    <div className="number-card-img">
                      <span className="number-circle">3</span>
                      <img
                        src="/images/general-components-form-elements/screen-states-03@3x.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="number-card">
                    <div className="number-card-img">
                      <span className="number-circle">4</span>
                      <img
                        src="/images/general-components-form-elements/screen-states-04@3x.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="number-card">
                    <div className="number-card-img">
                      <span className="number-circle">5</span>
                      <img
                        src="/images/general-components-form-elements/screen-states-05@3x.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <table className="table table-variants mb-40">
                  <thead>
                    <tr>
                      <th style={{ width: '15.625rem' }}>Type</th>
                      <th>Overview</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td valign="top">1. Enabled</td>
                      <td>
                        Free-form text inputs are the most commonly used components in forms. They
                        allow a user to enter any configuration of letters or numbers. Common
                        free-form inputs include: text input and text area.
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">2. Active</td>
                      <td>When a user is actively typing content into the field.</td>
                    </tr>
                    <tr>
                      <td valign="top">3. Focus</td>
                      <td>
                        When a user tabs to or clicks on the text input, it becomes focused,
                        indicating the user has successfully navigated to the component.
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">4. Disabled</td>
                      <td>
                        When the user is not allowed to interact with the text input due to either
                        permissions, dependencies, or pre-requisites. The disabled state completely
                        removes the interactive function from a component. The styling is not
                        subject to WCAG contrast compliance.
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="page-content-item mb-40">
                  <h3 className="h3" id="button-positioning">
                    Button positioning
                  </h3>
                  <p>
                    Buttons are sorted by importance from right to left, with the most important
                    button (primary) on the right.
                  </p>
                </div>
                <img
                  src="/images/general-components-form-elements/screen-button-positioning-01@3x.jpg"
                  alt=""
                  className="mb-40 img-responsive"
                />
                <div className="page-content-item mb-40">
                  <p>
                    Exceptions to this are single-page forms, flags, and section messages where
                    buttons are left aligned with the primary button on the left.
                  </p>
                </div>
                <img
                  src="/images/general-components-form-elements/screen-button-positioning-02@3x.jpg"
                  alt=""
                  className="mb-40 img-responsive"
                />
                <h1 className="h1 divider" id="references">
                  References
                </h1>
                {dataExternalLinkGroups.map((externalLinkGroup) => (
                  <ExternalLinkGroupStyled
                    key={externalLinkGroup.id}
                    title={externalLinkGroup.title}
                    cards={externalLinkGroup.cards}
                  />
                ))}
              </div>
              <div>
                <div className="page-navigation">
                  <h4>Contents</h4>
                  <Scrollspy
                    ids={[
                      'overview',
                      'usage',
                      'behaviors',
                      'specifications',
                      'anatomy',
                      'alignment',
                      'guidelines',
                      'form-controls',
                      'states',
                      'button-positioning',
                      'references',
                    ]}
                    offset={0}
                    activeItemClassName="active"
                    itemContainerClassName="multilevel-link-list"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <PaginationStyled
          previousLink="/general-components/links"
          previous="Links"
          nextLink="/general-components/communication"
          next="Communication"
        />
      </div>
    </div>
  </div>
);

FormElementsStructure.defaultProps = {
  className: '',
};

const FormElements = styled(FormElementsStructure)`
  ${commonPageContentStyles}
  ${navigationStyles([2, 3, 5, 6, 8, 9, 10])}
`;

export default FormElements;
