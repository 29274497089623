import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import ExternalLinkStyled from '../ExternalLink';

interface ExternalLinkProps {
  className?: string;
  title?: string;
  cards?: {
    title: string;
    subtitle: string;
    linkUrl: string;
  }[];
}

const ExternalLinkGroup = ({ title, cards, className }: ExternalLinkProps) => (
  <div className={className}>
    <div>
      <h3 className="title-group">{title}</h3>
    </div>
    <div className="group">
      {cards?.map((card) => (
        <ExternalLinkStyled
          key={uuidv4()}
          title={card.title}
          subtitle={card.subtitle}
          linkUrl={card.linkUrl}
        />
      ))}
    </div>
  </div>
);

ExternalLinkGroup.defaultProps = {
  className: '',
  title: '',
  cards: [],
};

const ExternalLinkGroupStyled = styled(ExternalLinkGroup)`
  position: relative;

  .title-group {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: -0.02em;
    color: var(--2f3237, #2f3237);
    margin-top: 0;
    margin-bottom: 1.5rem;
  }

  .group {
    display: grid;
    gap: 1rem;
    margin-bottom: 2.5rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    @media (min-width: 1620px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
`;

export default ExternalLinkGroupStyled;
