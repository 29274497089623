import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as ChevronRight } from '../../images/icons/chevron-right.svg';

interface Props {
  className?: string;
  href: string;
  children: string;
}

const Link = ({ className, children, href }: Props) => (
  <RouterLink className={className} to={href}>
    {children}
    <ChevronRight />
  </RouterLink>
);

Link.defaultProps = {
  className: '',
};

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primary.text};
  text-decoration: none;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  line-height: ${({ theme }) => theme.lineHeights.sm};
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-weight: 500;
  gap: 2px;

  &:hover,
  &:active {
    color: ${({ theme }) => theme.colors.primary['text-hover']};
  }
`;

export default StyledLink;
