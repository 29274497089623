import Scrollspy from 'react-scrollspy-ez';
import styled from 'styled-components';

import BadgeStyled from '../../../components/Badge';
import ContentHeaderStyled from '../../../components/ContentHeader';
import ExternalLinkGroupStyled from '../../../components/ExternalLinkGroup';
import Header from '../../../components/Header';
import PaginationStyled from '../../../components/Pagination';
import SidebarStyled from '../../../components/Sidebar';
import { ReactComponent as IconDanger } from '../../../images/icons/icon-danger.svg';
import { ReactComponent as IconSuccess } from '../../../images/icons/icon-success.svg';
import commonPageContentStyles from '../../../styles/mixins/_commonPageContentStyles';
import navigationStyles from '../../../styles/mixins/navigationMenuStyles';

interface Props {
  className?: string;
}

const dataExternalLinkGroups = [
  {
    id: 1,
    title: 'Government Usability Testing Resources:',
    cards: [
      {
        title: 'Usability testing on Digital.gov',
        subtitle: '[digital.gov]',
        linkUrl: '#',
      },
      {
        title: 'Getting started with usability testing',
        subtitle: '[digital.gov]',
        linkUrl: '#',
      },
      {
        title: 'Inclusive design principles',
        subtitle: '[inclusivedesignprinciples.org]',
        linkUrl: '#',
      },
    ],
  },
  {
    id: 2,
    title: 'Government Accessibility Resources:',
    cards: [
      {
        title: 'The Section 508 accessibility program',
        subtitle: '[section508.gov]',
        linkUrl: '#',
      },
      {
        title: 'Accessibility for Teams guide',
        subtitle: '[accessibility.digital.gov]',
        linkUrl: '#',
      },
    ],
  },
];

const ColorsStructure = ({ className }: Props) => (
  <div className={className}>
    <Header className="header" />
    <SidebarStyled className="sidebar" />
    <div className="scrollable-content">
      <div className="content">
        <ContentHeaderStyled
          title="Colors"
          breadcrumbs={[
            { label: 'Branding Styleguide', url: '/branding-styleguide' },
            { label: 'Colors' },
          ]}
        />

        <div className="page-container">
          <div className="page-content">
            <div className="page-content-with-navigation-wrapper">
              <div className="page-content-with-navigation">
                <div className="page-content-item">
                  <h1 className="h1" id="overview">
                    Overview
                  </h1>
                  <h3 className="h3" id="definition">
                    Definition
                  </h3>
                  <p className="mb-40">
                    Application of the color palette brings a unified and recognizable consistency
                    array of digital products and interfaces. This consistency is grounded in a set
                    of well-defined rules about how to work with the component library.
                  </p>
                </div>
                <img
                  src="/images/branding-styleguide-colors/color-palette@3x.jpg"
                  alt="The color palette"
                  className="mb-40 img-responsive"
                />
                <h1 className="divider h1" id="specifications">
                  Specifications
                </h1>
                <div className="page-content-item">
                  <h3 className="h3" id="purpose">
                    Purpose
                  </h3>
                  <p className="mb-40">
                    The purpose of using color has to be clear. Color needs to support a message or
                    status that needs to be easily identifiable by merchants. Neutral colors apply
                    to most backgrounds, text, and shapes in our experiences. They don&apos;t
                    typically have a meaning associated with them, though they can imply things like
                    disabled states.
                  </p>
                </div>
                <table className="table table-colors mb-40">
                  <thead>
                    <tr>
                      <th>Role</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="role-color">
                          <span className="color" style={{ background: '#727B88' }} />
                          <span>Neutral</span>
                        </div>
                      </td>
                      <td>
                        Use for primary actions or elements that communicate the Atlassian brand.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="role-color">
                          <span className="color" style={{ background: '#1E3157' }} />
                          <span>Primary</span>
                        </div>
                      </td>
                      <td>
                        Use for default text and secondary UI elements, such as secondary buttons or
                        navigation elements.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="role-color">
                          <span className="color" style={{ background: '#D04C3C' }} />
                          <span>Alert</span>
                        </div>
                      </td>
                      <td>
                        Use for informative UI, such as an information icon, or UI that communicates
                        something is in progress.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="role-color">
                          <span className="color" style={{ background: '#DFB45C' }} />
                          <span>Warning</span>
                        </div>
                      </td>
                      <td>
                        Use for UI that communicates caution to prevent a mistake or error from
                        occurring.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="role-color">
                          <span className="color" style={{ background: '#62845D' }} />
                          <span>Success</span>
                        </div>
                      </td>
                      <td>Use to communicate a favorable outcome, such as a success message.</td>
                    </tr>
                    <tr>
                      <td>
                        <div className="role-color">
                          <span
                            className="color"
                            style={{
                              background:
                                'linear-gradient(180deg, #CCB98A 0.01%, #CCB98A 26%, #A7AE7B 28%, #A7AE7B 41%, #A7AE7B 54.49%, #608F7B 54.5%, #45787A 78.99%, #2D4D62 79%)',
                            }}
                          />
                          <span>Sequential</span>
                        </div>
                      </td>
                      <td>
                        Use for for highlighting trends or variations in data without implying any
                        specific categories or grouping.
                      </td>
                    </tr>
                  </tbody>
                </table>

                <h3 className="h3" id="anatomy">
                  Anatomy
                </h3>
                <div className="page-content-item">
                  <p>
                    The neutral gray family is dominant in the default themes, making use of subtle
                    shifts in value to organize content into distinct zones.
                  </p>
                  <p className="mb-40">
                    The core blue family serves as the primary action color across all products and
                    experiences. Additional colors are used sparingly and purposefully.
                  </p>
                </div>
                <figure>
                  <img
                    src="/images/branding-styleguide-colors/example-color-roles@3x.jpg"
                    alt="Example color roles"
                  />
                  <figcaption>Use sentence—case capitalization</figcaption>
                </figure>
                <h3 className="h3" id="tokens">
                  Tokens
                </h3>
                <div className="page-content-item mb-40">
                  <p>
                    For enabled UI colors, primarily use the color range of Gray 100 through Gray
                    70, and for text and icons uses the color range between White and Gray 50.
                  </p>
                  <p>
                    Gray 90 theme: uses Gray 90 as the global background color and is layered first
                    with components using Gray 80 backgrounds. The second layer uses Gray 70 and the
                    third layer used Gray 60.
                  </p>
                </div>
                <figure>
                  <img
                    src="/images/branding-styleguide-colors/applying-color-with-design-tokens@3x.jpg"
                    alt="Example color roles"
                  />
                  <figcaption>Applying color with design tokens</figcaption>
                </figure>
                <h1 className="divider h1" id="guidelines">
                  Guidelines
                </h1>
                <div className="page-content-item">
                  <h3 className="h3" id="usage">
                    Usage
                  </h3>
                  <p className="mb-40">
                    There are two light themes in Carbon: White and Gray 10. For enabled UI colors
                    light themes primarily use the color range of White to Gray 20, and for text and
                    icons uses the color range between Gray 100 and Gray 60. All of the themes are
                    available in Design kits.
                  </p>
                  <h3 className="h3" id="best-practices">
                    Best practices
                  </h3>
                  <p className="mb-40">
                    There are two light themes in Carbon: White and Gray 10. For enabled UI colors
                    light themes primarily use the color range of White to Gray 20, and for text and
                    icons uses the color range between Gray 100 and Gray 60. All of the themes are
                    available in Design kits.
                  </p>
                </div>
                <div className="ex-cards">
                  <div className="ex-card true">
                    <img
                      className="ex-card-img"
                      src="/images/branding-styleguide-colors/screen-white-dropdown-on-gray-background@3x.jpg"
                      alt="White dropdown on gray background"
                    />
                    <div className="ex-card-desc">
                      <BadgeStyled className="success" iconLeft={<IconSuccess />}>
                        Do
                      </BadgeStyled>
                      <p>White dropdown on gray background.</p>
                    </div>
                  </div>
                  <div className="ex-card false">
                    <img
                      src="/images/branding-styleguide-colors/screen-avoid-use-of-midtones@3x.jpg"
                      alt="Avoid use of midtones"
                    />
                    <div className="ex-card-desc">
                      <BadgeStyled className="danger" iconLeft={<IconDanger />}>
                        Don&apos;t
                      </BadgeStyled>
                      <p>Avoid use of midtones.</p>
                    </div>
                  </div>
                </div>
                <div className="page-content-item">
                  <h3 className="h3" id="states">
                    States
                  </h3>
                  <p className="mb-40">
                    In addition to the core set of enabled-state tokens, there are five other
                    interaction states defined with tokens for each theme. Interaction tokens are
                    signified by the addition of a state name added to the end of the base token
                    name. For example, the $background hover state token is $background-hover.
                  </p>
                </div>
                <img
                  src="/images/branding-styleguide-colors/examples-contrast@3x.jpg"
                  alt="Example contrast"
                  className="mb-40 img-responsive"
                />
                <div className="page-content-item mb-40">
                  <p>
                    <strong>Must pass 3:1 contrast:</strong> Any UI essential to understanding the
                    experience and text 24px
                    <br />
                    <strong>Must pass 4.5:1:</strong> Text smaller than 24px
                  </p>
                </div>
                <h1 className="h1 divider" id="references">
                  References
                </h1>
                {dataExternalLinkGroups.map((externalLinkGroup) => (
                  <ExternalLinkGroupStyled
                    key={externalLinkGroup.id}
                    title={externalLinkGroup.title}
                    cards={externalLinkGroup.cards}
                  />
                ))}
              </div>
              <div>
                <div className="page-navigation">
                  <Scrollspy
                    ids={[
                      'overview',
                      'definition',
                      'specifications',
                      'purpose',
                      'anatomy',
                      'tokens',
                      'guidelines',
                      'usage',
                      'best-practices',
                      'states',
                      'references',
                    ]}
                    offset={0}
                    activeItemClassName="active"
                    itemContainerClassName="multilevel-link-list"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <PaginationStyled
          previousLink="/branding-styleguide"
          previous="Branding Styleguide"
          nextLink="/branding-styleguide/typography"
          next="Typography"
        />
      </div>
    </div>
  </div>
);

ColorsStructure.defaultProps = {
  className: '',
};

const Colors = styled(ColorsStructure)`
  ${commonPageContentStyles}
  ${navigationStyles([2, 4, 5, 6, 8, 9, 10])}
`;

export default Colors;
