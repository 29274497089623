import { useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as IconChevronDown } from '../../images/icons/chevron-down.svg';
import { ReactComponent as IconChevronUp } from '../../images/icons/chevron-up.svg';
import { ReactComponent as IconPerson } from '../../images/icons/person.svg';
import { ReactComponent as IconSettings } from '../../images/icons/settings.svg';
import { ReactComponent as IconSignOut } from '../../images/icons/sign-out.svg';

interface Props {
  className?: string;
}

// TODO: ADd the component in the Header component. Test if the new changes are working.
// The component is created using the code from top navigation bar (It is not improved)
// TODO: Improve the component in a separate task.
const UserProfile = ({ className }: Props) => {
  const [isActive, setIsActive] = useState(false);

  const [openUpwards, setOpenUpwards] = useState(false);
  const profileMenuRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const toggleActive = () => {
    setIsActive((prevIsActive) => !prevIsActive);
  };

  useLayoutEffect(() => {
    if (profileMenuRef.current && buttonRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const menuHeight = profileMenuRef.current.offsetHeight;
      const spaceBelow = window.innerHeight - buttonRect.bottom;
      const spaceAbove = buttonRect.top;
      if (spaceBelow < menuHeight && spaceAbove > menuHeight) {
        setOpenUpwards(true);
      } else {
        setOpenUpwards(false);
      }
    }
  }, [isActive]);

  return (
    <div className={className}>
      <button
        className={`btn-auth ${isActive ? 'active' : ''}`}
        type="button"
        aria-label="icon"
        onClick={toggleActive}
        ref={buttonRef}
      >
        <div className="avatar">RR</div>
        <div className="btn-auth-info">
          <div className="user">
            <span>Robert Robertson</span>
            {isActive ? <IconChevronUp /> : <IconChevronDown />}
          </div>
          <div className="role">Admin</div>
        </div>
      </button>
      <div ref={profileMenuRef} className={`profile-menu ${openUpwards ? 'open-upwards' : ''}`}>
        <div className="profile-menu-item">
          <strong className="profile-title">Account</strong>
          <button className="btn-profile" type="button" aria-label="icon">
            <div className="avatar-profile">RR</div>
            <div className="btn-profile-info">
              <div className="user">
                <span>Robert Robertson</span>
              </div>
              <div className="role">
                <a href="mailto:robert.r@gmail.com" title="">
                  robert.r@gmail.com
                </a>
              </div>
            </div>
          </button>
        </div>
        <div className="profile-menu-item">
          <strong className="profile-title">Theme</strong>
          <div className="profile-btn-list">
            <button className="profile-btn active" type="button">
              Light
            </button>
            <button className="profile-btn" type="button">
              Dark
            </button>
            <button className="profile-btn" type="button">
              System
            </button>
          </div>
        </div>
        <div className="profile-menu-item">
          <ul className="profile-list">
            <li>
              <IconPerson />
              <a href="/" target="_blank" title="">
                Profile
              </a>
            </li>
            <li>
              <IconSettings />
              <a href="/" target="_blank" title="">
                Settings
              </a>
            </li>
          </ul>
        </div>
        <div className="profile-menu-item">
          <ul className="profile-list">
            <li>
              <IconSignOut />
              <a href="/" target="_blank" title="">
                Sign out
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

UserProfile.defaultProps = {
  className: '',
};

const StyledUserProfile = styled(UserProfile)`
  position: relative;

  .btn-auth {
    display: flex;
    gap: 0.5rem;
    padding: 0.75rem 1rem 0.75rem 0;
    background: transparent none;
    border: 0 none;
    cursor: pointer;
    align-items: center;

    .avatar {
      background: var(--2d6e8d, #2d6e8d) url('/images/avatar-bg.jpg') no-repeat center/cover;
      color: var(--5f6877, #5f6877);
      border-radius: 0.375rem;
      width: 2.5rem;
      height: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--000005, #000005);
    }

    .role {
      font-size: 0.75rem;
      line-height: 1.1666666667;
      color: var(--5f6877, #5f6877);
    }

    .user {
      color: var(--000005, #000005);
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.1428571429;
    }

    &-info {
      display: flex;
      flex-direction: column;
      gap: 0.125rem;
      align-items: flex-start;
      color: var(--5f6877, #5f6877);
      padding: 0.25rem 0;
    }

    &:not(.active) {
      + .profile-menu {
        display: none;
      }
    }

    &.active {
      + .profile-menu {
        display: block;
      }
    }
  }

  .auth-profile {
    position: relative;
  }

  .profile-menu {
    position: absolute;
    top: 96%;
    right: 1.125rem;
    background: var(--fff, #fff);
    border: 0.0625rem solid var(--dfe1e4, #dfe1e4);
    box-shadow: 0 0.25rem 0.5rem 0.125rem var(--141c2c1f, #141c2c1f);
    border-radius: 0.375rem;
    padding: 0.75rem;
    z-index: 1;
    min-width: 14.6875rem;

    &.open-upwards {
      top: auto;
      bottom: 96%;
    }
  }

  .profile-menu-item {
    border-bottom: 0.0625rem solid var(--dfe1e4, #dfe1e4);
    padding-bottom: 0.34375rem;
    margin-bottom: 0.34375rem;

    &:last-child {
      border-bottom: 0 none;
      padding-bottom: 0;
      margin-bottom: 0;
    }

    .profile-title {
      display: block;
      font-size: 0.625rem;
      line-height: 2;
      font-weight: 400;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: var(--5f6877, #5f6877);
    }

    .btn-profile {
      display: flex;
      gap: 0.5rem;
      padding: 0.375rem 0;
      background: transparent none;
      border: 0 none;
      cursor: pointer;
      align-items: center;

      .avatar-profile {
        background: var(--2d6e8d, #2d6e8d) url('/images/avatar-bg.jpg') no-repeat center/cover;
        color: var(--5f6877, #5f6877);
        border-radius: 0.375rem;
        width: 2rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--000005, #000005);
      }

      .role {
        font-size: 0.75rem;
        line-height: 1.1666666667;
        color: var(--5f6877, #5f6877);

        & a {
          color: var(--5f6877, #5f6877);
          text-decoration: none;
        }
      }

      .user {
        color: var(--000005, #000005);
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.1428571429;
      }

      &-info {
        display: flex;
        flex-direction: column;
        gap: 0.125rem;
        align-items: flex-start;
        color: var(--5f6877, #5f6877);
      }
    }

    .profile-btn-list {
      display: inline-flex;
      align-items: center;
      background-color: var(--5f687714, #5f687714);
      border-radius: 0.375rem;
      padding: 0.125rem;
      margin: 0.25rem 0;

      .profile-btn {
        color: var(--5f6877, #5f6877);
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.142857142857143;
        padding: 0.375rem 1.09375rem;
        transition:
          color 0.3s ease-in-out,
          background-color 0.3s ease-in-out;
        border: 0 none;

        &.active {
          font-weight: 500;
          background: var(--fff, #fff);
          color: var(--000005, #000005);
          box-shadow: 0rem 0.125rem 0.25rem 0.0625rem var(--141c2c0a, #141c2c0a);
          border-radius: 0.25rem;
        }
      }
    }

    .profile-list {
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        font-size: 0.875rem;
        line-height: 1.142857142857143;
        padding: 0.5rem 0;
        display: flex;
        align-items: center;
        gap: 0.5rem;

        a {
          color: var(--000005, #000005);
          text-decoration: none;
        }
      }
    }
  }
`;

export default StyledUserProfile;
