import { DefaultTheme } from 'styled-components';

// Use this config to define the theme for the application
// Avoid hardcoding values in the components, use the theme instead
const theme: DefaultTheme = {
  colors: {
    white: '#FFFFFF',
    primary: {
      action: '#00143F',
      border: '#2D6E8D',
      'background-strong': '#2685A1',
      text: '#155676',
      'text-hover': '#003E5F',
      background: '#2685A11F',
      'background-medium': '#2D6E8D33',
    },
    neutral: {
      'text-weak': '#5C636E',
      'background-medium': '#EFF0F1',
      border: '#DADCE0',
      background: '#5F687714',
      'background-hover': '#5F68771F',
      text: '#000005',
    },
    grey: {
      100: '#F1F4F7',
      200: '#E9EAEC',
      300: '#DADCE0',
    },
  },
  fontSizes: {
    xs: '12px',
    sm: '14px',
    base: '16px',
    lg: '24px',
    xl: '32px',
    '1xl': '48px',
    '2xl': '56px',
  },
  lineHeights: {
    xs: '14px',
    sm: '16px',
    base: '20px',
    lg: '32px',
    xl: '40px',
    '1xl': '52px',
    '2xl': '67px',
  },
  breakpoints: {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    '2xl': '1536px',
    '3xl': '1920px',
  },
  containers: {
    max: '1920px',
  },
};

export default theme;
