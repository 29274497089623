import { useRef, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { MENU_ROUTES } from '../../constants';
import useDisableBodyScroll from '../../hooks/useDisableBodyScroll';
import { ReactComponent as Logo } from '../../images/dhcs.svg';
import { ReactComponent as ArrowIcon } from '../../images/icons/arrow-right.svg';
import { ReactComponent as IconOverview } from '../../images/icons/builder.svg';
import { ReactComponent as IconDesignSystem } from '../../images/icons/design-system.svg';
import { ReactComponent as Grid } from '../../images/icons/grid.svg';
import { ReactComponent as IconBuilder } from '../../images/icons/overview.svg';
import { ReactComponent as IconSearch } from '../../images/icons/search.svg';
import { ReactComponent as XIcon } from '../../images/icons/x.svg';
import Button from '../Button';
import UserProfile from '../UserProfile';

interface Props {
  className?: string;
}

const HeaderNavigationMobile = ({ className }: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // Custom hook to disable body scroll when the menu is open
  useDisableBodyScroll(isMenuOpen);

  const searchBlockRef = useRef<HTMLInputElement>(null);

  return (
    <div className={className}>
      {isMenuOpen && <div className="menu-overlay" />}
      <div className="menu-bar">
        <Link to={MENU_ROUTES.overview.path}>
          <Logo />
        </Link>
        <div className="menu-bar__actions">
          <button
            aria-label="search-icon"
            className="search-btn"
            onClick={() => {
              if (searchBlockRef.current) {
                searchBlockRef.current.focus();
              }
              setIsMenuOpen(true);
            }}
            type="button"
          >
            <IconSearch />
          </button>
          <Button
            onClick={() => (window.location.href = 'https://dhcs.fuselabcreative.com/sign-in')}
            color="primary"
            size="S"
            type="button"
            data-link="#"
          >
            <span className="button-name">Builder</span>
          </Button>

          <button
            type="button"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="toggle-menu-btn"
            aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
          >
            {isMenuOpen ? <XIcon /> : <Grid />}
          </button>
        </div>
      </div>

      <div className={`menu-drawer ${isMenuOpen ? 'open' : ''}`}>
        <div className="search-block">
          <IconSearch />
          <input ref={searchBlockRef} type="text" name="search" placeholder="Search ..." />
        </div>

        <div className="menu-items">
          <ul>
            <li>
              <NavLink
                to={MENU_ROUTES.overview.path}
                className={({ isActive }) =>
                  isActive ? 'menu-link menu-link--active' : 'menu-link'
                }
              >
                <IconBuilder />
                <span>{MENU_ROUTES.overview.name}</span>
                <ArrowIcon />
              </NavLink>
            </li>
            <li>
              <NavLink
                to={MENU_ROUTES.builder.path}
                className={({ isActive }) =>
                  isActive ? 'menu-link menu-link--active' : 'menu-link'
                }
              >
                <IconOverview />
                <span>{MENU_ROUTES.builder.name}</span>
                <ArrowIcon />
              </NavLink>
            </li>
            <li>
              <NavLink
                to={MENU_ROUTES.designSystem.path}
                className={({ isActive }) =>
                  isActive ? 'menu-link menu-link--active' : 'menu-link'
                }
              >
                <IconDesignSystem />
                <span>{MENU_ROUTES.designSystem.name}</span>
                <ArrowIcon />
              </NavLink>
            </li>
          </ul>
        </div>

        {isMenuOpen && <UserProfile className="user-profile" />}
      </div>
    </div>
  );
};

HeaderNavigationMobile.defaultProps = {
  className: '',
};

const HeaderNavigationMobileStyled = styled(HeaderNavigationMobile)`
    display: block;
    padding: 8px 16px;
    background-color: ${({ theme }) => theme.colors.neutral['background-medium']};

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        display: none;
    }
    
    > .menu-overlay {
        background-color: #000000;
        opacity: 60%;
        position: absolute;
        width: 100%;
        height: calc(100vh - 48px);
        left: 0;
        top: 48px;
        z-index: 1;
    }

  > .menu-bar {
      display: flex;
      align-items: center;
      justify-content: space-between;

      > .menu-bar__actions {
          display: flex;
          gap: 14px;
          position: relative;

         > .search-btn {
              border: unset;
              display: flex;
              align-items: center;
             color: ${({ theme }) => theme.colors.neutral['text-weak']};
          }

         > .toggle-menu-btn {
              border: unset;
              display: flex;
              align-items: center;
              position: relative;
              margin-left: 14px;
              cursor: pointer;

              &::before {
                  position: absolute;
                  content: '';
                  display: block;
                  width: 1px;
                  height: 48px;
                  background-color: ${({ theme }) => theme.colors.neutral.border};
                  left: -14px;
              }
          }
      }
  }

   > .menu-drawer { 
      z-index: 11;
      padding: 20px 12px;
      background-color: ${({ theme }) => theme.colors.neutral['background-medium']};
      border-top: 1px solid ${({ theme }) => theme.colors.neutral.border};
      position: fixed;
      top: 48px;
      right: 0;
      height: calc(100vh - 48px);
      width: 272px;
      transition: transform 0.3s ease-in-out;
      transform: translateX(100%);

      &.open {
        transform: translateX(0%);
      }

       > .search-block {
         position: relative;
         display: flex;

         > svg {
              position: absolute;
              left: 12px;
              top: 50%;
              transform: translateY(-50%);
          }
            
           > input {
              display: block;
              width: 100%;
              font-size: ${({ theme }) => theme.fontSizes.sm};
              line-height: ${({ theme }) => theme.lineHeights.sm};
              padding: 8px 12px 8px 35px;
              color: ${({ theme }) => theme.colors.neutral['text-weak']};
              align-items: center;
              gap: 8px;
              height: 40px;
              background-color: ${({ theme }) => theme.colors.neutral.background};
              border: 1px solid ${({ theme }) => theme.colors.neutral.border};
              border-radius: 0.375rem;

        &:active {
          background: ${({ theme }) => theme.colors.white};
          border: 1px solid ${({ theme }) => theme.colors.neutral.border};
        }

        &:focus {
          background: ${({ theme }) => theme.colors.white};
          border-color: ${({ theme }) => theme.colors.primary.border};
          outline: none;
        }
              
         &:hover:not(:focus) {
           background-color: ${({ theme }) => theme.colors.neutral['background-hover']};
          }
        }
      }
       
        > .menu-items {
           > ul {
                margin-top: 24px;
                list-style: none;
                
              >  li {
                  > .menu-link {
                        text-decoration: none;
                        color: ${({ theme }) => theme.colors.neutral['text-weak']};
                        width: 100%;
                        padding: 12px 16px;
                        font-size: ${({ theme }) => theme.fontSizes.sm};
                        line-height: ${({ theme }) => theme.lineHeights.sm};
                        display: flex;
                        align-items: center;
                      
                        &--active {
                            color: ${({ theme }) => theme.colors.primary['background-strong']};
                            background-color: ${({ theme }) => theme.colors.white};
                            border-radius: 6px;
                            
                            > svg:last-child {
                            display: none;
                            }
                        }
                        
                       > svg:first-child {
                           margin-right: 8px;
                        }
                       > svg:last-child {
                            margin-left: auto;
                        }
                    
                  }
                  
                }
            }
        }
        
      > .user-profile {
        position: absolute;
        bottom: 0
        }
    }
  }
`;

export default HeaderNavigationMobileStyled;
