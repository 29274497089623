import styled from 'styled-components';

import CardGroupStyled from '../../components/CardGroup';
import Header from '../../components/Header';
import HeroStyled from '../../components/Hero';
import PaginationStyled from '../../components/Pagination';
import SidebarStyled from '../../components/Sidebar';
import commonPageContentStyles from '../../styles/mixins/_commonPageContentStyles';

const dataCardGroups = [
  {
    id: 1,
    title: '1',
    content: '1',
    linkUrl: '1',
    cards: [
      {
        title: 'Colors',
        content:
          'It outlines the primary and secondary color palettes along with their corresponding hex codes or Pantone values, ensuring consistency across all brand materials.',
        linkUrl: '#',
        image: '/images/branding-styleguide/branding-styleguide-colors@3x.jpg',
      },
      {
        title: 'Typography',
        content:
          'This section provides details about the primary and secondary fonts, including font families, weights, and sizes for various use cases such as headings, body text, and captions.',
        linkUrl: '#',
        image: '/images/branding-styleguide/branding-styleguide-typography@3x.jpg',
      },
      {
        title: 'Logos',
        content: `Here, you'll find guidelines on logo usage, including acceptable variations such as full-color, monochrome, and reverse versions.`,
        linkUrl: '#',
        image: '/images/branding-styleguide/branding-styleguide-logos@3x.jpg',
      },
    ],
  },
  {
    id: 2,
    title: '1',
    content: '1',
    linkUrl: '1',
    cards: [
      {
        title: 'Icons',
        content:
          'This section outlines the design principles for icons, including style, grid system, and preferred sizes. It may also specify guidelines for icon usage in different contexts.',
        linkUrl: '#',
        image: '/images/branding-styleguide/branding-styleguide-icons@3x.jpg',
      },
      {
        title: 'Imagery',
        content: `Provides instructions on the types of images that align with the brand's visual identity, such as photography style, subject matter, and color treatment`,
        linkUrl: '#',
        image: '/images/branding-styleguide/branding-styleguide-imagery@3x.jpg',
      },
      {
        title: 'Interactions and Animations',
        content: `Describes the principles for interactive elements and animations, including transitions, timing, and easing effects.`,
        linkUrl: '#',
        image: '/images/branding-styleguide/branding-styleguide-interactions@3x.jpg',
      },
    ],
  },
];
interface Props {
  className?: string;
}

const BrandingStyleguideStructure = ({ className }: Props) => (
  <div className={className}>
    <Header className="header" />
    <SidebarStyled className="sidebar" />
    <div className="scrollable-content">
      <div className="content">
        <HeroStyled
          title="Branding Styleguide"
          subtitle={
            <>
              <p>
                Outlines the visual and stylistic elements of a brand to ensure consistency across
                all communication channels. It typically includes guidelines for logo usage, color
                palette, typography, imagery, and overall design principles.
              </p>
              <p>
                The purpose of a branding style guide is to maintain brand consistency, ensure brand
                recognition, and foster a cohesive brand identity across all touchpoints and
                mediums.
              </p>
            </>
          }
          category
        />
        <div>
          {dataCardGroups.map((cardGroup) => (
            <CardGroupStyled
              key={cardGroup.id}
              title={cardGroup.title}
              content={cardGroup.content}
              linkUrl={cardGroup.linkUrl}
              cards={cardGroup.cards}
              category
            />
          ))}
        </div>
        <PaginationStyled
          previousLink="/"
          previous="Get Started"
          previousDisabled="disabled"
          nextLink="/general-components"
          next="General Components"
        />
      </div>
    </div>
  </div>
);

BrandingStyleguideStructure.defaultProps = {
  className: '',
};

const BrandingStyleguide = styled(BrandingStyleguideStructure)`
  ${commonPageContentStyles}
`;

export default BrandingStyleguide;
