import styled from 'styled-components';

interface BadgeProps {
  children: React.ReactNode;
  className?: string;
  iconLeft?: JSX.Element;
}

const Badge = ({ children, className, iconLeft }: BadgeProps) => (
  <div className={className}>
    {iconLeft && <span className="icon-left">{iconLeft}</span>}
    <span className="name">{children}</span>
  </div>
);

Badge.defaultProps = {
  className: '',
  iconLeft: undefined,
};

const BadgeStyled = styled(Badge)`
  font-size: 0.75rem;
  line-height: 1.166666666666667;
  border: 0.0625rem solid transparent;
  border-radius: 0.125rem;
  display: inline-flex;
  align-items: center;

  &.info {
    color: var(--155676, #155676);
    border-color: var(--2d6e8d3d, #2d6e8d3d);
    background-color: var(--2d6e8d1f, #2d6e8d1f);
  }

  &.warning {
    color: var(--913d00, #913d00);
    border: 0.0625rem solid var(--b35f003d, #b35f003d);
    background-color: var(--B35F001f, #b35f001f);
  }

  &.success {
    color: var(--00623e, #00623e);
    border: 0.0625rem solid var(--1386603d, #1386603d);
    background-color: var(--1386601f, #1386601f);

    .icon-left {
      border-color: var(--13866033, #13866033);
    }
  }

  &.danger {
    color: var(--a81945, #a81945);
    border: 1px solid var(--c536601f, #c536601f);
    background-color: var(--c536601f, #c536601f);

    .icon-left {
      border-color: var(--c5366033, #c5366033);
    }
  }

  .name {
    padding: 0.125rem 0.1875rem;
  }

  .icon-left {
    display: inline-flex;
    border-right-width: 0.0625rem;
    border-right-style: solid;
    padding: 0.1875rem 0.25rem 0.1875rem;
    margin-right: 0.0625rem;
  }
`;

export default BadgeStyled;
