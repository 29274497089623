import { useState } from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';

import Alert from '../../components/Alert';
import CardPanel from '../../components/CardPanel';
import Link from '../../components/Link';
import Tabs from '../../components/Tabs';
import bubbles from '../../images/bubles.png';
import dotsChart from '../../images/dots-chart.png';
import horizontalChart from '../../images/horizontal-chart.png';
import { ReactComponent as ContentAreaIcon } from '../../images/icons/content-area.svg';
import { ReactComponent as ExportIcon } from '../../images/icons/export.svg';
import { ReactComponent as InfoIcon } from '../../images/icons/info-circle.svg';
import { ReactComponent as LightIcon } from '../../images/icons/light.svg';
import { ReactComponent as SlidersIcon } from '../../images/icons/sliders.svg';
import customizationBgMobile from '../../images/mobile/customization-bg.jpg';
import dataBgMobile from '../../images/mobile/data-bg.jpg';
import exportShareBgMobile from '../../images/mobile/export-share-bg.jpg';
import featuresBgMobile from '../../images/mobile/features-bg.jpg';
import tutorialsBgMobile from '../../images/mobile/tutorial-bg.jpg';
import verticalLinesChart from '../../images/vertical-lines-chart.png';

import CustomizationTab from './components/CustomizationTab';
import DataTab from './components/DataTab';
import ExportShareTab from './components/Export&ShareTab';
import FeaturesTab from './components/FeaturesTab';
import TutorialTab from './components/TutorialTab';

interface Props {
  className?: string;
}

const setActiveMobileTopBg = (activeTab: string) => {
  switch (activeTab) {
    case 'features':
      return featuresBgMobile;
    case 'customization':
      return customizationBgMobile;
    case 'data':
      return dataBgMobile;
    case 'export':
      return exportShareBgMobile;
    default:
      return tutorialsBgMobile;
  }
};

const Builder = ({ className }: Props) => {
  const [activeTab, setActiveTab] = useState('features');

  const TABS = [
    {
      id: 'features',
      title: (
        <>
          <LightIcon /> Features
        </>
      ),
      content: <FeaturesTab onRedirect={() => setActiveTab('tutorial')} />,
    },
    {
      id: 'customization',
      title: (
        <>
          <SlidersIcon /> Customization
        </>
      ),
      content: <CustomizationTab />,
    },
    {
      id: 'data',
      title: (
        <>
          <ContentAreaIcon /> Data
        </>
      ),
      content: <DataTab />,
    },
    {
      id: 'export',
      title: (
        <>
          <ExportIcon /> Export & Share
        </>
      ),
      content: <ExportShareTab />,
    },
    {
      id: 'tutorial',
      title: (
        <>
          <InfoIcon /> Tutorial
        </>
      ),
      content: <TutorialTab />,
    },
  ];

  return (
    <div className={className}>
      <section className="hero">
        <Alert
          className="alert"
          iconName="gear"
          mainText="Builder Alert:"
          numberOfAlerts={3}
          additionalText="chart types will be added to the Design System in July 2024."
          actions={<Link href="/">Learn More</Link>}
        />
        <div
          style={{
            backgroundImage: `url(${setActiveMobileTopBg(activeTab)})`,
            height: activeTab !== 'tutorial' ? '350px' : 'auto',
            padding: activeTab !== 'tutorial' ? 'unset' : '40px 25px',
          }}
          className="mobile-bg"
        >
          {activeTab === 'tutorial' && (
            // It appears only for mobile
            <ReactPlayer
              className="react-player"
              width="100%"
              height="100%"
              controls
              disablepictureinpicture
              url="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
              config={{
                file: {
                  attributes: {
                    controlsList: ['nodownload noplaybackrate'],
                    disablepictureinpicture: '',
                  },
                },
              }}
            />
          )}
        </div>

        <Tabs
          defaultActiveTab={activeTab}
          onChange={(tab) => setActiveTab(tab)}
          tabs={TABS}
          className="tabs-container"
        />
      </section>

      <section className="cards">
        <div className="cards-header">
          <h4>Project Gallery</h4>
          <Link className="see-examples-desktop" href="/">
            See All Examples
          </Link>
        </div>
        <div className="cards-container">
          <CardPanel imgPath={bubbles} />
          <CardPanel imgPath={dotsChart} />
          <CardPanel imgPath={verticalLinesChart} />
          <CardPanel imgPath={horizontalChart} />
        </div>
        <Link className="see-examples-mobile" href="/">
          See All Examples
        </Link>
      </section>
    </div>
  );
};

Builder.defaultProps = {
  className: '',
};

const StyledBuilder = styled(Builder)`
  margin: 0 auto;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .hero {
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
    > .alert {
      margin-top: 16px;
    }

    > .mobile-bg {
      width: 100%;
      display: block;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;

      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        display: none;
      }
      > .react-player {
        > video {
          border: 8px solid ${({ theme }) => theme.colors.white};
          border-radius: 12px;
          box-sizing: border-box;
        }
      }
    }

    > .tabs-container {
      padding: 0 16px 72px 16px;

      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        padding-left: 48px;
        padding-right: 0;
        padding-bottom: 0;
        margin-top: 20px;
        flex: 1;
        display: flex;
        flex-direction: column;
      }
    }
  }

  > .cards {
    padding: 24px 16px 40px;
    background: linear-gradient(to top, white, rgba(255, 255, 255, 0)), #f0f1f2;
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      padding: 16px 48px;
      border-top: 1px solid ${({ theme }) => theme.colors.grey['300']};
    }

    > .cards-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > .see-examples-desktop {
        display: none;
        @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
          display: flex;
          align-items: center;
        }
      }
      > h4 {
        font-size: ${({ theme }) => theme.fontSizes.xl};
        font-weight: 600;
        line-height: ${({ theme }) => theme.lineHeights.xl};
        background: linear-gradient(to left, #155676, #2685a1);
        color: transparent;
        background-clip: text;
        margin-bottom: 8px;
      }
    }
    > .cards-container {
      display: flex;
      gap: 16px;
      flex-wrap: wrap;
    }

    > .see-examples-mobile {
      margin-top: 28px;
      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        display: none;
      }
    }
  }
`;

export default StyledBuilder;
