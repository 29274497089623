import styled from 'styled-components';

import Article from '../../../components/Article';
import Button from '../../../components/Button';
import featuresBg from '../../../images/features-bg.png';
import { ReactComponent as ArrowTopRightIcon } from '../../../images/icons/arrow-top-right.svg';
import { ReactComponent as EyeDropperIcon } from '../../../images/icons/eyedropper.svg';
import { ReactComponent as LineChartIcon } from '../../../images/icons/icon-line-chart.svg';
import { ReactComponent as PlusIcon } from '../../../images/icons/plus.svg';
import { ReactComponent as ShareIcon } from '../../../images/icons/share.svg';
import { ReactComponent as UserIcon } from '../../../images/icons/user.svg';

interface Props {
  className?: string;
  onRedirect: () => void;
}

const FeaturesTab = ({ className, onRedirect }: Props) => (
  <section className={className}>
    <div className="tabs-content-left">
      <div className="tab-content-header">
        <h3>Introducing the DHCS Visualization Builder</h3>
        <div className="tabs-content-left__actions">
          <button onClick={onRedirect} type="button" className="launch-tutorial-btn">
            Launch tutorial
            <ArrowTopRightIcon />
          </button>
          <Button
            onClick={() => (window.location.href = 'https://dhcs.fuselabcreative.com/sign-in')}
            color="secondary"
            size="M"
            type="button"
          >
            <span className="button-name">Get Started</span>
            <span className="button-icon">
              <PlusIcon />
            </span>
          </Button>
        </div>
      </div>

      <div className="articles-container">
        <div>
          <Article
            icon={<LineChartIcon />}
            content="Experience the simplicity of our intuitive interface, allowing you to effortlessly craft visually stunning charts and graphs."
          />
          <Article
            icon={<UserIcon />}
            content="Connect to your preferred data sources, ranging from spreadsheets to APIs, ensuring seamless integration."
          />
        </div>
        <div>
          <Article
            icon={<EyeDropperIcon />}
            content="Customize your visualizations, selecting from a variety of options such as color schemes and chart types to tailor them to your specific needs"
          />
          <Article
            icon={<ShareIcon />}
            content="Collaborate within your team by sharing your visualizations and exporting them in multiple formats, fostering informed decision-making and knowledge exchange."
          />
        </div>
      </div>
    </div>

    <div className="tabs-content-right" />
  </section>
);

FeaturesTab.defaultProps = {
  className: '',
};

const StyledFeaturesTab = styled(FeaturesTab)`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  flex-direction: column-reverse;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-wrap: nowrap;
    flex-direction: row;
    min-height: 100%;
  }

  > .tabs-content-left {
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      flex-basis: 50%;
    }

    > .tab-content-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 16px;
      gap: 16px;

      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        flex-wrap: nowrap;
      }

      @media (min-width: ${({ theme }) => theme.breakpoints['2xl']}) {
        margin-bottom: 40px;
      }
      > h3 {
        font-size: ${({ theme }) => theme.fontSizes.base};
        font-weight: 600;
        line-height: ${({ theme }) => theme.lineHeights.base};
        color: ${({ theme }) => theme.colors.neutral.text};
      }

      > .tabs-content-left__actions {
        display: flex;
        gap: 24px;

        > .launch-tutorial-btn {
          white-space: nowrap;
          display: inline-flex;
          align-items: center;
          gap: 6px;
          color: ${({ theme }) => theme.colors.primary.text};
          font-size: ${({ theme }) => theme.fontSizes.sm};
          line-height: ${({ theme }) => theme.lineHeights.base};
          font-weight: 500;
          padding: 0;
          background: transparent;
          border: none;
          cursor: pointer;

          &:hover,
          &:active {
            color: ${({ theme }) => theme.colors.primary['text-hover']};
          }
        }
      }
    }

    > .articles-container {
      display: flex;
      flex-direction: column;

      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        flex-direction: row;
        > div {
          flex-basis: 50%;
        }
      }
    }
  }

  > .tabs-content-right {
    display: none;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      display: block;
      flex-basis: 50%;
      background-image: url(${featuresBg});
      background-repeat: no-repeat;
      background-size: contain;
      background-position: bottom right;
    }
  }
`;

export default StyledFeaturesTab;
