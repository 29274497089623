import React, { ReactElement } from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
  title?: React.ReactNode;
  icon?: ReactElement;
  color?: string;
}

const Notification = ({ className, title, icon, color }: Props) => (
  <div className={`${className} ${color}`}>
    {icon}
    {title}
  </div>
);

Notification.defaultProps = {
  className: '',
  title: '',
  icon: undefined,
  color: '',
};

const NotificationStyled = styled(Notification)`
  display: inline-flex;
  gap: 1rem;
  font-family: Arial;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.333333333333333;
  border-radius: 0.125rem;
  padding: 0.75rem;
  margin: 0;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 0.0625rem;
    top: 50%;
    height: 1rem;
    margin-left: 1.5rem;
    transform: translateY(-50%);
  }

  &.blue {
    background: var(--2d6e8d1f, #2d6e8d1f);
    border-left: 0.125rem solid var(--2d6e8d, #2d6e8d);

    svg {
      color: var(--2d6e8d, #2d6e8d);
    }

    &:after {
      background-color: var(--2d6e8d33, #2d6e8d33);
    }
  }
`;

export default NotificationStyled;
