import styled from 'styled-components';

import { ReactComponent as ArrowRight } from '../../images/icons/arrow-right.svg';
import { ReactComponent as Newsletter } from '../../images/icons/newsletter.svg';
import Button from '../Button';

interface Props {
  className?: string;
  newsImage?: string;
  newsTitle?: string;
  newsDate?: string;
  newsContent?: string;
}

const LatestNews = ({ className, newsImage, newsTitle, newsDate, newsContent }: Props) => (
  <div className={className}>
    <div className="news-header">
      <h1>Latest Updates</h1>
      <Button color="secondary" size="M" type="button" data-link="#">
        <span className="button-name">Sign Up for Our Newsletter</span>
        <span className="button-icon">
          <Newsletter />
        </span>
      </Button>
    </div>
    <div className="news-body">
      <img src={newsImage} alt={newsTitle} />
      <div>
        <div className="news-content">
          <span className="news-date">{newsDate}</span>
          <h3 className="news-title">{newsTitle}</h3>
          <p>{newsContent}</p>
        </div>
        <Button color="default" size="S" type="button" data-link="#">
          <span className="button-name">Read more</span>
          <span className="button-icon">
            <ArrowRight />
          </span>
        </Button>
      </div>
    </div>
  </div>
);

LatestNews.defaultProps = {
  className: '',
  newsImage: '',
  newsTitle: '',
  newsDate: '',
  newsContent: '',
};

const LatestNewsStyled = styled(LatestNews)`
  margin-top: 2.5rem;
  margin-bottom: 4.5rem;

  .news-header {
    display: flex;
    gap: 2rem;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }

  h1 {
    color: var(--5c636e, #5c636e);
    font-size: 2rem;
    font-weight: 300;
    line-height: 1.25;
    margin-top: 0;
    margin-bottom: 0;
  }

  img {
    max-width: 100%;
    border-radius: 0.75rem;
  }

  p {
    color: var(--5c636e, #5c636e);
    margin-top: 0;
    margin-bottom: 1.5rem;
  }

  .news {
    &-body {
      display: grid;
      grid-template-columns: 1fr 3fr;
      gap: 1.5rem;
    }

    &-content {
      max-width: 39.5rem;
      margin-bottom: 1.5rem;
    }

    &-title {
      font-size: 1.25rem;
      font-weight: 400;
      line-height: 1.2;
      letter-spacing: -0.02em;
      margin-top: 0;
      margin-bottom: 0.75rem;
    }

    &-date {
      display: inline-block;
      color: var(--030404, #030404);
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 1;
      border: 0.0625rem solid var(--dadce0, #dadce0);
      border-radius: 0.9375rem;
      padding: 0.5rem 0.75rem;
      margin-bottom: 0.75rem;
    }
  }
`;

export default LatestNewsStyled;
