import { useEffect } from 'react';

const useDisableBodyScroll = (isOpen: boolean) => {
  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);
};

export default useDisableBodyScroll;
