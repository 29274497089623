import Scrollspy from 'react-scrollspy-ez';
import styled from 'styled-components';

import BadgeStyled from '../../../components/Badge';
import Button from '../../../components/Button';
import ContentHeaderStyled from '../../../components/ContentHeader';
import ExternalLinkGroupStyled from '../../../components/ExternalLinkGroup';
import Header from '../../../components/Header';
import PaginationStyled from '../../../components/Pagination';
import SidebarStyled from '../../../components/Sidebar';
import { ReactComponent as ArrowRight } from '../../../images/icons/arrow-right.svg';
import { ReactComponent as IconDanger } from '../../../images/icons/icon-danger.svg';
import { ReactComponent as IconSuccess } from '../../../images/icons/icon-success.svg';
import commonPageContentStyles from '../../../styles/mixins/_commonPageContentStyles';
import navigationStyles from '../../../styles/mixins/navigationMenuStyles';

interface Props {
  className?: string;
}

const dataExternalLinkGroups = [
  {
    id: 1,
    title: 'Government accessibility resources',
    cards: [
      {
        title: 'Learn about writing in plain language ',
        subtitle: '[plainlanguage.gov]',
        linkUrl: '#',
      },
      {
        title: 'The Section 508 accessibility program',
        subtitle: '[section508.gov]',
        linkUrl: '#',
      },
      {
        title: 'Accessibility for Teams guide',
        subtitle: '[accessibility.digital.gov]',
        linkUrl: '#',
      },
      {
        title: 'Find your Section 508 Coordinator',
        subtitle: '[section508.gov]',
        linkUrl: '#',
      },
      {
        title: 'Section 508 ICT Testing Baseline',
        subtitle: '[section58coordinators.github.com ]',
        linkUrl: '#',
      },
    ],
  },
];

const ImageryStructure = ({ className }: Props) => (
  <div className={className}>
    <Header className="header" />
    <SidebarStyled className="sidebar" />
    <div className="scrollable-content">
      <div className="content">
        <ContentHeaderStyled
          title="Imagery"
          breadcrumbs={[
            { label: 'Branding Styleguide', url: '/branding-styleguide' },
            { label: 'Imagery' },
          ]}
        />
        <div className="page-container">
          <div className="page-content">
            <div className="page-content-with-navigation-wrapper">
              <div className="page-content-with-navigation">
                <div className="page-content-item">
                  <h1 className="h1" id="overview">
                    Overview
                  </h1>
                  <h3 className="h3" id="definition">
                    Definition
                  </h3>
                  <p className="mb-40">
                    Imagery communicates and differentiates a product through visuals. Imagery can
                    both enhance the user experience and express a brand’s visual language. Images
                    help tell a story, clarify complex messages that are difficult to express with
                    words, and to show users how to perform an action.
                  </p>
                </div>
                <div className="news">
                  <div className="news-item">
                    <img
                      className="news-img img-responsive"
                      src="/images/branding-styleguide-imagery/news-image-01@3x.jpg"
                      alt=""
                    />
                    <div className="news-desc">
                      <span className="news-data">9 Feb, 2024</span>
                      <strong className="news-title">
                        Substance Use Disorder Integrated Care Conference
                      </strong>
                      <p>
                        Introducing our brand new design system, meticulously crafted to elevate
                        user experience to unprecedented heights. Drawing from the latest in design
                        innovation and user feedback. Dive in and explore the next frontier of
                        digital design.
                      </p>
                    </div>
                    <Button color="default" size="XS" type="button" data-link="#">
                      <span className="button-name">Read more</span>
                      <span className="button-icon">
                        <ArrowRight />
                      </span>
                    </Button>
                  </div>
                  <div className="news-item">
                    <img
                      className="news-img img-responsive"
                      src="/images/branding-styleguide-imagery/news-image-02@3x.jpg"
                      alt=""
                    />
                    <div className="news-desc">
                      <span className="news-data">14 Jan, 2024</span>
                      <strong className="news-title">
                        DHCS announced $907M in awards for Health Bridge Housing
                      </strong>
                      <p>
                        Since joining DHCS, Ms. Recchio has served as the Interim Deputy Director
                        and Chief Counsel since July 2023, as a Deputy Chief Counsel since December
                        2021, and as the Senior Assistant Chief Counsel for the former
                        Administrative Litigation Team from February 2018-December
                      </p>
                    </div>
                    <Button color="default" size="XS" type="button" data-link="#">
                      <span className="button-name">Read more</span>
                      <span className="button-icon">
                        <ArrowRight />
                      </span>
                    </Button>
                  </div>
                  <div className="news-item">
                    <img
                      className="news-img img-responsive"
                      src="/images/branding-styleguide-imagery/news-image-03@3x.jpg"
                      alt=""
                    />
                    <div className="news-desc">
                      <span className="news-data">7 Sep, 2023</span>
                      <strong className="news-title">
                        Paves way for behavioral Health transformation
                      </strong>
                      <p>
                        Attention all experts! DHCS is looking for workshop proposals for the
                        Substance Use Disorder Integrated Care Conference taking place on August
                        13-15, 2024.
                      </p>
                    </div>
                    <Button color="default" size="XS" type="button" data-link="#">
                      <span className="button-name">Read more</span>
                      <span className="button-icon">
                        <ArrowRight />
                      </span>
                    </Button>
                  </div>
                </div>
                <h1 className="divider h1" id="guidelines">
                  Guidelines
                </h1>
                <div className="page-content-item">
                  <h3 className="h3" id="focal-point">
                    Have a focal point
                  </h3>
                  <p className="mb-40">
                    Have an iconic focal point in your imagery, as it impacts how it should be
                    cropped for different sizes. A focal point can be anything embodied in anything
                    from a single entity to an overall composition. Clearly convey concepts in a
                    memorable way.
                  </p>
                </div>
                <div className="ex-cards">
                  <div className="ex-card true">
                    <img
                      className="ex-card-img"
                      src="/images/branding-styleguide-imagery/screen-cropping-image-01@3x.jpg"
                      alt=""
                    />
                    <div className="ex-card-desc">
                      <BadgeStyled className="success" iconLeft={<IconSuccess />}>
                        Do
                      </BadgeStyled>
                      <p>Establish a clear focal point when cropping an image.</p>
                    </div>
                  </div>
                  <div className="ex-card false">
                    <img
                      src="/images/branding-styleguide-imagery/screen-cropping-image-02@3x.jpg"
                      alt=""
                    />
                    <div className="ex-card-desc">
                      <BadgeStyled className="danger" iconLeft={<IconDanger />}>
                        Don&apos;t
                      </BadgeStyled>
                      <p>
                        Don&apos;t crop an image in a way that doesn&apos;t show a clear focal
                        point.
                      </p>
                    </div>
                  </div>
                </div>
                <h3 className="h3" id="size">
                  Size appropriately
                </h3>
                <div className="page-content-item">
                  <p>
                    To best display images of different sizes and types, appropriately size images
                    for different displays and platforms. Resolution is the most important factor in
                    how quickly imagery will load. To preserve network bandwidth, keep resolution
                    low where possible.
                  </p>
                  <p className="mb-40">
                    Test appropriate resolution sizes for specific ratios and devices, ensuring that
                    assets don&apos;t appear pixelated.
                  </p>
                </div>
                <div className="ex-cards">
                  <div className="ex-card true">
                    <img
                      className="ex-card-img"
                      src="/images/branding-styleguide-imagery/screen-compromising-quality-01@3x.jpg"
                      alt=""
                    />
                    <div className="ex-card-desc">
                      <BadgeStyled className="success" iconLeft={<IconSuccess />}>
                        Do
                      </BadgeStyled>
                      <p>
                        Export images at the lowest file size possible without compromising quality.
                      </p>
                    </div>
                  </div>
                  <div className="ex-card false">
                    <img
                      src="/images/branding-styleguide-imagery/screen-compromising-quality-02@3x.jpg"
                      alt=""
                    />
                    <div className="ex-card-desc">
                      <BadgeStyled className="danger" iconLeft={<IconDanger />}>
                        Don&apos;t
                      </BadgeStyled>
                      <p>
                        Avoid using small file sizes that may result in pixelation of the image.
                      </p>
                    </div>
                  </div>
                </div>
                <h3 className="h3" id="matching-imagery">
                  Matching Imagery
                </h3>
                <div className="page-content-item mb-40">
                  <p>
                    Use imagery that corresponds with the messaging. A bold exclamation or stop
                    symbol tells the user to pause and pay attention to this destructive message.
                  </p>
                </div>
                <div className="ex-cards">
                  <div className="ex-card true">
                    <img
                      className="ex-card-img"
                      src="/images/branding-styleguide-imagery/screen-matching-imagery-01@3x.jpg"
                      alt=""
                    />
                    <div className="ex-card-desc">
                      <BadgeStyled className="success" iconLeft={<IconSuccess />}>
                        Do
                      </BadgeStyled>
                      <p>
                        Use imagery that corresponds with the messaging. A bold exclamation or stop
                        symbol tells the user to pause and pay attention to this destructive
                        message.
                      </p>
                    </div>
                  </div>
                  <div className="ex-card false">
                    <img
                      src="/images/branding-styleguide-imagery/screen-matching-imagery-02@3x.jpg"
                      alt=""
                    />
                    <div className="ex-card-desc">
                      <BadgeStyled className="danger" iconLeft={<IconDanger />}>
                        Don&apos;t
                      </BadgeStyled>
                      <p>
                        Incorporate imagery that contrasts sharply with the intended message. Use
                        bold symbols like exclamation marks or stop signs to grab the user&apos;s
                        attention and signal the importance of the message.{' '}
                      </p>
                    </div>
                  </div>
                </div>
                <h3 className="h3" id="accessibility">
                  Accessible Images
                </h3>
                <div className="page-content-item mb-40">
                  <p>
                    When placing text over images, the text contrast ratio should be enough to meet
                    accessibility standards.
                  </p>
                </div>
                <div className="ex-cards">
                  <div className="ex-card true">
                    <img
                      className="ex-card-img"
                      src="/images/branding-styleguide-imagery/screen-accessible-images-01@3x.jpg"
                      alt=""
                    />
                    <div className="ex-card-desc">
                      <BadgeStyled className="success" iconLeft={<IconSuccess />}>
                        Do
                      </BadgeStyled>
                      <p>
                        Avoid using small file sizes that may result in pixelation of the image.
                      </p>
                    </div>
                  </div>
                  <div className="ex-card false">
                    <img
                      src="/images/branding-styleguide-imagery/screen-accessible-images-02@3x.jpg"
                      alt=""
                    />
                    <div className="ex-card-desc">
                      <BadgeStyled className="danger" iconLeft={<IconDanger />}>
                        Don&apos;t
                      </BadgeStyled>
                      <p>
                        Use text over images that do have an overlay or scrim layer to add contrast
                        between the text and image.
                      </p>
                    </div>
                  </div>
                </div>
                <h1 className="h1 divider" id="references">
                  References
                </h1>
                {dataExternalLinkGroups.map((externalLinkGroup) => (
                  <ExternalLinkGroupStyled
                    key={externalLinkGroup.id}
                    title={externalLinkGroup.title}
                    cards={externalLinkGroup.cards}
                  />
                ))}
              </div>
              <div>
                <div className="page-navigation">
                  <h4>Contents</h4>
                  <Scrollspy
                    ids={[
                      'overview',
                      'definition',
                      'guidelines',
                      'focal-point',
                      'size',
                      'accessibility',
                      'references',
                    ]}
                    offset={0}
                    activeItemClassName="active"
                    itemContainerClassName="multilevel-link-list"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <PaginationStyled
          previousLink="/branding-styleguide/icons"
          previous="Icons"
          nextLink="/branding-styleguide/interactions-and-animations"
          next="Interactions and Animations"
        />
      </div>
    </div>
  </div>
);

ImageryStructure.defaultProps = {
  className: '',
};

const Imagery = styled(ImageryStructure)`
  ${commonPageContentStyles}
  ${navigationStyles([2, 4, 5, 6])}
`;

export default Imagery;
