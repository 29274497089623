import styled from 'styled-components';

import ContentHeaderStyled from '../../../components/ContentHeader';
import HoverableCard from '../../../components/HoverableCard';
import PaginationStyled from '../../../components/Pagination';
import { ReactComponent as ChartIcon } from '../../../images/icons/chart-bar.svg';

interface Props {
  className?: string;
}

const BarsPage = ({ className }: Props) => (
  <div className={className}>
    <div className="content">
      <ContentHeaderStyled
        className="breadcrumbs"
        title="Bars"
        breadcrumbs={[
          { label: 'Data Vizualization', url: '/data-vizualization' },
          { label: 'Bars Chart' },
        ]}
      />

      <div className="cards">
        <HoverableCard
          description="Grouped bar chat is useful for visually comparing data across multiple subcategories
                        within each main category. It displays multiple bars for each category with each group
                        containing bars representing different subcategories."
          title="Bar Chart - V"
          url="test"
          image="/images/data-viz-bars/bar-chart-v.png"
          icon={<ChartIcon />}
          tags={['comparison']}
        />
        <HoverableCard
          description="Grouped bar chat is useful for visually comparing data across multiple subcategories
                        within each main category. It displays multiple bars for each category with each group
                        containing bars representing different subcategories."
          title="Bar Chart - H"
          url="test"
          image="/images/data-viz-bars/bar-chart-h.png"
          icon={<ChartIcon />}
          tags={['comparison']}
        />

        <HoverableCard
          description="Grouped bar chat is useful for visually comparing data across multiple subcategories
                        within each main category. It displays multiple bars for each category with each group
                        containing bars representing different subcategories."
          title="Grouped Bar Chart - V"
          url="test"
          image="/images/data-viz-bars/grouped-bar-chart-v.png"
          icon={<ChartIcon />}
          tags={['comparison']}
        />

        <HoverableCard
          description="Grouped bar chat is useful for visually comparing data across multiple subcategories
                        within each main category. It displays multiple bars for each category with each group
                        containing bars representing different subcategories."
          title="Grouped Bar Chart - V"
          url="test"
          image="/images/data-viz-bars/grouped-bar-chart-h.png"
          icon={<ChartIcon />}
          tags={['comparison']}
        />

        <HoverableCard
          description="Grouped bar chat is useful for visually comparing data across multiple subcategories
                        within each main category. It displays multiple bars for each category with each group
                        containing bars representing different subcategories."
          title="Stacked Bar Chart - V"
          url="test"
          image="/images/data-viz-bars/stacked-bar-chart-v.png"
          icon={<ChartIcon />}
          tags={['comparison', 'proportion']}
        />

        <HoverableCard
          description="Grouped bar chat is useful for visually comparing data across multiple subcategories
                        within each main category. It displays multiple bars for each category with each group
                        containing bars representing different subcategories."
          title="Stacked Bar Chart - H"
          url="test"
          image="/images/data-viz-bars/stacked-bar-chart-h.png"
          icon={<ChartIcon />}
          tags={['comparison', 'proportion']}
        />

        <HoverableCard
          description="Grouped bar chat is useful for visually comparing data across multiple subcategories
                        within each main category. It displays multiple bars for each category with each group
                        containing bars representing different subcategories."
          title="Lollipop - V"
          url="test"
          image="/images/data-viz-bars/lollipop-v.png"
          icon={<ChartIcon />}
          tags={['comparison']}
        />

        <HoverableCard
          description="Grouped bar chat is useful for visually comparing data across multiple subcategories
                        within each main category. It displays multiple bars for each category with each group
                        containing bars representing different subcategories."
          title="Lollipop - H"
          url="test"
          image="/images/data-viz-bars/lollipop-h.png"
          icon={<ChartIcon />}
          tags={['comparison']}
        />

        <HoverableCard
          description="Grouped bar chat is useful for visually comparing data across multiple subcategories
                        within each main category. It displays multiple bars for each category with each group
                        containing bars representing different subcategories."
          title="Grouped Lollipop - V"
          url="test"
          image="/images/data-viz-bars/grouped-lollipop-v.png"
          icon={<ChartIcon />}
          tags={['comparison']}
        />

        <HoverableCard
          description="Grouped bar chat is useful for visually comparing data across multiple subcategories
                        within each main category. It displays multiple bars for each category with each group
                        containing bars representing different subcategories."
          title="Grouped Lollipop - H"
          url="test"
          image="/images/data-viz-bars/grouped-lollipop-h.png"
          icon={<ChartIcon />}
          tags={['comparison']}
        />

        <HoverableCard
          description="Grouped bar chat is useful for visually comparing data across multiple subcategories
                        within each main category. It displays multiple bars for each category with each group
                        containing bars representing different subcategories."
          title="Stacked Lollipop - V"
          url="test"
          image="/images/data-viz-bars/stacked-lollipop-v.png"
          icon={<ChartIcon />}
          tags={['comparison', 'proportion']}
        />

        <HoverableCard
          description="Grouped bar chat is useful for visually comparing data across multiple subcategories
                        within each main category. It displays multiple bars for each category with each group
                        containing bars representing different subcategories."
          title="Stacked Lollipop - H"
          url="test"
          image="/images/data-viz-bars/stacked-lollipop-h.png"
          icon={<ChartIcon />}
          tags={['comparison', 'proportion']}
        />

        <HoverableCard
          description="Grouped bar chat is useful for visually comparing data across multiple subcategories
                        within each main category. It displays multiple bars for each category with each group
                        containing bars representing different subcategories."
          title="Funnel Chart"
          url="test"
          image="/images/data-viz-bars/funnel-chart.png"
          icon={<ChartIcon />}
          tags={['comparison', 'proportion']}
        />

        <HoverableCard
          description="Grouped bar chat is useful for visually comparing data across multiple subcategories
                        within each main category. It displays multiple bars for each category with each group
                        containing bars representing different subcategories."
          title="Histogram"
          url="test"
          image="/images/data-viz-bars/histogram.png"
          icon={<ChartIcon />}
          tags={['distribution', 'trend']}
        />
      </div>

      <PaginationStyled
        previousLink="/data-vizualization/lines"
        previous="Lines"
        nextLink="/data-vizualization/maps"
        next="Maps"
        className="footer-pagination"
      />
    </div>
  </div>
);

BarsPage.defaultProps = {
  className: '',
};

const StyledBarsPage = styled(BarsPage)`
  > .content {
    overflow-x: hidden;
    max-width: 75rem;
    margin: 0 auto;
    width: 100%;
    padding: 16px 16px 72px 16px;
    min-height: 100%;
    display: flex;
    flex-direction: column;

    @media (min-width: ${({ theme }) => theme.breakpoints['2xl']}) {
      padding: 40px 20px 16px 20px;
    }

    > .breadcrumbs {
      margin-bottom: 24px;

      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        margin-bottom: 40px;
      }
    }

    > .cards {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 16px;

      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: 80px;
      }

      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    > .footer-pagination {
      display: none;
      margin-top: auto;
      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        display: block;
      }
    }
  }
`;

export default StyledBarsPage;
