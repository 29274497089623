import styled from 'styled-components';

import { ReactComponent as ArrowCounterclockwise } from '../../images/icons/arrow-counterclockwise.svg';
import { ReactComponent as BuildChart } from '../../images/icons/build-chart.svg';
import { ReactComponent as Fullscreen } from '../../images/icons/fullscreen.svg';
import { ReactComponent as InfoCircle } from '../../images/icons/info-circle.svg';
import { ReactComponent as ZoomIn } from '../../images/icons/zoom-in.svg';
import { ReactComponent as ZoomOut } from '../../images/icons/zoom-out.svg';
import BreadcrumbsStyled from '../Breadcrumbs';
import Button from '../Button';

interface Breadcrumb {
  label: string;
  url?: string;
}

interface Props {
  className?: string;
  title?: string;
  controlBar?: boolean;
  breadcrumbs?: Array<Breadcrumb>;
}

const ContentHeader = ({ className, title, controlBar, breadcrumbs }: Props) => (
  <div className={className}>
    <div>
      <BreadcrumbsStyled breadcrumbs={breadcrumbs} />
      <h1>{title}</h1>
    </div>
    {controlBar && (
      <div className="control-bar">
        <div className="button-list">
          <button type="button" aria-label="icon">
            <InfoCircle />
          </button>
          <button type="button" aria-label="icon">
            <ZoomIn />
          </button>
          <button type="button" aria-label="icon">
            <ZoomOut />
          </button>
          <button type="button" aria-label="icon">
            <ArrowCounterclockwise />
          </button>
          <button type="button" aria-label="icon">
            <Fullscreen />
          </button>
        </div>
        <Button color="secondary" size="M" type="button" data-link="#">
          <span className="button-name">Use in Builder</span>
          <span className="button-icon">
            <BuildChart />
          </span>
        </Button>
      </div>
    )}
  </div>
);

ContentHeader.defaultProps = {
  className: '',
  title: '',
  controlBar: false,
  breadcrumbs: [],
};

const ContentHeaderStyled = styled(ContentHeader)`
  border: 0.0625rem solid var(--2685a133, #2685a133);
  border-radius: 0.5rem;
  background: url('/images/content-header-bg.jpg') center center / cover no-repeat;
  padding: 0.9375rem 1.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.5rem;
  gap: 1rem;

  h1 {
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.25;
    letter-spacing: -0.03em;
    color: var(--006b8a, #006b8a);
    margin: 0;
  }

  .control-bar {
    display: flex;
    gap: 1rem;
    align-items: center;
    background-color: var(--ffffff, #ffffff);
    padding: 0.5rem;
    border-radius: 0.5rem;
  }

  .button-list {
    display: inline-flex;

    button {
      cursor: pointer;
      color: var(--5c636e, #5c636e);
      padding: 0.75rem;
      background-color: transparent;
      border: 0 none;
      display: flex;
      align-items: center;
    }
  }
`;

export default ContentHeaderStyled;
