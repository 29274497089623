import styled from 'styled-components';

import { ReactComponent as ChevronRight } from '../../images/icons/chevron-right.svg';
import CardStyled from '../Card';

interface CardGroupProps {
  className?: string;
  title?: string;
  content?: string;
  linkUrl?: string;
  cards?: {
    title: string;
    content: string;
    linkUrl: string;
    icon?: React.ReactNode;
    image?: string;
    category?: boolean;
  }[];
  category?: boolean;
}

const CardGroup = ({ title, content, linkUrl, cards, className, category }: CardGroupProps) => (
  <div className={`${className} ${category && 'card-group-category'} `}>
    {!category && (
      <div>
        <h2>{title}</h2>
        <p>{content}</p>
        <a href={linkUrl}>
          <span>Explore More</span> <ChevronRight />
        </a>
      </div>
    )}
    <div className="group">
      {cards?.map((card) => (
        <CardStyled
          key={card.title}
          title={card.title}
          icon={card.icon}
          image={card.image}
          category={category}
        >
          {card.content}
        </CardStyled>
      ))}
    </div>
  </div>
);

CardGroup.defaultProps = {
  className: '',
  title: '',
  content: '',
  linkUrl: '',
  cards: [],
  category: false,
};

const CardGroupStyled = styled(CardGroup)`
  &:not(.card-group-category) {
    position: relative;
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 4fr;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1.5rem;
      border-style: solid;
      border-width: 0 0 0.0625rem 0.0625rem;
      border-bottom-left-radius: 0.5rem;
      border-bottom-color: var(--DADCE0, #dadce0);
      border-left-color: var(--DADCE0, #dadce0);
    }

    h2 {
      margin-top: 0;
      margin-bottom: 0;
      font-weight: 600;
      line-height: 1.3333333333;
      color: var(--color-text, #030404);
      letter-spacing: -0.02em;

      & + p {
        margin-top: 0.5rem;
      }
    }

    p {
      margin-top: 0;
      margin-bottom: 0;
      color: var(--5c636e, #5c636e);
      font-size: 0.875rem;
      line-height: 1.4285714286;

      & + a {
        margin-top: 1.5rem;
      }
    }

    a {
      display: block;
      margin: 0;
      color: var(--155676, #155676);
      text-decoration: none;
      font-size: 0.875rem;
      line-height: 1.4285714286;
      display: flex;
      align-items: center;
      gap: 0.25rem;

      &:hover {
        color: var(--003e5f, #003e5f);
      }
    }

    .group {
      display: grid;
      gap: 1rem;
      grid-template-columns: repeat(2, minmax(0, 1fr));

      @media (min-width: 1620px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
    }
  }

  &.card-group-category {
    margin-bottom: 2.5rem;

    &:last-child {
      margin-bottom: 5rem;
    }

    .group {
      display: grid;
      gap: 1.5rem;
      grid-template-columns: repeat(2, minmax(0, 1fr));

      @media (min-width: 1620px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
    }
  }
`;

export default CardGroupStyled;
