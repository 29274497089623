import styled from 'styled-components';

import Link from '../Link';

interface Props {
  className?: string;
  imagePath: string;
  title: string;
  content: string;
  linkPath: string;
}

const ArticleCard = ({ className, imagePath, title, content, linkPath }: Props) => (
  <div className={className}>
    <div style={{ backgroundImage: `url(${imagePath})` }} className="card-image" />
    <div className="card-content">
      <h6>{title}</h6>
      <p>{content}</p>
      <Link href={linkPath}>Learn More</Link>
    </div>
  </div>
);

ArticleCard.defaultProps = {
  className: '',
};

const StyledArticleCard = styled(ArticleCard)`
  display: flex;
  gap: 24px;
  padding: 24px 20px;
  align-items: start;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral.border};
  flex: 1 1 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    border-top: 1px solid ${({ theme }) => theme.colors.neutral.border};
    border-right: 1px solid ${({ theme }) => theme.colors.neutral.border};
    padding: 24px 40px;
    gap: 40px;
    align-items: center;
  }

  > .card-image {
    background-size: contain;
    height: 56px;
    width: 56px;

    background-position: center;
    background-repeat: no-repeat;
    //flex-basis: 30%;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      flex-basis: 30%;
      height: 104px;
      width: 104px;
    }
  }

  > .card-content {
    flex-basis: 70%;
    > h6 {
      color: ${({ theme }) => theme.colors.primary.text};
      font-size: ${({ theme }) => theme.fontSizes.base};
      line-height: ${({ theme }) => theme.lineHeights.base};
      margin-bottom: 16px;
    }

    > p {
      color: ${({ theme }) => theme.colors.neutral.text};
      font-size: ${({ theme }) => theme.fontSizes.sm};
      line-height: ${({ theme }) => theme.lineHeights.sm};
    }

    > a {
      margin-top: 16px;

      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        margin-top: 24px;
      }

      @media (min-width: ${({ theme }) => theme.breakpoints['2xl']}) {
        margin-top: 40px;
      }

      @media (min-height: ${({ theme }) => theme.breakpoints.lg}) {
        margin-top: 40px;
      }

      &:hover {
        color: ${({ theme }) => theme.colors.primary['text-hover']};
      }
    }
  }
`;

export default StyledArticleCard;
