import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as Union } from '../../images/icons/union.svg';

interface Props {
  className?: string;
  children?: React.ReactNode;
  title?: string;
  icon?: React.ReactNode;
  image?: string;
  category?: boolean;
  path?: string;
}

const Card = ({ className, children, title, icon, image, category, path = '' }: Props) => {
  const navigate = useNavigate();

  return (
    <button
      onClick={() => navigate(path)}
      type="button"
      className={`${className} ${category ? 'card-category' : ''}`}
    >
      {!category ? (
        <>
          <div className="icons-wrapper">
            <div className="icon">{icon}</div> <Union className="icon-link" />
          </div>
          <h4>{title}</h4>
          <p>{children}</p>
        </>
      ) : (
        <>
          <div className="card-category-img">
            {image && <img className="img-responsive" src={image} alt={title} />}
            <Union className="icon-link" />
          </div>
          <div className="card-category-desc">
            <h4>{title}</h4>
            <p>{children}</p>
          </div>
        </>
      )}
    </button>
  );
};

Card.defaultProps = {
  className: '',
  children: null,
  title: '',
  icon: null,
  image: '',
  category: false,
  path: '',
};

const CardStyled = styled(Card)`
  &:not(.card-category) {
    border: 0.0625rem solid var(--e9eaec, #e9eaec);
    background: var(--5f687714, #5f687714);
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    padding: 1rem;
    position: relative;
    text-align: left;
    min-height: 12.5rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    cursor: pointer;
    transition:
      background 0.3s ease-in-out,
      border-color 0.3s ease-in-out;

    &::before {
      content: '';
      position: absolute;
      bottom: -0.0625rem;
      left: -0.0625rem;
      height: 0.125rem;
      width: 0%;
      background: var(--2685a1, #2685a1);
      transition: width 0.3s ease-in-out;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0.0625rem;
      background: var(--2685a133, #2685a133);
    }

    &:hover {
      background: var(--2685a11f, #2685a11f);
      border-color: transparent;

      &::before {
        width: calc(100% + 0.125rem);
      }

      &::after {
        background: var(--2685a133, #2685a133);
      }

      .icon-link {
        color: var(--5c636e, #5c636e);
      }
    }

    &:active {
      background: var(--2685a13d, #2685a13d);
      border: 0.0625rem solid var(--2685a13d, #2685a13d);

      &::before {
        display: none;
      }

      &::after {
        background: var(--2685a133, #2685a133);
      }

      .icon-link {
        color: var(--006b8a, #006b8a);
      }
    }

    .icons-wrapper {
      display: flex;
      align-items: start;
      justify-content: space-between;

      & + h4 {
        margin-top: 0.5rem;
      }
    }

    .icon {
      padding: 0.25rem;

      &-link {
        color: var(--8e95a0, #8e95a0);
        transition: color 0.3s ease-in-out;
      }
    }

    h4 {
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.25;
      color: var(--030404, #030404);
      margin-top: 0;
      margin-bottom: 0;
      letter-spacing: -0.01em;

      & + p {
        margin-top: 0.5rem;
        margin-bottom: 0;
      }
    }

    p {
      color: var(--5c636e, #5c636e);
    }
  }

  &.card-category {
    display: flex;
    flex-direction: column;
    border: 0.0625rem solid var(--e9eaec, #e9eaec);
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    background: transparent;
    padding: 0;
    position: relative;
    overflow: hidden;
    text-align: left;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 0.25rem;
      transition: background-color 0.3s ease-in-out;
    }

    &:hover {
      &::after {
        background-color: var(--e9eaec, #e9eaec);
      }
    }

    .icon-link {
      position: absolute;
      top: 1rem;
      right: 1rem;
      color: var(--8e95a0, #8e95a0);
      transition: color 0.3s ease-in-out;
    }

    .card-category {
      &-img {
        display: flex;
      }

      &-desc {
        padding: 1.5rem 1.25rem 2rem;

        h4 {
          color: var(--030404, #030404);
          font-size: 1.25rem;
          font-weight: 600;
          line-height: 1.2;
          letter-spacing: -0.02em;
          margin: 0 0 1rem 0;
          transition: color 0.3s ease-in-out;
        }

        p {
          color: var(--5c636e, #5c636e);
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 1.428571428571429;
          margin: 0;
        }

        &:hover {
          h4 {
            color: var(--006b8a, #006b8a);
          }
        }
      }
    }
  }
`;

export default CardStyled;
