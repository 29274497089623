import styled from 'styled-components';

import bubbles from '../../images/bubles.png';
import { ReactComponent as CalendarIcon } from '../../images/icons/calendar.svg';
import { ReactComponent as ChevronRight } from '../../images/icons/chevron-right.svg';
import { ReactComponent as ExternalLinkIcon } from '../../images/icons/external-link.svg';
import Button from '../Button';

interface Props {
  className?: string;
  imgPath?: string;
}

const CardPanel = ({ className, imgPath }: Props) => (
  <div className={className}>
    <div className="card-panel__left">
      <div className="time">
        <CalendarIcon />
        <span>2018 to 2022</span>
      </div>
      <h5>Introducing the DHCS</h5>

      <button type="button" className="share-btn">
        Share
        <ExternalLinkIcon />
      </button>

      <Button color="secondary" size="S" type="button" data-link="#">
        <span className="button-name">View Project</span>
        <span className="button-icon">
          <ChevronRight />
        </span>
      </Button>
    </div>

    <div className="card-panel__right" style={{ backgroundImage: `url(${imgPath})` }} />
  </div>
);

CardPanel.defaultProps = {
  className: '',
  imgPath: '',
};

const StyledCardPanel = styled(CardPanel)`
  display: flex;
  gap: 16px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.grey['200']};
  background-color: ${({ theme }) => theme.colors.white};
  flex-grow: 1;
  flex-basis: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-basis: calc(25% - 16px);
  }

  > .card-panel__left {
    flex-basis: 50%;
    padding: 24px 0 24px 24px;
    box-sizing: border-box;

    > .time {
      background-color: ${({ theme }) => theme.colors.primary['background-strong']};
      color: ${({ theme }) => theme.colors.white};
      font-weight: 400;
      font: ${({ theme }) => theme.fontSizes.sm};
      align-items: center;
      display: inline-flex;
      border-radius: 2px;
      margin-bottom: 8px;
      gap: 1px;

      svg {
        margin-left: 4px;
        margin-right: 4px;
      }

      > span {
        font-size: ${({ theme }) => theme.fontSizes.xs};
        line-height: ${({ theme }) => theme.lineHeights.xs};
        font-weight: 400;
        position: relative;
        padding: 3px 8px;
        &:after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          display: inline-block;
          width: 1px;
          height: 100%;
          background-color: ${({ theme }) => theme.colors.neutral.border};
        }
      }
    }

    > h5 {
      font-size: ${({ theme }) => theme.fontSizes.base};
      font-weight: 600;
      line-height: ${({ theme }) => theme.lineHeights.base};
      margin-bottom: 8px;
      color: ${({ theme }) => theme.colors.primary.text};

      @media (min-width: ${({ theme }) => theme.breakpoints['2xl']}) {
        margin-bottom: 40px;
      }
    }

    > .share-btn {
      display: inline-flex;
      align-items: center;
      gap: 6px;
      color: ${({ theme }) => theme.colors.primary.text};
      font-size: ${({ theme }) => theme.fontSizes.sm};
      line-height: ${({ theme }) => theme.lineHeights.base};
      font-weight: 500;
      padding: 0;
      background: transparent;
      border: none;
      cursor: pointer;
      margin-bottom: 16px;

      &:hover {
        color: ${({ theme }) => theme.colors.primary['text-hover']};
      }
    }
  }

  > .card-panel__right {
    background-image: url(${bubbles});
    flex-basis: 50%;
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
    box-sizing: border-box;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      min-width: 70px;
    }
  }
`;

export default StyledCardPanel;
