import styled from 'styled-components';

import ChartStyled from '../Chart';

interface RelatedPlotsProps {
  className?: string;
  title?: string;
  description?: string;
  data?: {
    className?: string;
    id?: number;
    title?: string;
    image?: string;
    icon?: React.ReactNode;
  }[];
}

const RelatedPlots = ({ className, title, description, data = [] }: RelatedPlotsProps) => (
  <div className={className}>
    <div className="related-plots mb-40">
      <h2 id="related-plots">{title}</h2>
      <p>{description}</p>
    </div>
    <div className="related-plots-chart">
      {data?.map((item) => (
        <ChartStyled
          className={item.className}
          key={item.id}
          title={item.title}
          icon={item.icon}
          image={item.image}
        />
      ))}
    </div>
  </div>
);

RelatedPlots.defaultProps = {
  className: '',
  title: '',
  description: '',
  data: [],
};

const RelatedPlotsStyled = styled(RelatedPlots)`
  .related-plots {
    h2 {
      font-weight: 600;
      line-height: 1.333333333333333;
      letter-spacing: -0.02em;
      color: var(--030404, #030404);
      margin-top: 0;
      margin-bottom: 0.5rem;
    }

    p {
      color: var(--5c636e, #5c636e);
      font-size: 0.875rem;
      line-height: 1.142857142857143;
      margin: 0;
    }
  }
  .related-plots-chart {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    @media (min-width: 1620px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
`;

export default RelatedPlotsStyled;
