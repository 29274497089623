import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { ReactComponent as ChevronLeft } from '../../images/icons/chevron-left.svg';

interface Breadcrumb {
  label: string;
  url?: string;
}

interface Props {
  className?: string;
  breadcrumbs?: Array<Breadcrumb>;
}

const Breadcrumbs = ({ className, breadcrumbs }: Props) => (
  <div className={className}>
    <ChevronLeft />

    {breadcrumbs && (
      <div className="breadcrumbs-list">
        {breadcrumbs.map((breadcrumb, index) =>
          index === breadcrumbs.length - 1 ? (
            <span key={uuidv4()} className="breadcrumbs-item last">
              {breadcrumb.label}
            </span>
          ) : (
            <Link key={uuidv4()} to={breadcrumb.url || '#'} className="breadcrumbs-item">
              {breadcrumb.label}
            </Link>
          ),
        )}
      </div>
    )}
  </div>
);

Breadcrumbs.defaultProps = {
  className: '',
  breadcrumbs: [],
};

const BreadcrumbsStyled = styled(Breadcrumbs)`
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
  gap: 0.25rem;

  .breadcrumbs {
    &-list {
      display: flex;
      align-items: center;
      gap: 0.75rem;
    }

    &-item {
      font-size: 0.75rem;
      line-height: 1.166666666666667;
      border: 0 none;
      border-radius: 0.125rem;
      position: relative;
      transition: color 0.3s ease-in-out;
      cursor: pointer;
      text-decoration: none;

      &:not(.last) {
        padding: 0.0625rem 0.25rem;
        color: var(--5f6877, #5f6877);
        background-color: var(--5f687714, #5f687714);

        &::after {
          content: '/';
          position: absolute;
          color: var(--9fa4ad, #9fa4ad);
          right: -0.5rem;
        }

        &:hover {
          color: var(--000005, #000005);
        }
      }

      &.last {
        padding: 0;
        color: var(--000005, #000005);
        background-color: transparent;
        pointer-events: none;
      }
    }
  }
`;

export default BreadcrumbsStyled;
