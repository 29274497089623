import Scrollspy from 'react-scrollspy-ez';
import styled from 'styled-components';

import BadgeStyled from '../../../components/Badge';
import ContentHeaderStyled from '../../../components/ContentHeader';
import ExternalLinkGroupStyled from '../../../components/ExternalLinkGroup';
import Header from '../../../components/Header';
import PaginationStyled from '../../../components/Pagination';
import SidebarStyled from '../../../components/Sidebar';
import { ReactComponent as IconDanger } from '../../../images/icons/icon-danger.svg';
import { ReactComponent as IconSuccess } from '../../../images/icons/icon-success.svg';
import commonPageContentStyles from '../../../styles/mixins/_commonPageContentStyles';
import navigationStyles from '../../../styles/mixins/navigationMenuStyles';

interface Props {
  className?: string;
}

const dataExternalLinkGroups = [
  {
    id: 1,
    title: 'Government accessibility resources',
    cards: [
      {
        title: 'Learn about writing in plain language ',
        subtitle: '[plainlanguage.gov]',
        linkUrl: '#',
      },
      {
        title: 'Plain language resources',
        subtitle: '[plainlanguage.gov]',
        linkUrl: '#',
      },
      {
        title: '18F’s accessibility guide ',
        subtitle: '[accessibility.18f.gov]',
        linkUrl: '#',
      },
      {
        title: 'Accessibility Requirements Tool (ART) for contracting ',
        subtitle: '[section508.gov]',
        linkUrl: '#',
      },
      {
        title: 'Understanding universal design',
        subtitle: '[section508.gov]',
        linkUrl: '#',
      },
    ],
  },
];

const NavigationStructure = ({ className }: Props) => (
  <div className={className}>
    <Header className="header" />
    <SidebarStyled className="sidebar" />
    <div className="scrollable-content">
      <div className="content">
        <ContentHeaderStyled
          title="Navigation"
          breadcrumbs={[
            { label: 'General Components', url: '/general-components' },
            { label: 'Navigation' },
          ]}
        />
        <div className="page-container">
          <div className="page-content">
            <div className="page-content-with-navigation-wrapper">
              <div className="page-content-with-navigation">
                <div className="page-content-item">
                  <h1 className="h1" id="overview">
                    Overview
                  </h1>
                  <p className="mb-40">
                    Tabs are used to group different but related content, allowing users to navigate
                    views without leaving the page. They always contain at least two items and one
                    tab is active at a time. Tabs can be used on full page layouts or in components
                    such as modals, cards, or side panels.
                  </p>
                  <h3 className="h3" id="tabs">
                    Tabs
                  </h3>
                  <p className="mb-40">
                    Tabs are used to organize related content. They allow the user to navigate
                    between groups of information that appear within the same context.
                  </p>
                </div>
                <img
                  className="img-responsive mb-40"
                  src="/images/general-components-navigation/screen-tabs@3x.jpg"
                  alt=""
                />
                <div className="page-content-item">
                  <h3 className="h3" id="segmented-buttons">
                    Segmented buttons
                  </h3>
                  <p className="mb-40">
                    Segmented buttons help people select options, switch views, or sort elements
                  </p>
                </div>
                <img
                  className="img-responsive mb-40"
                  src="/images/general-components-navigation/screen-segmented-buttons@3x.jpg"
                  alt=""
                />
                <div className="page-content-item">
                  <h3 className="h3" id="toggle">
                    Toggle
                  </h3>
                  <p className="mb-40">
                    A toggle is used to quickly switch between two possible states. They are
                    commonly used for “on/off” switches.
                  </p>
                </div>
                <img
                  className="img-responsive mb-40"
                  src="/images/general-components-navigation/screen-toggle@3x.jpg"
                  alt=""
                />
                <div className="page-content-item">
                  <h4 className="h4" id="toggle-typography">
                    Toggle Typography
                  </h4>
                  <p className="mb-40">
                    Toggle labels should be set in sentence case, with only the first word in a
                    phrase and any proper nouns capitalized, and no more than three words.
                  </p>
                </div>
                <table className="table table-variants mb-40">
                  <thead>
                    <tr>
                      <th style={{ width: '15.625rem' }}>Element</th>
                      <th>Font-size (px/rem)</th>
                      <th>Font-weight</th>
                      <th>Type token</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td valign="top">Label text</td>
                      <td>12 / 0.75</td>
                      <td>Regular / 400</td>
                      <td>
                        <div className="role-color">$body/body 1</div>
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">Action text</td>
                      <td>14 / 0.875</td>
                      <td>Regular / 400</td>
                      <td>
                        <div className="role-color">$body/body 1</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <h1 className="h1 divider" id="specifications">
                  Specifications
                </h1>
                <div className="page-content-item">
                  <h3 className="visually-hidden" id="anatomy">
                    Anatomy
                  </h3>
                  <h3 className="h3">Anatomy of line tabs</h3>
                </div>
                <div className="number-cards">
                  <div className="number-card">
                    <div className="number-card-img">
                      <span className="number-circle">1</span>
                      <img
                        src="/images/general-components-navigation/screen-anatomy-of-line-tabs-01@3x.jpg"
                        alt=""
                      />
                    </div>
                    <div className="number-card-desc">
                      <p>1. Line tabs - A. Label, B. Indicator</p>
                    </div>
                  </div>
                  <div className="number-card">
                    <div className="number-card-img">
                      <span className="number-circle">2</span>
                      <img
                        src="/images/general-components-navigation/screen-anatomy-of-line-tabs-02@3x.jpg"
                        alt=""
                      />
                    </div>
                    <div className="number-card-desc">
                      <p>2. Icon-only line tabs - B. Indicator, C. Icon</p>
                    </div>
                  </div>
                </div>
                <div className="page-content-item">
                  <h3 className="h3" id="anatomy-size">
                    Anatomy &amp; Size of Segmented buttons
                  </h3>
                  <p>
                    Segmented buttons help people select options, switch views, or sort elements.
                  </p>
                </div>
                <img
                  className="img-responsive mb-40"
                  src="/images/general-components-navigation/screen-anatomy-size@3x.jpg"
                  alt=""
                />
                <div className="page-content-item">
                  <h4 className="h4">Tabs Formatting</h4>
                  <p className="mb-40">
                    The tab component consists of two distinct zones: selected and unselected. There
                    are always at least two tabs and one is selected by default. Icons are optional.
                  </p>
                </div>
                <h1 className="h1 divider" id="guidelines">
                  Guidelines
                </h1>
                <div className="page-content-item">
                  <h3 className="visually-hidden" id="alignment">
                    Alignment
                  </h3>
                  <h3 className="h3">Alignment of Tabs</h3>
                  <p className="mb-40">
                    Much like buttons, alignment of tabs depends on where they appear and whether or
                    not they&apos;re contained within another component. As a general rule, the
                    first label for both line tabs and contained tabs align with the grid and the
                    text below. If tabs are within another component, such as a card, follow the
                    grid that you are using inside the component and align the label with text in
                    the component.
                  </p>
                  <ul className="font-sm">
                    <li>
                      <strong>1. Active Button:</strong> Only one segment can be active at a time.
                      These mutually exclusive Buttons can appear as icon only, text left, or icon
                      left with text.
                    </li>
                    <li>
                      <strong>2. Default Buttons:</strong> Default control options that can be
                      customized.
                    </li>
                  </ul>
                </div>
                <div className="ex-cards">
                  <div className="ex-card true">
                    <img
                      className="ex-card-img"
                      src="/images/general-components-navigation/screen-alignment-of-tabs-01@3x.jpg"
                      alt=""
                    />
                    <div className="ex-card-desc">
                      <BadgeStyled className="success" iconLeft={<IconSuccess />}>
                        Do
                      </BadgeStyled>
                      <p>Do align tab labels with the grid</p>
                    </div>
                  </div>
                  <div className="ex-card false">
                    <img
                      className="ex-card-img"
                      src="/images/general-components-navigation/screen-alignment-of-tabs-02@3x.jpg"
                      alt=""
                    />
                    <div className="ex-card-desc">
                      <BadgeStyled className="danger" iconLeft={<IconDanger />}>
                        Don&apos;t
                      </BadgeStyled>
                      <p>Do not align tab container with the grid</p>
                    </div>
                  </div>
                </div>
                <div className="page-content-item">
                  <h3 className="visually-hidden" id="usage">
                    Usage
                  </h3>
                  <h3 className="h3">Usage of Segmented buttons</h3>
                  <ul className="disc-list font-sm">
                    <li>Only one control option can be selected and active at a time.</li>
                    <li>
                      Once a user selects an option, the results should be displayed immediately.
                    </li>
                    <li>
                      Each Button must clearly identify its purpose. Text variants will already have
                      their labels displayed visually, but should be used for icon only variants.
                    </li>
                    <li>
                      All the buttons in the group to be the same size regardless Of text length.
                      Length Of segments is determined by the longest text label.
                    </li>
                  </ul>
                </div>
                <div className="ex-cards">
                  <div className="ex-card true">
                    <img
                      className="ex-card-img"
                      src="/images/general-components-navigation/screen-usage-of-segmented-buttons-01@3x.jpg"
                      alt=""
                    />
                    <div className="ex-card-desc">
                      <BadgeStyled className="success" iconLeft={<IconSuccess />}>
                        Do
                      </BadgeStyled>
                      <p>
                        Labels should be short and succinct. If a label is too long to fit within
                        its segment, consider using an icon- only variant.
                      </p>
                    </div>
                  </div>
                  <div className="ex-card false">
                    <img
                      className="ex-card-img"
                      src="/images/general-components-navigation/screen-usage-of-segmented-buttons-02@3x.jpg"
                      alt=""
                    />
                    <div className="ex-card-desc">
                      <BadgeStyled className="danger" iconLeft={<IconDanger />}>
                        Don&apos;t
                      </BadgeStyled>
                      <p>Wrap or truncate text</p>
                    </div>
                  </div>
                </div>
                <div className="page-content-item">
                  <h3 className="visually-hidden" id="placement">
                    Placement
                  </h3>
                  <h3 className="h3">Segmented buttons Placement</h3>
                  <p className="mb-40">
                    Segmented buttons should have adequate margins from the edge of the viewport or
                    frame. On larger screens, set a maximum padding for all button segments so the
                    set doesn&apos;t fill
                  </p>
                </div>
                <div className="ex-cards">
                  <div className="ex-card true">
                    <img
                      className="ex-card-img"
                      src="/images/general-components-navigation/screen-segmented-buttons-placement-01@3x.jpg"
                      alt=""
                    />
                    <div className="ex-card-desc">
                      <BadgeStyled className="success" iconLeft={<IconSuccess />}>
                        Do
                      </BadgeStyled>
                      <p>Segmented buttons are best used for selecting between 2 and 5 choices</p>
                    </div>
                  </div>
                  <div className="ex-card false">
                    <img
                      className="ex-card-img"
                      src="/images/general-components-navigation/screen-segmented-buttons-placement-02@3x.jpg"
                      alt=""
                    />
                    <div className="ex-card-desc">
                      <BadgeStyled className="danger" iconLeft={<IconDanger />}>
                        Don&apos;t
                      </BadgeStyled>
                      <p>
                        Don&apos;t use more than five segments in a single segmented button. Choices
                        should be scoped. If you have more than five choices, consider using another
                        component, such as chips.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="page-content-item">
                  <h3 className="h3" id="states">
                    States
                  </h3>
                  <p className="mb-40">
                    Toggle is a control that is used to quickly switch between two possible states.
                    Toggles are only used for these binary actions that occur immediately after the
                    user “flips the switch”. They are commonly used for “on/off” switches.
                  </p>
                </div>
                <div className="number-cards">
                  <div className="number-card">
                    <div className="number-card-img">
                      <span className="number-circle">1</span>
                      <img
                        src="/images/general-components-navigation/screen-states-01@3x.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="number-card">
                    <div className="number-card-img">
                      <span className="number-circle">2</span>
                      <img
                        src="/images/general-components-navigation/screen-states-02@3x.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="number-card">
                    <div className="number-card-img">
                      <span className="number-circle">3</span>
                      <img
                        src="/images/general-components-navigation/screen-states-03@3x.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="number-card">
                    <div className="number-card-img">
                      <span className="number-circle">4</span>
                      <img
                        src="/images/general-components-navigation/screen-states-04@3x.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="number-card">
                    <div className="number-card-img">
                      <span className="number-circle">5</span>
                      <img
                        src="/images/general-components-navigation/screen-states-05@3x.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <h1 className="h1 divider" id="references">
                  References
                </h1>
                {dataExternalLinkGroups.map((externalLinkGroup) => (
                  <ExternalLinkGroupStyled
                    key={externalLinkGroup.id}
                    title={externalLinkGroup.title}
                    cards={externalLinkGroup.cards}
                  />
                ))}
              </div>
              <div>
                <div className="page-navigation">
                  <h4>Contents</h4>
                  <Scrollspy
                    ids={[
                      'overview',
                      'tabs',
                      'segmented-buttons',
                      'toggle',
                      'specifications',
                      'anatomy',
                      'guidelines',
                      'alignment',
                      'usage',
                      'placement',
                      'states',
                      'references',
                    ]}
                    offset={0}
                    activeItemClassName="active"
                    itemContainerClassName="multilevel-link-list"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <PaginationStyled
          previousLink="/general-components/communication"
          previous="Communication"
          nextLink="/general-components/selection"
          next="Selection"
        />
      </div>
    </div>
  </div>
);

NavigationStructure.defaultProps = {
  className: '',
};

const Navigation = styled(NavigationStructure)`
  ${commonPageContentStyles}
  ${navigationStyles([2, 3, 4, 6, 8, 9, 10, 11])}
`;

export default Navigation;
