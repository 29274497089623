import { ReactNode, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as SettingsIcon } from '../../images/icons/settings.svg';
import { ReactComponent as XIcon } from '../../images/icons/x.svg';
import Button from '../Button';

interface Props {
  className?: string;
  iconName: 'gear';
  mainText: string;
  numberOfAlerts?: number;
  additionalText?: string;
  actions?: ReactNode;
}

const icons = {
  gear: <SettingsIcon className="gear-icon" />,
};

const Alert = ({
  className,
  iconName,
  mainText,
  numberOfAlerts,
  additionalText,
  actions,
}: Props) => {
  const [isOpen, setIsOpen] = useState(true);

  if (!isOpen) {
    return null;
  }

  return (
    <div role="alert" className={className}>
      <div className="alert-content">
        <h6>
          {icons[iconName]}
          {mainText}
        </h6>

        <div className="alert-body">
          {numberOfAlerts && <span className="number-of-alerts">{numberOfAlerts} new</span>}
          {additionalText && <p className="additional-text">{additionalText}</p>}
        </div>

        <div className="actions">{actions}</div>
      </div>
      <Button
        onClick={() => setIsOpen(false)}
        className="close-btn"
        color="secondary"
        size="M"
        type="button"
        data-link="#"
      >
        <XIcon />
      </Button>
    </div>
  );
};

Alert.defaultProps = {
  className: '',
  numberOfAlerts: 0,
  additionalText: '',
  actions: null,
};

const StyledAlert = styled(Alert)`
  margin-left: 16px;
  margin-right: 16px;
  padding: 12px;
  border-radius: 6px;
  background: linear-gradient(to left, white, rgba(255, 255, 255, 0)), #2685a11f;
  border: 1px solid rgba(38, 133, 161, 0.24);
  display: flex;
  align-items: start;
  justify-content: space-between;
  position: relative;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    align-items: center;
    padding: 10px 16px;
  }

  > .alert-content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 8px;

    > h6 {
      color: ${({ theme }) => theme.colors.primary.text};
      font-size: ${({ theme }) => theme.fontSizes.base};
      line-height: ${({ theme }) => theme.lineHeights.base};
      font-weight: 600;

      > .gear-icon {
        color: ${({ theme }) => theme.colors.primary.text};
        margin-right: 8px;
        position: relative;
        top: 2px;
      }
    }

    > .alert-body {
      display: flex;
      align-items: start;
      gap: 8px;
      > .additional-text {
        color: ${({ theme }) => theme.colors.neutral['text-weak']};
        font-size: ${({ theme }) => theme.fontSizes.base};
        line-height: ${({ theme }) => theme.lineHeights.base};
        font-weight: 400;
        max-width: 450px;

        @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
          max-width: 100%;
        }
      }

      > .number-of-alerts {
        white-space: nowrap;
        background-color: ${({ theme }) => theme.colors.primary['background-strong']};
        color: ${({ theme }) => theme.colors.white};
        padding: 3px 4px;
        font-size: ${({ theme }) => theme.fontSizes.xs};
        line-height: ${({ theme }) => theme.lineHeights.xs};
        font-weight: 400;
        border-radius: 2px;
      }
    }

    > .actions {
      display: none;
      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        margin-left: 24px;
        display: flex;
        align-items: center;
      }
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      flex-direction: row;
    }
  }

  .close-btn {
    background-color: ${({ theme }) => theme.colors.primary.background};
    border: unset;
    display: flex;
    padding: 6px;
    border-radius: 3px;
    cursor: pointer;
    position: absolute;
    top: 4px;
    right: 4px;
    height: 24px;
    align-items: center;
    width: 24px;

    &:hover {
      background-color: ${({ theme }) => theme.colors.primary['background-medium']};

      > svg {
        path {
          fill: ${({ theme }) => theme.colors.primary['text-hover']};
        }
      }
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      position: unset;
    }
  }
`;

export default StyledAlert;
