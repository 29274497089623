import styled from 'styled-components';

import NavigationStyled from '../Navigation';

interface Props {
  className?: string;
}

const Sidebar = ({ className }: Props) => (
  <div className={className}>
    <NavigationStyled />
  </div>
);

Sidebar.defaultProps = {
  className: '',
};

const SidebarStyled = styled(Sidebar)`
  width: 17rem;
  padding: 0.75rem;
  background-color: var(--eff0f1, #eff0f1);

  @media (min-width: 1620px) {
    width: 20rem;
  }
`;

export default SidebarStyled;
