// redux/reducers.js
import { combineReducers } from 'redux';

import exampleReducer from './exampleReducer'; // Import your individual reducers

const rootReducer = combineReducers({
  example: exampleReducer, // Add your reducers here
});

export default rootReducer;
