import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { MENU_ROUTES } from '../../../constants';
import useDisableBodyScroll from '../../../hooks/useDisableBodyScroll';
import { ReactComponent as Logo } from '../../../images/dhcs.svg';
import { ReactComponent as Grid } from '../../../images/icons/grid.svg';
import { ReactComponent as IconSearch } from '../../../images/icons/search.svg';
import { ReactComponent as XIcon } from '../../../images/icons/x.svg';
import Button from '../../Button';
import SidebarStyled from '../../Sidebar';
import UserProfile from '../../UserProfile';

interface Props {
  className?: string;
}

const MenuMobile = ({ className }: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // Custom hook to disable body scroll when the menu is open
  useDisableBodyScroll(isMenuOpen);

  const searchBlockRef = useRef<HTMLInputElement>(null);

  return (
    <div className={className}>
      {isMenuOpen && <div className="menu-overlay" />}
      <div className="menu-bar">
        <Link to={MENU_ROUTES.overview.path}>
          <Logo />
        </Link>
        <div className="menu-bar__actions">
          <button
            aria-label="search-icon"
            className="search-btn"
            onClick={() => {
              if (searchBlockRef.current) {
                searchBlockRef.current.focus();
              }
              setIsMenuOpen(true);
            }}
            type="button"
          >
            <IconSearch />
          </button>
          <Button
            onClick={() => (window.location.href = 'https://dhcs.fuselabcreative.com/sign-in')}
            color="primary"
            size="S"
            type="button"
            data-link="#"
          >
            <span className="button-name">Builder</span>
          </Button>

          <button
            type="button"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="toggle-menu-btn"
            aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
          >
            {isMenuOpen ? <XIcon /> : <Grid />}
          </button>
        </div>
      </div>

      <div className={`menu-drawer ${isMenuOpen ? 'open' : ''}`}>
        <div className="menu-drawer__content">
          <div className="search-block">
            <IconSearch />
            <input ref={searchBlockRef} type="text" name="search" placeholder="Search ..." />
          </div>

          <SidebarStyled className="sidebar" />
        </div>

        {isMenuOpen && <UserProfile className="user-profile" />}
      </div>
    </div>
  );
};

MenuMobile.defaultProps = {
  className: '',
};

const MenuMobileStyled = styled(MenuMobile)`
    display: block;
    padding: 8px 16px;
    background-color: ${({ theme }) => theme.colors.neutral['background-medium']};

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        display: none;
    }
    
    > .menu-overlay {
        background-color: #000000;
        opacity: 60%;
        position: absolute;
        width: 100%;
        height: calc(100vh - 48px);
        left: 0;
        top: 48px;
        z-index: 1;
    }

  > .menu-bar {
      display: flex;
      align-items: center;
      justify-content: space-between;

      > .menu-bar__actions {
          display: flex;
          gap: 14px;
          position: relative;

         > .search-btn {
              border: unset;
              display: flex;
              align-items: center;
             color: ${({ theme }) => theme.colors.neutral['text-weak']};
          }

         > .toggle-menu-btn {
              border: unset;
              display: flex;
              align-items: center;
              position: relative;
              margin-left: 14px;
              cursor: pointer;

              &::before {
                  position: absolute;
                  content: '';
                  display: block;
                  width: 1px;
                  height: 48px;
                  background-color: ${({ theme }) => theme.colors.neutral.border};
                  left: -14px;
              }
          }
      }
  }

   > .menu-drawer { 
      z-index: 11;
      padding: 20px 12px;
      background-color: ${({ theme }) => theme.colors.neutral['background-medium']};
      border-top: 1px solid ${({ theme }) => theme.colors.neutral.border};
      position: fixed;
      top: 48px;
      height: calc(100vh - 48px);
      width: 272px;
      transition: transform 0.3s ease-in-out;
       transform: translateX(-100%);
       left: 0;

      &.open {
          transform: translateX(0%);
          left: 0;
     
      }
       
       > .menu-drawer__content {
           overflow-x: hidden;
           height: calc(100vh - 148px);
           > .sidebar {
           width: 100%;
           padding-left: 0;
       }
       
       > .search-block {
         position: relative;
         display: flex;

         > svg {
              position: absolute;
              left: 12px;
              top: 50%;
              transform: translateY(-50%);
          }
            
           > input {
              display: block;
              width: 100%;
              font-size: ${({ theme }) => theme.fontSizes.sm};
              line-height: ${({ theme }) => theme.lineHeights.sm};
              padding: 8px 12px 8px 35px;
              color: ${({ theme }) => theme.colors.neutral['text-weak']};
              align-items: center;
              gap: 8px;
              height: 40px;
              background-color: ${({ theme }) => theme.colors.neutral.background};
              border: 1px solid ${({ theme }) => theme.colors.neutral.border};
              border-radius: 0.375rem;

        &:active {
          background: ${({ theme }) => theme.colors.white};
          border: 1px solid ${({ theme }) => theme.colors.neutral.border};
        }

        &:focus {
          background: ${({ theme }) => theme.colors.white};
          border-color: ${({ theme }) => theme.colors.primary.border};
          outline: none;
        }
              
         &:hover:not(:focus) {
           background-color: ${({ theme }) => theme.colors.neutral['background-hover']};
          }
        }
      }
       }

       > .user-profile {
           position: absolute;
           bottom: 0;


           .profile-menu {
           right: auto;

       }
       }
    }
  }
`;

export default MenuMobileStyled;
