import { ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
  icon: ReactNode;
  content: string;
  boldWordsCount?: number;
}

const Article = ({ className, icon, content, boldWordsCount = 1 }: Props) => {
  const words = content.split(' ');
  const boldWords = words.slice(0, boldWordsCount).join(' ');
  const restOfContent = words.slice(boldWordsCount).join(' ');

  return (
    <div className={className}>
      <div className="article-icon">{icon}</div>

      <div className="article-content">
        <b>{boldWords} </b>
        {restOfContent}
      </div>
    </div>
  );
};

Article.defaultProps = {
  className: '',
  boldWordsCount: 1,
};

const StyledArticle = styled(Article)`
  display: flex;
  padding: 16px 0;
  align-items: start;
  border-bottom: 0;
  border-left: 0;
  border-top: 1px dotted ${({ theme }) => theme.colors.neutral.border};
  width: 100%;
  flex-basis: 50%;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    border-right: 0;
    border-top: 0;
    border-bottom: 1px dotted ${({ theme }) => theme.colors.neutral.border};
    border-left: 1px dotted ${({ theme }) => theme.colors.neutral.border};
    padding: 16px;
  }

  > .article-icon {
    margin-right: 16px;
    background-color: ${({ theme }) => theme.colors.grey['200']};
    padding: 4px;
    align-items: center;
    display: flex;
    border-radius: 2px;
  }

  > .article-content {
    font-size: ${({ theme }) => theme.fontSizes.base};
    line-height: ${({ theme }) => theme.lineHeights.base};
    color: ${({ theme }) => theme.colors.neutral.text};
  }
`;

export default StyledArticle;
