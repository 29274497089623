import styled from 'styled-components';

import CardStyled from '../../components/Card';
import PaginationStyled from '../../components/Pagination';
import commonPageContentStyles from '../../styles/mixins/_commonPageContentStyles';

const CARDS = [
  {
    title: 'Lines',
    content:
      'It outlines the primary and secondary color palettes along with their corresponding hex codes or Pantone values, ensuring consistency across all brand materials.',
    linkUrl: '/data-vizualization/lines',
    image: '/images/data-vizualization/data-vizualization-line-chart@3x.jpg',
  },
  {
    title: 'Bars',
    content:
      'This section provides details about the primary and secondary fonts, including font families, weights, and sizes for various use cases such as headings, body text, and captions.',
    linkUrl: '/data-vizualization/bars',
    image: '/images/data-vizualization/data-vizualization-bars-chart@3x.jpg',
  },
  {
    title: 'Maps',
    content:
      'This section outlines the design principles for icons, including style, grid system, and preferred sizes. It may also specify guidelines for icon usage in different contexts.',
    linkUrl: '/data-vizualization/maps',
    image: '/images/data-vizualization/data-vizualization-maps@3x.jpg',
  },
  {
    title: 'Tables',
    content:
      'Here, you will find guidelines on logo usage, including acceptable variations such as full-color, monochrome, and reverse versions.',
    linkUrl: '/data-vizualization/tables',
    image: '/images/data-vizualization/tables@3x.jpg',
  },
  {
    title: 'Circulars Chart',
    content: `Here, you'll find guidelines on logo usage, including acceptable variations such as full-color, monochrome, and reverse versions.`,
    linkUrl: '/data-vizualization/circulars-chart',
    image: '/images/data-vizualization/data-vizualization-circulars-chart@3x.jpg',
  },
  {
    title: 'Diagrams',
    content: `Here, you'll find guidelines on logo usage, including acceptable variations such as full-color, monochrome, and reverse versions.`,
    linkUrl: '/data-vizualization/diagrams',
    image: '/images/data-vizualization/diagrams@3x.jpg',
  },
  {
    title: 'Others',
    content: `Here, you'll find guidelines on logo usage, including acceptable variations such as full-color, monochrome, and reverse versions.`,
    linkUrl: '/data-vizualization/others',
    image: '/images/data-vizualization/others@3x.jpg',
  },
];

interface Props {
  className?: string;
}

const DataVizualizationStructure = ({ className }: Props) => (
  <div className={className}>
    {/* <Header className="header" /> */}
    {/* <SidebarStyled className="sidebar" /> */}
    <div className="scrollable-content">
      <div className="content">
        <div className="hero">
          <div className="hero__content">
            <h1>Data Vizualization</h1>
            <p>
              Outlines the visual and stylistic elements of a brand to ensure consistency across all
              communication channels. It typically includes guidelines for logo usage, color
              palette, typography, imagery, and overall design principles.
            </p>
            <p>
              The purpose of a branding style guide is to maintain brand consistency, ensure brand
              recognition, and foster a cohesive brand identity across all touchpoints and mediums.
            </p>
          </div>
        </div>
        <div className="cards">
          {CARDS.map((card) => (
            <CardStyled
              key={card.title}
              title={card.title}
              image={card.image}
              path={card.linkUrl}
              category
            >
              {card.content}
            </CardStyled>
          ))}
        </div>
        <PaginationStyled
          previousLink="/general-components"
          previous="General Components"
          previousDisabled="disabled"
          nextLink="/data-vizualization/lines"
          next="Lines"
          className="footer-pagination"
        />
      </div>
    </div>
  </div>
);

DataVizualizationStructure.defaultProps = {
  className: '',
};

const DataVizualization = styled(DataVizualizationStructure)`
  ${commonPageContentStyles}
  display: block;
  height: 100%;

  .scrollable-content {
    height: 100%;

    .hero {
      margin-bottom: 40px;
      background: url('images/data-vizualization/hero-bg-mobile.png') center center / cover
        no-repeat;
      padding: 128px 16px 32px 16px;
      border-radius: 12px;
      border: 1px solid ${({ theme }) => theme.colors.neutral.border};

      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        padding: 80px 32px;
        background: url('images/hero-category@3x.jpg') center center / cover no-repeat;
      }
      > .hero__content {
        > h1 {
          font-size: ${({ theme }) => theme.fontSizes.lg};
          font-weight: 600;
          letter-spacing: -0.03em;
          color: ${({ theme }) => theme.colors.neutral.text};
          line-height: ${({ theme }) => theme.lineHeights.lg};
          margin-bottom: 16px;

          @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
            font-size: ${({ theme }) => theme.fontSizes.xl};
            line-height: ${({ theme }) => theme.lineHeights.xl};
          }
        }

        > p {
          color: ${({ theme }) => theme.colors.neutral['text-weak']};
          font-size: ${({ theme }) => theme.fontSizes.sm};
          line-height: ${({ theme }) => theme.lineHeights.sm};
          font-weight: 400;

          &:last-of-type {
            margin-top: 23px;
          }
        }

        @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
          max-width: 63%;
        }
      }
    }

    .cards {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 16px;

      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        grid-template-columns: repeat(2, 1fr);
        gap: 40px 24px;
      }

      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    .footer-pagination {
      display: none;
      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        display: block;
      }
    }
  }
`;

export default DataVizualization;
