const navigationStyles = (submenus: number[]) => {
  const submenuStyles = submenus
    .map(
      (submenu) => `
        &:nth-of-type(${submenu}) {
          margin-left: 1.5rem;
          margin-bottom: 0;
          padding-left: 0.9375rem;
          color: var(--5c636e, #5c636e);
          transition: color 0.3s ease-in-out;

          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 0.0625rem;
            height: 100%;
            background-color: var(--dadce0, #dadce0);
            transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
          }

          &:hover:before {
            background-color: var(--0052cc, #0052cc);
          }

          &.active{
            color: var(--155676, #155676);
            &:before {
              background-color: var(--155676, #155676);
            }
          }
        }
      `,
    )
    .join('');

  return `
    .page-content .page-navigation {
      .multilevel-link-list {
        li {
          position: relative;

          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: -0.125rem;
            width: 0.125rem;
            height: 100%;
            background-color: var(--dadce0, #dadce0);
            transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
          }

          &.active,
          &:hover {
            &:before {
              background-color: var(--0052cc, #0052cc);
            }
          }

          ${submenuStyles}
        }
      }
    }
  `;
};

export default navigationStyles;
