import styled from 'styled-components';

import BadgeStyled from '../Badge';

interface ChangelogItem {
  id: number;
  color: string;
  badgeTitle?: string;
  title: string;
  date: string;
  description: string;
}

interface ChangelogProps {
  data?: ChangelogItem[];
  className?: string;
}
const Changelog = ({ className, data = [] }: ChangelogProps) => (
  <>
    {data.map(({ id, color, title, badgeTitle, date, description }) => (
      <div className={className} key={id}>
        <BadgeStyled className={color}>{badgeTitle}</BadgeStyled>
        <h3>
          <span>{title}</span> <span className="date">{date}</span>
        </h3>
        <p>{description}</p>
      </div>
    ))}
  </>
);

Changelog.defaultProps = {
  data: '',
  className: '',
};

const ChangelogStyled = styled(Changelog)`
  margin-bottom: 2.5rem;
  max-width: 40rem;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  align-items: flex-start;

  h3 {
    display: flex;
    gap: 0.5rem;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: -0.02em;
    color: var(--030404, #030404);
    margin-top: 0;
    margin-bottom: 0;
  }

  p {
    font-size: 0.875rem;
    line-height: 1.4285714286;
    color: var(--5c636e, #5c636e);
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .date {
    color: var(--5c636e, #5c636e);
    font-weight: 400;
  }
`;

export default ChangelogStyled;
