import styled from 'styled-components';

interface ColorVisionItem {
  id: number;
  color: string;
  title: string;
}

interface ColorVisionProps {
  data?: ColorVisionItem[];
  className?: string;
}
const ColorVision = ({ className, data = [] }: ColorVisionProps) => (
  <div className={className}>
    {data.map(({ id, color, title }) => (
      <div className="item" key={id}>
        <p>{title}</p>
        <div className="bg" style={{ backgroundColor: color }} />
      </div>
    ))}
  </div>
);

ColorVision.defaultProps = {
  data: [],
  className: '',
};

const ColorVisionStyled = styled(ColorVision)`
  display: flex;
  gap: 1rem;

  .item {
    flex: 1;
  }

  p {
    line-height: 1.428571428571429;
    color: var(--030404, #030404);
    margin-bottom: 0.5rem;
  }

  .bg {
    height: 1.5rem;
    border-radius: 0.1875rem;
    overflow: hidden;
  }
`;

export default ColorVisionStyled;
