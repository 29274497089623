import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as IconBuilder } from '../../images/icons/builder.svg';
import { ReactComponent as IconDesignSystem } from '../../images/icons/design-system.svg';
import { ReactComponent as IconOverview } from '../../images/icons/overview.svg';

interface Props {
  className?: string;
}

const HeaderNavigation = ({ className }: Props) => (
  <div className={className}>
    <ul>
      <li>
        <NavLink to="/overview" className={({ isActive }) => (isActive ? 'active' : '')}>
          <IconBuilder className="icon" />
          <span>Overview</span>
        </NavLink>
      </li>
      <li>
        <NavLink to="/builder" className={({ isActive }) => (isActive ? 'active' : '')}>
          <IconOverview className="icon" />
          <span>Builder</span>
        </NavLink>
      </li>
      <li>
        <NavLink to="/" className={({ isActive }) => (isActive ? 'active' : '')}>
          <IconDesignSystem className="icon" />
          <span>Design System</span>
        </NavLink>
      </li>
    </ul>
  </div>
);

HeaderNavigation.defaultProps = {
  className: '',
};

const HeaderNavigationStyled = styled(HeaderNavigation)`
  ul {
    display: flex;
    gap: 0.5rem;
    padding-left: 0;
  }

  li {
    list-style: none;
  }

  a,
  button {
    border: 0 none;
    background: transparent none;
    display: flex;
    gap: 0.5rem;
    letter-spacing: -0.01em;
    font-weight: 600;
    color: var(--5F6877, #5f6877);
    cursor: pointer;
    padding: 0.625rem;
    align-items: center;
    border-radius: 0.375rem;
    text-decoration: none;
    transition:
      color 0.3s ease-in-out,
      background 0.3s ease-in-out;
    position: relative;

    &:not(.active) {
      &:hover {
        color: var(--000005, #000005);
        background-color: var(--5f68771f, #5f68771f);

        .icon {
          color: var(--000005, #000005);
        }
      }
    }
  }

  span {
  }

  .active {
    color: var(--155676, #155676);
    background-color: var(--fff, #fff);
    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: -15px;
      left: 0;
      width: 100%;
      height: 2px;
      background: ${({ theme }) => theme.colors.primary.border};
      border-left: 1px solid ${({ theme }) => theme.colors.neutral['background-medium']};
      border-right: 1px solid ${({ theme }) => theme.colors.neutral['background-medium']};
      border-bottom: 1px solid ${({ theme }) => theme.colors.neutral['background-medium']};
      border-bottom-left-radius: 1px;
      border-bottom-right-radius: 1px;
    }
  }

  .icon {
    transition: color 0.3s ease-in-out;
  }
`;

export default HeaderNavigationStyled;
