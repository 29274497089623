import styled from 'styled-components';

interface InfoIconProps {
  children: React.ReactNode;
  className?: string;
}

const InfoIcon = ({ children, className }: InfoIconProps) => (
  <div className={className}>{children}</div>
);

InfoIcon.defaultProps = {
  className: '',
};

const InfoIconStyled = styled(InfoIcon)`
  font-size: 0.75rem;
  line-height: 1.166666666666667;
  border-radius: 0.125rem;
  display: inline-flex;
  align-items: center;
  padding: 0.125rem;
  vertical-align: middle;

  &.info {
    color: var(--155676, #155676);
    background-color: var(--2d6e8d1f, #2d6e8d1f);
    transition:
      color 0.15s ease-in-out,
      background-color 0.15s ease-in-out;
    cursor: pointer;
    margin-left: 0.25rem;
    margin-right: 0.25rem;

    &:hover {
      color: var(--ffffff, #ffffff);
      background-color: var(--2D6E8D, #2d6e8d);
    }

    svg {
      margin: 0;
    }
  }
`;

export default InfoIconStyled;
