import styled from 'styled-components';

interface Props {
  className?: string;
}

const ContentNavigation = ({ className }: Props) => (
  <div className={className}>
    <ul>
      <li>
        <button type="button" className="active">
          Get Started
        </button>
      </li>
      <li>
        <button type="button">How to Use</button>
      </li>
      <li>
        <button type="button">Design Principles</button>
      </li>
      <li>
        <button type="button" disabled>
          About
        </button>
      </li>
    </ul>
  </div>
);

ContentNavigation.defaultProps = {
  className: '',
};

const ContentNavigationStyled = styled(ContentNavigation)`
  border-bottom: 0.0625rem solid var(--dadce0, #dadce0);
  margin-bottom: 2.5rem;

  ul {
    display: flex;
    list-style: none;
    gap: 1.5rem;
    margin: 0;
    padding-left: 0;
  }

  button {
    padding: 1.5rem 0;
    font-size: 0.875rem;
    line-height: 1.1428571429;
    color: var(--5f6877, #5f6877);
    background: transparent;
    border: 0 none;
    position: relative;
    cursor: pointer;
    transition: color 0.3s ease-in-out;

    &::after {
      content: '';
      position: absolute;
      bottom: -0.0625rem;
      height: 0.125rem;
      background-color: transparent;
      width: 100%;
      left: 0;
      transition: background 0.3s ease-in-out;
    }

    &:hover {
      color: var(--000005, #000005);

      &::after {
        background: var(--9fa4ad, #9fa4ad);
      }
    }

    &.active {
      color: var(--000005, #000005);
      font-weight: 600;

      &::after {
        background: var(--2d6e8d, #2d6e8d);
      }
    }

    &:disabled {
      pointer-events: none;
      color: var(--9fa4ad, #9fa4ad);
    }
  }
`;

export default ContentNavigationStyled;
