import styled from 'styled-components';

import ContentHeaderStyled from '../../../components/ContentHeader';
import HoverableCard from '../../../components/HoverableCard';
import PaginationStyled from '../../../components/Pagination';
import { ReactComponent as ChartIcon } from '../../../images/icons/chart-bar.svg';

interface Props {
  className?: string;
}

const DiagramsPage = ({ className }: Props) => (
  <div className={className}>
    <div className="content">
      <ContentHeaderStyled
        className="breadcrumbs"
        title="Diagrams"
        breadcrumbs={[
          { label: 'Data Vizualization', url: '/data-vizualization' },
          { label: 'Diagrams Chart' },
        ]}
      />

      <div className="cards">
        <HoverableCard
          description="Grouped bar chat is useful for visually comparing data across multiple subcategories
                        within each main category. It displays multiple bars for each category with each group
                        containing bars representing different subcategories."
          title="Donut Chart"
          url="test"
          image="/images/data-viz-diagrams/sankey.png"
          icon={<ChartIcon />}
          tags={['correlation', 'proportion']}
        />
        <HoverableCard
          description="Grouped bar chat is useful for visually comparing data across multiple subcategories
                        within each main category. It displays multiple bars for each category with each group
                        containing bars representing different subcategories."
          title="Polar Area Chart"
          url="test"
          image="/images/data-viz-diagrams/treemap.png"
          icon={<ChartIcon />}
          tags={['proportion']}
        />

        <HoverableCard
          description="Grouped bar chat is useful for visually comparing data across multiple subcategories
                        within each main category. It displays multiple bars for each category with each group
                        containing bars representing different subcategories."
          title="Puncard"
          url="test"
          image="/images/data-viz-diagrams/circle-packing.png"
          icon={<ChartIcon />}
          tags={['comparison']}
        />
      </div>

      <PaginationStyled
        previousLink="/data-vizualization/circulars"
        previous="Circulars"
        nextLink="/data-vizualization/others"
        next="Others"
        className="footer-pagination"
      />
    </div>
  </div>
);

DiagramsPage.defaultProps = {
  className: '',
};

const StyledDiagramsPage = styled(DiagramsPage)`
  > .sidebar {
    overflow-x: hidden;
    height: calc(100vh - 4rem);
  }

  > .content {
    overflow-x: hidden;
    max-width: 75rem;
    margin: 0 auto;
    width: 100%;
    padding: 16px 16px 72px 16px;
    min-height: 100%;
    display: flex;
    flex-direction: column;

    @media (min-width: ${({ theme }) => theme.breakpoints['2xl']}) {
      padding: 40px 20px 16px 20px;
    }

    > .breadcrumbs {
      margin-bottom: 24px;

      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        margin-bottom: 40px;
      }
    }

    > .cards {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 16px;

      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: 80px;
      }

      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    > .footer-pagination {
      display: none;
      margin-top: auto;
      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        display: block;
      }
    }
  }
`;

export default StyledDiagramsPage;
