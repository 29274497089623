import styled from 'styled-components';

import Article from '../../../components/Article';
import Button from '../../../components/Button';
import customizationBg from '../../../images/customization-bg.jpg';
import { ReactComponent as BezierIcon } from '../../../images/icons/bezier.svg';
import { ReactComponent as DoubleCheckIcon } from '../../../images/icons/double-check.svg';
import { ReactComponent as EyeDropperIcon } from '../../../images/icons/eyedropper.svg';
import { ReactComponent as PlusIcon } from '../../../images/icons/plus.svg';
import { ReactComponent as ResizeIcon } from '../../../images/icons/resize.svg';

interface Props {
  className?: string;
}

const CustomizationTab = ({ className }: Props) => (
  <section className={className}>
    <div className="tabs-content-left">
      <div className="tab-content-header">
        <h3>Create Tailored Data Displays with Customization Options</h3>
        <div className="tabs-content-left__actions">
          <Button
            onClick={() => (window.location.href = 'https://dhcs.fuselabcreative.com/sign-in')}
            color="secondary"
            size="M"
            type="button"
          >
            <span className="button-name">Get Started</span>
            <span className="button-icon">
              <PlusIcon />
            </span>
          </Button>
        </div>
      </div>

      <div className="articles-container">
        <div>
          <Article
            boldWordsCount={2}
            icon={<DoubleCheckIcon />}
            content="Custom Data Selection allows users to filter and choose specific datasets relevant to their analysis needs."
          />
          <Article
            boldWordsCount={2}
            icon={<BezierIcon />}
            content="Interactive Elements - users can add tooltips, annotations, and drill-down capabilities for a more detailed data exploration experience."
          />
        </div>
        <div>
          <Article
            boldWordsCount={2}
            icon={<ResizeIcon />}
            content="Dynamic Scaling Enables adjustments to chart scales and axes to focus on specific data ranges or trends."
          />
          <Article
            icon={<EyeDropperIcon />}
            content="Visual Style Options Provides multiple chart types and design elements for personalized visual representation."
          />
        </div>
      </div>
    </div>

    <div className="tabs-content-right" />
  </section>
);

CustomizationTab.defaultProps = {
  className: '',
};

const StyledCustomizationTab = styled(CustomizationTab)`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  flex-direction: column-reverse;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-wrap: nowrap;
    flex-direction: row;
    min-height: 100%;
  }

  > .tabs-content-left {
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      flex-basis: 50%;
    }

    > .tab-content-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 16px;
      gap: 16px;

      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        flex-wrap: nowrap;
      }

      @media (min-width: ${({ theme }) => theme.breakpoints['2xl']}) {
        margin-bottom: 40px;
      }
      > h3 {
        font-size: ${({ theme }) => theme.fontSizes.base};
        font-weight: 600;
        line-height: ${({ theme }) => theme.lineHeights.base};
        color: ${({ theme }) => theme.colors.neutral.text};
      }

      > .tabs-content-left__actions {
        display: flex;
        gap: 24px;
      }
    }

    > .articles-container {
      display: flex;
      flex-direction: column;

      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        flex-direction: row;
        > div {
          flex-basis: 50%;
        }
      }
    }
  }

  > .tabs-content-right {
    display: none;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      display: block;
      flex-basis: 50%;
      background-image: url(${customizationBg});
      background-repeat: no-repeat;
      background-size: contain;
      background-position: bottom right;
    }
  }
`;

export default StyledCustomizationTab;
