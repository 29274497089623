import Scrollspy from 'react-scrollspy-ez';
import styled from 'styled-components';

import BadgeStyled from '../../../components/Badge';
import ContentHeaderStyled from '../../../components/ContentHeader';
import ExternalLinkGroupStyled from '../../../components/ExternalLinkGroup';
import Header from '../../../components/Header';
import PaginationStyled from '../../../components/Pagination';
import SidebarStyled from '../../../components/Sidebar';
import { ReactComponent as IconDanger } from '../../../images/icons/icon-danger.svg';
import { ReactComponent as IconSuccess } from '../../../images/icons/icon-success.svg';
import commonPageContentStyles from '../../../styles/mixins/_commonPageContentStyles';
import navigationStyles from '../../../styles/mixins/navigationMenuStyles';

interface Props {
  className?: string;
}

const dataExternalLinkGroups = [
  {
    id: 1,
    title: 'Government accessibility resources',
    cards: [
      {
        title: 'The Section 508 accessibility program',
        subtitle: '[section508.gov]',
        linkUrl: '#',
      },
      {
        title: 'Accessibility for Teams guide',
        subtitle: '[accessibility.digital.gov]',
        linkUrl: '#',
      },
      {
        title: 'Find your Section 508 Coordinator',
        subtitle: '[section508.gov]',
        linkUrl: '#',
      },
    ],
  },
];

const SelectionStructure = ({ className }: Props) => (
  <div className={className}>
    <Header className="header" />
    <SidebarStyled className="sidebar" />
    <div className="scrollable-content">
      <div className="content">
        <ContentHeaderStyled
          title="Selection"
          breadcrumbs={[
            { label: 'General Components', url: '/general-components' },
            { label: 'Selection', url: '/' },
          ]}
        />
        <div className="page-container">
          <div className="page-content">
            <div className="page-content-with-navigation-wrapper">
              <div className="page-content-with-navigation">
                <div className="page-content-item">
                  <h1 className="h1" id="overview">
                    Overview
                  </h1>
                  <h3 className="h3" id="filtering">
                    Filtering
                  </h3>
                  <p className="mb-40">
                    Filtering allows a user to add or remove data items from a displayed data set by
                    turning on and off certain predefined attributes.
                  </p>
                  <h3 className="h3" id="selection-types">
                    Selection types
                  </h3>
                  <p className="mb-40">
                    Use a single-selection filter when the user can pick only one attribute to
                    modify data results. Under the hood, single selection behaves like a radio
                    button. Types of single-selection filters include:
                  </p>
                </div>
                <table className="table table-variants mb-40">
                  <thead>
                    <tr>
                      <th style={{ width: '15.625rem' }}>Selection method</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Single selection</td>
                      <td>The user can pick only one attribute to modify data results.</td>
                    </tr>
                    <tr>
                      <td>Multiselection</td>
                      <td>
                        The user has the option to pick more than one attribute to modify data
                        results.
                      </td>
                    </tr>
                    <tr>
                      <td>Multiple categories</td>
                      <td>
                        The user has the option to select attributes across multiple data
                        categories.
                      </td>
                    </tr>
                    <tr>
                      <td>Multiple filters with batch updates</td>
                      <td>
                        The user selects multiple filters and then takes an additional action to
                        apply the filters.
                      </td>
                    </tr>
                    <tr>
                      <td>Multiple filters with instant updates</td>
                      <td>The data is updated as the user selects each filter.</td>
                    </tr>
                  </tbody>
                </table>

                <div className="page-content-item">
                  <h4 className="h4">Single selection</h4>
                  <p>
                    Use a single-selection filter when the user can pick only one attribute to
                    modify data results. Under the hood, single selection behaves like a radio
                    button. Types of single-selection filters include:
                  </p>
                  <ul className="disc-list font-sm">
                    <li>Basic dropdown</li>
                    <li>Inline dropdown</li>
                    <li>Radio button set (either standalone or within a menu)</li>
                  </ul>
                </div>
                <figure className="figure-sm">
                  <img
                    className="img-responsive"
                    src="/images/general-components-selection/screen-single-selection@3x.jpg"
                    alt=""
                  />
                  <figcaption>
                    Example of a single selection filter in an inline dropdown
                  </figcaption>
                </figure>
                <div className="page-content-item">
                  <h4 className="h4">Multiselect</h4>
                  <p>
                    Use a multiselect filter when the user has the option to pick more than one
                    attribute to modify the data results. Under the hood, multiselects behave like
                    checkboxes. Types of multiselect filters include:
                  </p>
                  <ul className="disc-list font-sm">
                    <li>Multiselect dropdown</li>
                    <li>Inline multiselect dropdown</li>
                    <li>Checkbox set (either standalone or within a menu)</li>
                  </ul>
                </div>
                <figure className="figure-sm">
                  <img
                    className="img-responsive"
                    src="/images/general-components-selection/screen-multiselect@3x.jpg"
                    alt=""
                  />
                  <figcaption>
                    Example of a single selection filter in an inline dropdown
                  </figcaption>
                </figure>
                <div className="page-content-item">
                  <p className="mb-40">
                    If the filter(s) can be hidden in either a drawer, dropdown, or menu, then there
                    should be an indicator visible on the closed filter state that informs the user
                    that filters have been applied. At a minimum, the indicator should include the
                    number of filters applied and have the option to clear filters without
                    re-opening the filter container.
                  </p>
                </div>
                <h1 className="h1 divider" id="specifications">
                  Specifications
                </h1>
                <div className="page-content-item">
                  <h3 className="h3" id="select-overview">
                    Select Overview
                  </h3>
                  <p>
                    Filtering allows a user to add or remove data items from a displayed data set by
                    turning on and off certain predefined attributes.
                  </p>
                  <p className="mb-40">
                    The select component collects user-provided information from a list of options.
                    Selects are usually used in forms where a user submits data and chooses one
                    option from a list.
                  </p>
                </div>
                <figure className="figure-sm">
                  <img
                    className="img-responsive"
                    src="/images/general-components-selection/select-overview@3x.jpg"
                    alt=""
                  />
                  <figcaption>Example of filter without selections</figcaption>
                </figure>
                <div className="page-content-item">
                  <h3 className="h3 mb-40" id="states">
                    States
                  </h3>
                </div>
                <table className="table table-variants mb-40">
                  <thead>
                    <tr>
                      <th style={{ width: '15.625rem' }}>State</th>
                      <th>Element</th>
                      <th>Property</th>
                      <th>Color token</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1. Default</td>
                      <td>Field</td>
                      <td>background-color</td>
                      <td>
                        <div className="role-color">$primary/bg-base</div>
                      </td>
                    </tr>
                    <tr>
                      <td>2. Hover</td>
                      <td>Field</td>
                      <td>background-color</td>
                      <td>
                        <div className="role-color">$neutral/bg-hover</div>
                      </td>
                    </tr>
                    <tr>
                      <td>3. Pressed</td>
                      <td>Field</td>
                      <td>background-color</td>
                      <td>
                        <div className="role-color">$neutral/bg-active</div>
                      </td>
                    </tr>
                    <tr>
                      <td>4. Disabled</td>
                      <td>Field</td>
                      <td>background-color</td>
                      <td>
                        <div className="role-color">none</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="number-cards">
                  <div className="number-card">
                    <div className="number-card-img">
                      <span className="number-circle">1</span>
                      <img
                        src="/images/general-components-selection/screen-states-01@3x.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="number-card">
                    <div className="number-card-img">
                      <span className="number-circle">2</span>
                      <img
                        src="/images/general-components-selection/screen-states-02@3x.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="number-card">
                    <div className="number-card-img">
                      <span className="number-circle">3</span>
                      <img
                        src="/images/general-components-selection/screen-states-03@3x.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="number-card">
                    <div className="number-card-img">
                      <span className="number-circle">4</span>
                      <img
                        src="/images/general-components-selection/screen-states-04@3x.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <h1 className="h1 divider" id="guidelines">
                  Guidelines
                </h1>
                <div className="page-content-item">
                  <h3 className="h3" id="content">
                    Content
                  </h3>
                  <ul className="disc-list font-sm mb-40">
                    <li>Give a short description (1–3 words) of the requested input.</li>
                    <li>
                      Be written in sentence case (the first word capitalized, the rest lowercase).
                    </li>
                    <li>Avoid punctuation and articles (“the”, “an”, “a”).</li>
                    <li>
                      Be independent sentences. To support <strong>internationalization</strong>,
                      they should not act as the first part of a sentence that is finished by the
                      component’s options.
                    </li>
                    <li>
                      Be descriptive, not instructional. If the selection needs more explanation,
                      use help text below the field.
                    </li>
                  </ul>
                  <h3 className="visually-hidden" id="radio-button">
                    Radio button
                  </h3>
                  <h3 className="h3">Radio button usage</h3>
                  <p className="mb-40">
                    Radio buttons are used for mutually exclusive choices, not for multiple choices.
                    Only one radio button can be selected at a time. When a user chooses a new item,
                    the previous choice is automatically deselected.
                  </p>
                  <p className="mb-40">
                    Can be used in tiles, data tables, modals, side panels, and in forms on full
                    pages. Used to change from one setting to another in a menu, page, or component.
                    It can often act as a filtering mechanism.
                  </p>
                </div>
                <div className="reccomendations mb-40">
                  <div className="reccomendation-item">
                    <BadgeStyled className="success" iconLeft={<IconSuccess />}>
                      Do
                    </BadgeStyled>
                    <ul className="disc-list">
                      <li>Email address</li>
                      <li>Phone number</li>
                    </ul>
                  </div>
                  <div className="reccomendation-item">
                    <BadgeStyled className="danger" iconLeft={<IconDanger />}>
                      Don&apos;t
                    </BadgeStyled>
                    <ul className="disc-list">
                      <li>What is your email address?</li>
                      <li>My phone number is:</li>
                    </ul>
                  </div>
                </div>
                <img
                  className="img-responsive mb-40"
                  src="/images/general-components-selection/screen-radio-button@3x.jpg"
                  alt=""
                />
                <div className="page-content-item">
                  <h4 className="h4">When not to use</h4>
                  <p className="mb-40">
                    If a user can select from multiple options, use checkboxes instead of radio
                    buttons. Radio buttons allow the user to select only one item in a set whereas
                    checkboxes allow the user to select multiple items.
                  </p>
                </div>
                <div className="ex-cards">
                  <div className="ex-card true">
                    <img
                      className="ex-card-img"
                      src="/images/general-components-selection/screen-radio-button-do@3x.jpg"
                      alt=""
                    />
                    <div className="ex-card-desc">
                      <BadgeStyled className="success" iconLeft={<IconSuccess />}>
                        Do
                      </BadgeStyled>
                      <p>Do use radio buttons when only one item can be selected.</p>
                    </div>
                  </div>
                  <div className="ex-card false">
                    <img
                      className="ex-card-img"
                      src="/images/general-components-selection/screen-radio-button-dont@3x.jpg"
                      alt=""
                    />
                    <div className="ex-card-desc">
                      <BadgeStyled className="danger" iconLeft={<IconDanger />}>
                        Don&apos;t
                      </BadgeStyled>
                      <p>Don&apos;t use checkboxes when only one item can be selected.</p>
                    </div>
                  </div>
                </div>
                <div className="page-content-item">
                  <h3 className="visually-hidden" id="checkbox">
                    Checkbox
                  </h3>
                  <h3 className="h3">Checkbox usage</h3>
                  <p>
                    Radio buttons are used for mutually exclusive choices, not for multiple choices.
                    Only one radio button can be selected at a time. When a user chooses a new item,
                    the previous choice is automatically deselected.
                  </p>
                  <p>
                    Can be used in tiles, data tables, modals, side panels, and in forms on full
                    pages. Used to change from one setting to another in a menu, page, or component.
                    It can often act as a filtering mechanism.
                  </p>
                </div>
                <img
                  className="img-responsive mb-40"
                  src="/images/general-components-selection/screen-checkbox-usage@3x.jpg"
                  alt=""
                />
                <div className="page-content-item">
                  <h4 className="h4">When not to use</h4>
                  <p className="mb-40">
                    If a user can select from multiple options, use checkboxes instead of radio
                    buttons. Radio buttons allow the user to select only one item in a set whereas
                    checkboxes allow the user to select multiple items.
                  </p>
                </div>
                <div className="ex-cards">
                  <div className="ex-card true">
                    <img
                      className="ex-card-img"
                      src="/images/general-components-selection/screen-use-checkboxes@3x.jpg"
                      alt=""
                    />
                    <div className="ex-card-desc">
                      <BadgeStyled className="success" iconLeft={<IconSuccess />}>
                        Do
                      </BadgeStyled>
                      <p>Do use checkboxes when multiple items can be selected.</p>
                    </div>
                  </div>
                  <div className="ex-card false">
                    <img
                      className="ex-card-img"
                      src="/images/general-components-selection/screen-use-radio-buttons@3x.jpg"
                      alt=""
                    />
                    <div className="ex-card-desc">
                      <BadgeStyled className="danger" iconLeft={<IconDanger />}>
                        Don&apos;t
                      </BadgeStyled>
                      <p>Don&apos;t use radio buttons when multiple items can be selected.</p>
                    </div>
                  </div>
                </div>
                <h1 className="h1 divider" id="references">
                  References
                </h1>
                {dataExternalLinkGroups.map((externalLinkGroup) => (
                  <ExternalLinkGroupStyled
                    key={externalLinkGroup.id}
                    title={externalLinkGroup.title}
                    cards={externalLinkGroup.cards}
                  />
                ))}
              </div>
              <div>
                <div className="page-navigation">
                  <h4>Contents</h4>
                  <Scrollspy
                    ids={[
                      'overview',
                      'filtering',
                      'selection-types',
                      'specifications',
                      'select-overview',
                      'states',
                      'guidelines',
                      'content',
                      'radio-button',
                      'checkbox',
                      'references',
                    ]}
                    offset={0}
                    activeItemClassName="active"
                    itemContainerClassName="multilevel-link-list"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <PaginationStyled
          previousLink="/general-components/navigation"
          previous="Navigation"
          nextLink="/general-components/containment"
          next="Containment"
        />
      </div>
    </div>
  </div>
);

SelectionStructure.defaultProps = {
  className: '',
};

const Selection = styled(SelectionStructure)`
  ${commonPageContentStyles}
  ${navigationStyles([2, 3, 5, 6, 8, 9, 10])}
`;

export default Selection;
