import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import Footer from '../Footer';
import Header from '../Header';
import HeaderMobile from '../HeaderNavigationMobile';

interface Props {
  className?: string;
}
// TODO: get rid of the Header wrapper after this LayoutWithHeader component is used for all pages
const LayoutWithHeader: FC = ({ className }: Props) => (
  <>
    {/* // temporary fix for the header */}
    <div className={className}>
      <div className="header">
        <Header />
      </div>
      <HeaderMobile />
      <main>
        <Outlet />
      </main>
      <Footer className="footer" />
    </div>
  </>
);

LayoutWithHeader.defaultProps = {
  className: '',
};

const StyledLayoutWithHeader = styled(LayoutWithHeader)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .header {
    display: none;

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      display: block;
    }
  }

  main {
    width: 100%;
    height: 100%;
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      display: flex;
      flex: 1;
    }
  }

  .footer {
    margin-top: auto;
  }
`;

export default StyledLayoutWithHeader;
