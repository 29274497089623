import { useEffect, useRef, useState } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import styled from 'styled-components';

import BadgeStyled from '../../../components/Badge';
import Button from '../../../components/Button';
import ContentHeaderStyled from '../../../components/ContentHeader';
import Header from '../../../components/Header';
import InfoIconStyled from '../../../components/InfoIcon';
import PaginationStyled from '../../../components/Pagination';
import SidebarStyled from '../../../components/Sidebar';
import { ReactComponent as ArrowIcon } from '../../../images/icons/arrow-right.svg';
import { ReactComponent as BuildIcon } from '../../../images/icons/build-chart.svg';
import { ReactComponent as IconBarChart } from '../../../images/icons/chart-bar.svg';
import { ReactComponent as IconCircularChart } from '../../../images/icons/chart-circular.svg';
import { ReactComponent as IconDiagramChart } from '../../../images/icons/chart-diagram.svg';
import { ReactComponent as IconLineChart } from '../../../images/icons/chart-line.svg';
import { ReactComponent as IconMapChart } from '../../../images/icons/chart-map.svg';
import { ReactComponent as IconOtherChart } from '../../../images/icons/chart-other.svg';
import { ReactComponent as IconTableChart } from '../../../images/icons/chart-table.svg';
import { ReactComponent as ComparisonIcon } from '../../../images/icons/charts-comparison.svg';
import { ReactComponent as CorrelationIcon } from '../../../images/icons/charts-correlation.svg';
import { ReactComponent as DistributionIcon } from '../../../images/icons/charts-distribution.svg';
import { ReactComponent as PorportionIcon } from '../../../images/icons/charts-proportion.svg';
import { ReactComponent as TrendIcon } from '../../../images/icons/charts-trend.svg';
import { ReactComponent as CheckboxCheckedIcon } from '../../../images/icons/checkbox-checked.svg';
import { ReactComponent as CheckboxIcon } from '../../../images/icons/checkbox.svg';
import { ReactComponent as CheckIcon } from '../../../images/icons/checkmark-1.svg';
import { ReactComponent as ChevronDown } from '../../../images/icons/chevron-down.svg';
import { ReactComponent as InfoCircle } from '../../../images/icons/info-circle.svg';
import { ReactComponent as InfoIconDimensions } from '../../../images/icons/info-icon-dimensions.svg';
import { ReactComponent as InfoIconLocation } from '../../../images/icons/info-icon-map.svg';
import { ReactComponent as InfoIconNetwork } from '../../../images/icons/info-icon-network.svg';
import { ReactComponent as InfoIconNumber } from '../../../images/icons/info-icon-number.svg';
import { ReactComponent as RadioCheckedIcon } from '../../../images/icons/radio-checked.svg';
import { ReactComponent as RadioIcon } from '../../../images/icons/radio.svg';
import { ReactComponent as ResetIcon } from '../../../images/icons/reset.svg';
import commonPageContentStyles from '../../../styles/mixins/_commonPageContentStyles';

interface Props {
  className?: string;
}

type Chart = {
  id: number;
  type: string;
  title: string;
  description: string;
  url: string;
  image: string;
  icon?: JSX.Element;
  types: string[];
};

const tooltipBorder = '1px solid var(--Neutral-border, #DADCE0)';

const charts: Chart[] = [
  {
    id: 1,
    type: 'Lines',
    title: 'Area Chart',
    description: '',
    url: '/data-vizualization/lines/area-chart',
    image: '/images/chart-match/01.jpg',
    icon: <IconLineChart />,
    types: [
      'purpose_comp',
      'purpose_trend',
      'dimensions_1dmn',
      'location_yes',
      'location_no',
      'moreThan12_yes',
      'moreThan12_no',
    ],
  },
  {
    id: 2,
    type: 'Lines',
    title: 'Area Chart - Stacked',
    description: '',
    url: '/data-vizualization/lines/area-chart-stacked',
    image: '/images/chart-match/02.jpg',
    icon: <IconLineChart />,
    types: [
      'purpose_corr',
      'purpose_trend',
      'dimensions_1dmn',
      'location_yes',
      'location_no',
      'moreThan12_yes',
      'moreThan12_no',
    ],
  },
  {
    id: 3,
    type: 'Lines',
    title: 'Line chart',
    description: '',
    url: '/data-vizualization/lines/lines-chart',
    image: '/images/chart-match/03.jpg',
    icon: <IconLineChart />,
    types: [
      'purpose_comp',
      'purpose_trend',
      'dimensions_1d1n',
      'location_yes',
      'location_no',
      'moreThan12_no',
    ],
  },
  {
    id: 4,
    type: 'Bars',
    title: 'Bar chart',
    description: '',
    url: '/data-vizualization/bars/bars-chart',
    image: '/images/chart-match/04.jpg',
    icon: <IconBarChart />,
    types: ['purpose_corr', 'dimensions_1d1n', 'location_yes', 'location_no', 'moreThan12_no'],
  },
  {
    id: 5,
    type: 'Bars',
    title: 'Bar Chart - Grouped',
    description: '',
    url: '/data-vizualization/bars/bar-chart-grouped',
    image: '/images/chart-match/05.jpg',
    icon: <IconBarChart />,
    types: ['purpose_comp', 'dimensions_md1n', 'location_yes', 'location_no', 'moreThan12_no'],
  },
  {
    id: 6,
    type: 'Bars',
    title: 'Bar Chart - Stacked',
    description: '',
    url: '/data-vizualization/bars/bar-stacked',
    image: '/images/chart-match/06.jpg',
    icon: <IconBarChart />,
    types: [
      'purpose_comp',
      'purpose_prop',
      'dimensions_md1n',
      'location_yes',
      'location_no',
      'moreThan12_no',
    ],
  },
  {
    id: 7,
    type: 'Bars',
    title: 'Bar Chart - Horizontal Grouped',
    description: '',
    url: '/data-vizualization/bars/bar-horizontal-grouped',
    image: '/images/chart-match/07.jpg',
    icon: <IconBarChart />,
    types: ['purpose_comp', 'dimensions_md1n', 'location_yes', 'location_no', 'moreThan12_yes'],
  },
  {
    id: 8,
    type: 'Bars',
    title: 'Bar Chart - Horizontal Stacked',
    description: '',
    url: '/data-vizualization/bars/bar-horizontal-stacked',
    image: '/images/chart-match/08.jpg',
    icon: <IconBarChart />,
    types: [
      'purpose_comp',
      'purpose_prop',
      'dimensions_md1n',
      'location_yes',
      'location_no',
      'moreThan12_yes',
    ],
  },
  {
    id: 9,
    type: 'Bars',
    title: 'Funnel Chart',
    description: '',
    url: '',
    image: '/images/chart-match/09.jpg',
    icon: <IconBarChart />,
    types: [
      'purpose_comp',
      'purpose_prop',
      'dimensions_1d1n',
      'dimensions_md1n',
      'location_no',
      'moreThan12_yes',
      'moreThan12_no',
    ],
  },
  {
    id: 10,
    type: 'Bars',
    title: 'Histogram',
    description: '',
    url: '',
    image: '/images/chart-match/10.jpg',
    icon: <IconBarChart />,
    types: [
      'purpose_corr',
      'purpose_dist',
      'purpose_trend',
      'dimensions_1d1n',
      'location_no',
      'moreThan12_yes',
      'moreThan12_no',
    ],
  },
  {
    id: 11,
    type: 'Bars',
    title: 'Lollipop',
    description: '',
    url: '/data-vizualization/bars/lollipop',
    image: '/images/chart-match/11.jpg',
    icon: <IconBarChart />,
    types: [
      'purpose_comp',
      'purpose_corr',
      'dimensions_1d1n',
      'location_yes',
      'location_no',
      'moreThan12_no',
    ],
  },
  {
    id: 12,
    type: 'Bars',
    title: 'Lollipop - Stacked',
    description: '',
    url: '/data-vizualization/bars/lollipop-stacked',
    image: '/images/chart-match/12.jpg',
    icon: <IconBarChart />,
    types: [
      'purpose_comp',
      'purpose_corr',
      'purpose_prop',
      'dimensions_md1n',
      'location_yes',
      'location_no',
      'moreThan12_no',
    ],
  },
  {
    id: 13,
    type: 'Bars',
    title: 'Lollipop - Horizontal',
    description: '',
    url: '/data-vizualization/bars/lollipop-horizontal',
    image: '/images/chart-match/13.jpg',
    icon: <IconBarChart />,
    types: [
      'purpose_comp',
      'purpose_corr',
      'dimensions_1d1n',
      'location_yes',
      'location_no',
      'moreThan12_yes',
    ],
  },

  {
    id: 14,
    type: 'Maps',
    title: 'Map - Choropleth',
    description: '',
    url: '/data-vizualization/maps/map-chloropleth',
    image: '/images/chart-match/14.jpg',
    icon: <IconMapChart />,
    types: ['purpose_comp', 'dimensions_1d1n', 'location_yes', 'moreThan12_yes'],
  },
  {
    id: 15,
    type: 'Maps',
    title: 'Map - Bubble',
    description: '',
    url: '/data-vizualization/maps/bubble-map',
    image: '/images/chart-match/15.jpg',
    icon: <IconMapChart />,
    types: ['purpose_comp', 'dimensions_1d1n', 'location_yes', 'moreThan12_yes'],
  },
  {
    id: 16,
    type: 'Maps',
    title: 'Map - Pikes',
    description: '',
    url: '/data-vizualization/maps/spike-map',
    image: '/images/chart-match/16.jpg',
    icon: <IconMapChart />,
    types: ['purpose_comp', 'dimensions_1d1n', 'location_yes', 'moreThan12_yes'],
  },
  {
    id: 17,
    type: 'Maps',
    title: 'Map - Scatter',
    description: '',
    url: '/data-vizualization/maps/scatter-map',
    image: '/images/chart-match/17.jpg',
    icon: <IconMapChart />,
    types: ['purpose_corr', 'dimensions_1d1n', 'location_yes', 'moreThan12_yes'],
  },

  {
    id: 18,
    type: 'Tables',
    title: 'Heatmap',
    description: '',
    url: '/data-vizualization/tables/heatmap',
    image: '/images/chart-match/18.jpg',
    icon: <IconTableChart />,
    types: [
      'purpose_comp',
      'purpose_corr',
      'dimensions_md1n',
      'location_yes',
      'location_no',
      'moreThan12_yes',
    ],
  },
  {
    id: 19,
    type: 'Tables',
    title: 'Matrix',
    description: '',
    url: '/data-vizualization/tables/matrix',
    image: '/images/chart-match/19.jpg',
    icon: <IconTableChart />,
    types: [
      'purpose_comp',
      'purpose_corr',
      'dimensions_md1n',
      'location_yes',
      'location_no',
      'moreThan12_yes',
    ],
  },
  {
    id: 20,
    type: 'Tables',
    title: 'Punchcard',
    description: '',
    url: '/data-vizualization/tables/punchcard',
    image: '/images/chart-match/20.jpg',
    icon: <IconTableChart />,
    types: [
      'purpose_comp',
      'purpose_corr',
      'dimensions_md1n',
      'location_yes',
      'location_no',
      'moreThan12_yes',
    ],
  },

  {
    id: 21,
    type: 'Circulars',
    title: 'Donut Chart',
    description: '',
    url: '/data-vizualization/circulars-chart/donut-chart',
    image: '/images/chart-match/21.jpg',
    icon: <IconCircularChart />,
    types: ['purpose_prop', 'dimensions_1d1n', 'location_no', 'moreThan12_no'],
  },
  {
    id: 22,
    type: 'Circulars',
    title: 'Polar Area Chart',
    description: '',
    url: '/data-vizualization/circulars-chart/polar-area-chart',
    image: '/images/chart-match/22.jpg',
    icon: <IconCircularChart />,
    types: ['purpose_comp', 'dimensions_1d1n', 'dimensions_md1n', 'location_no', 'moreThan12_no'],
  },
  {
    id: 23,
    type: 'Circulars',
    title: 'Radar Chart',
    description: '',
    url: '/data-vizualization/circulars-chart/radar-chart',
    image: '/images/chart-match/23.jpg',
    icon: <IconCircularChart />,
    types: ['purpose_comp', 'dimensions_1d1n', 'dimensions_md1n', 'location_no', 'moreThan12_no'],
  },

  {
    id: 24,
    type: 'Diagrams',
    title: 'Treemap',
    description: '',
    url: '/data-vizualization/diagrams/treemap',
    image: '/images/chart-match/24.jpg',
    icon: <IconDiagramChart />,
    types: [
      'purpose_prop',
      'dimensions_1d1n',
      'dimensions_md1n',
      'location_yes',
      'location_no',
      'moreThan12_no',
    ],
  },
  {
    id: 25,
    type: 'Diagrams',
    title: 'Circular Packing',
    description: '',
    url: '/data-vizualization/diagrams/circular-packing',
    image: '/images/chart-match/25.jpg',
    icon: <IconDiagramChart />,
    types: [
      'purpose_comp',
      'dimensions_1d1n',
      'dimensions_md1n',
      'location_yes',
      'location_no',
      'moreThan12_yes',
      'moreThan12_no',
    ],
  },
  {
    id: 26,
    type: 'Diagrams',
    title: 'Sankey Diagram',
    description: '',
    url: '/data-vizualization/diagrams/sankey-diagram',
    image: '/images/chart-match/26.jpg',
    icon: <IconDiagramChart />,
    types: [
      'purpose_comp',
      'purpose_corr',
      'purpose_prop',
      'purpose_trend',
      'dimensions_md1n',
      'location_yes',
      'location_no',
      'moreThan12_no',
    ],
  },

  {
    id: 27,
    type: 'Others',
    title: 'Bubble Chart',
    description: '',
    url: '/data-vizualization/others/bubble-chart',
    image: '/images/chart-match/27.jpg',
    icon: <IconOtherChart />,
    types: ['purpose_corr', 'dimensions_1dmn', 'location_no', 'moreThan12_no'],
  },
  {
    id: 28,
    type: 'Others',
    title: 'Box Plot',
    description: '',
    url: '/data-vizualization/others/box-plot',
    image: '/images/chart-match/28.jpg',
    icon: <IconOtherChart />,
    types: [
      'purpose_comp',
      'purpose_dist',
      'dimensions_1d1n',
      'dimensions_md1n',
      'location_no',
      'moreThan12_no',
    ],
  },
  {
    id: 29,
    type: 'Others',
    title: 'Box Plot - Horizontal',
    description: '',
    url: '/data-vizualization/others/box-plot-horizontal',
    image: '/images/chart-match/29.jpg',
    icon: <IconOtherChart />,
    types: [
      'purpose_comp',
      'purpose_dist',
      'dimensions_1d1n',
      'dimensions_md1n',
      'location_no',
      'moreThan12_yes',
    ],
  },
  {
    id: 30,
    type: 'Others',
    title: 'Line & Stacked Bar Chart',
    description: '',
    url: '/data-vizualization/others/line-and-stacked-bar-chart',
    image: '/images/chart-match/30.jpg',
    icon: <IconOtherChart />,
    types: ['purpose_prop', 'purpose_trend', 'dimensions_md1n', 'location_no', 'moreThan12_no'],
  },
  {
    id: 31,
    type: 'Others',
    title: 'Scatter Plot',
    description: '',
    url: '/data-vizualization/others/scatter-plot',
    image: '/images/chart-match/31.jpg',
    icon: <IconOtherChart />,
    types: ['purpose_corr', 'dimensions_1dmn', 'location_no', 'moreThan12_no'],
  },
  {
    id: 32,
    type: 'Others',
    title: 'Violin Plot',
    description: '',
    url: '/data-vizualization/others/violin-plot',
    image: '/images/chart-match/32.jpg',
    icon: <IconOtherChart />,
    types: [
      'purpose_comp',
      'purpose_dist',
      'dimensions_1d1n',
      'dimensions_md1n',
      'location_no',
      'moreThan12_no',
    ],
  },
  {
    id: 33,
    type: 'Others',
    title: 'Violin Plot - Horizontal',
    description: '',
    url: '/data-vizualization/others/violin-plot-horizontal',
    image: '/images/chart-match/33.jpg',
    icon: <IconOtherChart />,
    types: [
      'purpose_comp',
      'purpose_dist',
      'dimensions_1d1n',
      'dimensions_md1n',
      'location_no',
      'moreThan12_yes',
    ],
  },
];

const filterData = [
  {
    id: 1,
    image: '/images/chart-match/filters/chart-type-1.jpg',
    description: (
      <>
        Visually represent and compare different entities, variables, or data sets Common types
        include <strong>bar charts, column charts, and radar charts</strong>.
      </>
    ),
  },
  {
    id: 2,
    image: '/images/chart-match/filters/chart-type-2.jpg',
    description: (
      <>
        Visualize proportional relationships within a dataset, depicting each category&apos;s
        contribution to the total. Common types include{' '}
        <strong>pie charts and stacked bar charts</strong>.
      </>
    ),
  },
  {
    id: 3,
    image: '/images/chart-match/filters/chart-type-3.jpg',
    description: (
      <>
        Visually represent data patterns and trends to discern the strength and direction of the
        relationship between variables. Common types include{' '}
        <strong>scatter plots and correlation matrices</strong>.
      </>
    ),
  },
  {
    id: 4,
    image: '/images/chart-match/filters/chart-type-4.jpg',
    description: (
      <>
        Visualize represent patterns, seasonality, and long-term trends within the data. Common
        types include <strong>line charts and area charts</strong>.
      </>
    ),
  },
  {
    id: 5,
    image: '/images/chart-match/filters/chart-type-5.jpg',
    description: (
      <>
        Showcase the distribution of a dataset across different values or ranges. Common types
        include <strong>histograms, box plots, and density plots</strong>.
      </>
    ),
  },
  {
    id: 6,
    image: '/images/chart-match/filters/chart-type-6.jpg',
    description: (
      <>
        <strong>Bar Chart</strong> or <strong>Box Plot</strong> - Ideal for visualizing the
        distribution of the numeric variable across different categories.
      </>
    ),
  },
  {
    id: 7,
    image: '/images/chart-match/filters/chart-type-7.jpg',
    description: (
      <>
        <strong>Grouped Bar Chart</strong> or <strong>Parallel Coordinate Plot</strong> - Suitable
        for comparing multiple numeric variables across categories of a single dimension.
      </>
    ),
  },
  {
    id: 8,
    image: '/images/chart-match/filters/chart-type-8.jpg',
    description: (
      <>
        <strong>Stacked Bar Chart</strong> or <strong>Grouped Bar Chart</strong> with Multiple
        Dimensions - Effective in displaying how the numeric variable varies across categorical
        variables.
      </>
    ),
  },
  {
    id: 9,
    image: '/images/chart-match/filters/chart-type-9.jpg',
    description: (
      <>
        A <strong>Choropleth Map & Scatter Map</strong> is an excellent choice for visualizing the
        geographic distribution or relationship of the dimension with location.
      </>
    ),
  },
  {
    id: 10,
    image: '/images/chart-match/filters/chart-type-10.jpg',
    description: (
      <>
        Helps in visualizing percentages or proportions within a dataset and understanding the
        contribution of each category to the total.{' '}
        <strong>Pie charts and stacked bar charts</strong> are common types of proportion charts.
      </>
    ),
  },
  {
    id: 11,
    image: '/images/chart-match/filters/chart-type-11.jpg',
    description: (
      <>
        <strong>Bar Chart</strong> or <strong>Stacked Bar Chart</strong> - A bar chart or stacked
        bar chart can effectively display the count or proportion of dimensions that have more than
        12 subdimensions.
      </>
    ),
  },
  {
    id: 12,
    image: '/images/chart-match/filters/chart-type-12.jpg',
    description: (
      <>
        <strong>Pie Chart</strong> or <strong>Donut Chart</strong> - A pie chart or donut chart can
        illustrate the proportion of dimensions that do not have more than 12 subdimensions.
      </>
    ),
  },
];

const stepsData = [
  {
    id: 1,
    title: 'What is the primary purpose of data visualization?',
    key: 'purpose',
    options: [
      {
        key: 'purpose_comp',
        text: 'Comparison',
        icon: <CheckboxIcon />,
        tooltipContent: filterData[0],
      },
      {
        key: 'purpose_corr',
        text: 'Correlation',
        icon: <CheckboxIcon />,
        tooltipContent: filterData[1],
      },
      {
        key: 'purpose_dist',
        text: 'Distribution',
        icon: <CheckboxIcon />,
        tooltipContent: filterData[2],
      },
      {
        key: 'purpose_prop',
        text: 'Proportion',
        icon: <CheckboxIcon />,
        tooltipContent: filterData[3],
      },
      {
        key: 'purpose_trend',
        text: 'Trend',
        icon: <CheckboxCheckedIcon />,
        tooltipContent: filterData[4],
      },
    ],
  },
  {
    id: 2,
    title: (
      <div>
        How many dimensions
        <InfoIconStyled className="info">
          <InfoIconDimensions data-tooltip-id="tooltip-dimensions" />
        </InfoIconStyled>
        <br />
        and numerical variables
        <InfoIconStyled className="info">
          <InfoIconNumber data-tooltip-id="tooltip-numeric" />
        </InfoIconStyled>
        do you need to display?
      </div>
    ),
    key: 'dimensions',
    options: [
      {
        key: 'dimensions_1d1n',
        text: 'One dimension & one numeric variable',
        icon: <CheckboxIcon />,
        tooltipContent: filterData[5],
      },
      {
        key: 'dimensions_1dmn',
        text: 'One dimension & several numeric variables',
        icon: <CheckboxIcon />,
        tooltipContent: filterData[6],
      },
      {
        key: 'dimensions_md1n',
        text: 'Several dimensions & one numeric variable',
        icon: <CheckboxIcon />,
        tooltipContent: filterData[7],
      },
    ],
  },
  {
    id: 3,
    title: (
      <div>
        Is the dimension related to location
        <InfoIconStyled className="info">
          <InfoIconLocation data-tooltip-id="tooltip-location" />
        </InfoIconStyled>
        ?
      </div>
    ),
    key: 'location',
    options: [
      { key: 'location_yes', text: 'Yes', icon: <CheckboxIcon />, tooltipContent: filterData[8] },
      { key: 'location_no', text: 'No', icon: <CheckboxIcon />, tooltipContent: filterData[9] },
    ],
  },
  {
    id: 4,
    title: (
      <div>
        Do any of the dimensions have more than 12 subdimensions
        <InfoIconStyled className="info">
          <InfoIconNetwork data-tooltip-id="tooltip-subdimensions" />
        </InfoIconStyled>
        ?
      </div>
    ),
    key: 'moreThan12',
    options: [
      {
        key: 'moreThan12_yes',
        text: 'Yes',
        icon: <CheckboxIcon />,
        tooltipContent: filterData[10],
      },
      { key: 'moreThan12_no', text: 'No', icon: <CheckboxIcon />, tooltipContent: filterData[11] },
    ],
  },
];

const groupedByType = charts.reduce(
  (acc, chart) => {
    if (!acc[chart.type]) {
      acc[chart.type] = [];
    }
    acc[chart.type].push(chart);
    return acc;
  },
  {} as Record<string, Chart[]>,
);

const ChartMatchStructure = ({ className }: Props) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [currentStepHeight, setCurrentStepHeight] = useState(0);
  const [isScrolled, setIsScrolled] = useState(false);
  const [filters, setFilters] = useState<Record<string, string | string[]>>({});
  const [filteredCharts, setFilteredCharts] = useState(charts); // Initialize with all charts
  const stepRefs = useRef<(HTMLDivElement | null)[]>([]);
  const scrollableContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const selectedKeys = Object.values(filters);
    if (selectedKeys.length === 0) {
      setFilteredCharts(charts);
    }

    /* let purposeFilteredCharts;
    if (filters.purpose) {
      const selectedKeys = Array.isArray(filters.purpose) ? filters.purpose : [filters.purpose];
      purposeFilteredCharts = charts.filter((chart) =>
        selectedKeys.some((key) => chart.types.includes(key)),
      );
    } else {
      purposeFilteredCharts = charts;
    } */

    if (selectedKeys.length > 0) {
      const flatKeys = selectedKeys.flat();
      const newFilteredCharts = charts.filter((chart) =>
        flatKeys.every((key) => chart.types.includes(key)),
      );
      setFilteredCharts(newFilteredCharts);
    }
  }, [filters]);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollableContainerRef.current) {
        setIsScrolled(scrollableContainerRef.current.scrollTop > 0);
      }
    };

    const container = scrollableContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const handleStepChange = (nextStep: number, key: string, value: string) => {
    setFilters((prevFilters) => {
      if (key === 'purpose') {
        const currentValues: string[] = (prevFilters[key] as string[]) || [];
        const newValues = currentValues.includes(value)
          ? currentValues.filter((v: string) => v !== value) // Remove value if already selected
          : [...currentValues, value]; // Add value if not selected

        return {
          ...prevFilters,
          [key]: newValues,
        };
      }
      return {
        ...prevFilters,
        [key]: value,
      };
    });

    if (nextStep > currentStep) setCurrentStep(nextStep);

    const stepElement = stepRefs.current[nextStep];
    if (stepElement) {
      setCurrentStepHeight(stepElement.offsetHeight);
    }
  };

  useEffect(() => {
    if (stepRefs.current[currentStep] && scrollableContainerRef.current) {
      const container = scrollableContainerRef.current;
      const containerTop = container.getBoundingClientRect().top;
      const elementTop = stepRefs.current[currentStep]?.getBoundingClientRect().top || 0;
      const scrollPosition = elementTop - containerTop + container.scrollTop;
      container.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    }
  }, [currentStep]);

  const renderCheckbox = (stepKey: string, optionKey: string, index: number) => {
    const CheckedIcon = index === 0 ? CheckboxCheckedIcon : RadioCheckedIcon;
    const UncheckedIcon = index === 0 ? CheckboxIcon : RadioIcon;

    if (Array.isArray(filters[stepKey])) {
      return filters[stepKey].includes(optionKey) ? <CheckedIcon /> : <UncheckedIcon />;
    }
    return filters[stepKey] === optionKey ? <CheckedIcon /> : <UncheckedIcon />;
  };

  const renderChartPreview = (chart: Chart) => (
    <div key={chart.id} className="chart-block">
      <div className="chart-preview">
        <img className="chart-img img-responsive" src={chart.image} alt="" />
        <div className="chart-title">
          <div className="icon-container">{chart.icon}</div>
          {chart.title}
        </div>
      </div>
      <div className="chart-details">
        <div className="chart-title">
          <div className="icon-container">{chart.icon}</div>
          {chart.title}
        </div>
        <div className="chart-description">
          <div className="top-buttons">
            {chart.types.includes('purpose_comp') && (
              <div className="type-badge">
                <ComparisonIcon />
                Comparison
              </div>
            )}
            {chart.types.includes('purpose_corr') && (
              <div className="type-badge">
                <CorrelationIcon />
                Correlation
              </div>
            )}
            {chart.types.includes('purpose_dist') && (
              <div className="type-badge">
                <DistributionIcon />
                Distribution
              </div>
            )}
            {chart.types.includes('purpose_prop') && (
              <div className="type-badge">
                <PorportionIcon />
                Proportion
              </div>
            )}
            {chart.types.includes('purpose_trend') && (
              <div className="type-badge">
                <TrendIcon />
                Trend
              </div>
            )}
          </div>
          Grouped bar chat is useful for visually comparing data across multiple subcategories
          within each main category. It displays multiple bars for each category with each group
          containing bars representing different subcategories.
        </div>
        <div className="chart-buttons">
          <a href={chart.url} title="">
            Details
          </a>
          <div className="right">
            <Button
              color="secondary"
              className="reset-filters"
              size="S"
              type="button"
              onClick={() => {
                setFilters({});
                setCurrentStep(0);
              }}
            >
              <span className="button-name">Build</span>
              <span className="button-icon">
                <BuildIcon />
              </span>
            </Button>
            <Button
              color="primary"
              className="reset-filters"
              size="S"
              type="button"
              onClick={() => {
                setFilters({});
                setCurrentStep(0);
              }}
            >
              <span className="button-name">Download</span>
              <span className="button-icon">
                <ChevronDown />
              </span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={className}>
      <Header className="header" />
      <SidebarStyled className="sidebar" />
      <div className="scrollable-content">
        <div className="content">
          <ContentHeaderStyled
            title="ChartMatch"
            breadcrumbs={[
              { label: 'Design System', url: '/data-vizualization' },
              { label: 'ChartMatch', url: '#' },
            ]}
          />
          <div className="page-container">
            <div className="page-content">
              <div className="page-content-with-quiz-wrapper">
                <div className="sticky">
                  {Object.keys(filters).length === 0 && (
                    <div className="quiz-tip">
                      Leverage the Data Viz Filter feature for tailored visualization
                      recommendations based on your specific needs.
                    </div>
                  )}
                  <div className="quiz">
                    <h4>Data Viz Filtering</h4>
                    <div className="quiz-text">
                      Answer questions to discover the visualization that best suits your needs.
                      <div
                        className="transparent-element"
                        style={{ opacity: isScrolled ? 1 : 0 }}
                      />
                    </div>

                    {filterData.map((filter) => (
                      <ReactTooltip
                        key={filter.id}
                        noArrow
                        className="tooltip-w-shadow"
                        id={`tooltip-${filter.id}`}
                        variant="light"
                        opacity={1}
                        border={tooltipBorder}
                        place="bottom"
                      >
                        <div className="tooltip-content">
                          <img src={filter.image} alt="" />
                          <p>{filter.description}</p>
                        </div>
                      </ReactTooltip>
                    ))}
                    <ReactTooltip
                      className="tooltip-w-shadow"
                      id="tooltip-dimensions"
                      variant="light"
                      opacity={1}
                      border={tooltipBorder}
                      place="right"
                    >
                      <div className="tooltip-content badges">
                        <div className="badge-row">
                          <BadgeStyled className="info">Race/Ehtnicity</BadgeStyled>
                          <BadgeStyled className="info">Age Group</BadgeStyled>
                        </div>
                        <div className="badge-row">
                          <BadgeStyled className="info">County</BadgeStyled>
                          <BadgeStyled className="info">Health Plan</BadgeStyled>
                          <BadgeStyled className="info">Sex</BadgeStyled>
                          <BadgeStyled className="info">...</BadgeStyled>
                        </div>
                      </div>
                    </ReactTooltip>
                    <ReactTooltip
                      className="tooltip-w-shadow"
                      id="tooltip-numeric"
                      variant="light"
                      opacity={1}
                      border={tooltipBorder}
                      place="right"
                    >
                      <div className="tooltip-content badges">
                        <div className="badge-row">
                          <BadgeStyled className="info">Members</BadgeStyled>
                          <BadgeStyled className="info">Population</BadgeStyled>
                        </div>
                        <div className="badge-row">
                          <BadgeStyled className="info">Payment Allocation</BadgeStyled>
                          <BadgeStyled className="info">...</BadgeStyled>
                        </div>
                      </div>
                    </ReactTooltip>
                    <ReactTooltip
                      className="tooltip-w-shadow"
                      id="tooltip-location"
                      variant="light"
                      opacity={1}
                      border="1px solid var(--Neutral-border, #DADCE0)"
                      place="right"
                    >
                      <div className="tooltip-content badges">
                        <div className="badge-row">
                          <BadgeStyled className="info">County</BadgeStyled>
                          <BadgeStyled className="info">Zip Code</BadgeStyled>
                        </div>
                        <div className="badge-row">
                          <BadgeStyled className="info">Region</BadgeStyled>
                          <BadgeStyled className="info">...</BadgeStyled>
                        </div>
                      </div>
                    </ReactTooltip>
                    <ReactTooltip
                      className="tooltip-w-shadow"
                      id="tooltip-subdimensions"
                      variant="light"
                      opacity={1}
                      border="1px solid var(--Neutral-border, #DADCE0)"
                      place="right"
                    >
                      <div className="tooltip-content badges">
                        <div className="badge-row">
                          <BadgeStyled className="info">Hispanic</BadgeStyled>
                          <BadgeStyled className="info">80-84</BadgeStyled>
                          <BadgeStyled className="info">Alameda</BadgeStyled>
                        </div>
                        <div className="badge-row">
                          <BadgeStyled className="info">CalOptima</BadgeStyled>
                          <BadgeStyled className="info">Male</BadgeStyled>
                          <BadgeStyled className="info">...</BadgeStyled>
                        </div>
                      </div>
                    </ReactTooltip>
                    <div
                      className="quiz-steps"
                      ref={scrollableContainerRef}
                      style={{ height: currentStepHeight > 350 ? currentStepHeight : 350 }}
                    >
                      {stepsData.map((step, index) => (
                        <div
                          key={step.id}
                          ref={(el) => {
                            stepRefs.current[index] = el;
                          }}
                          className={`quiz-step ${currentStep >= index ? 'active' : ''}`}
                        >
                          <div className="quiz-step-title">
                            <span>{step.id}</span>
                            {step.title}
                          </div>
                          {step.options.map((option) => (
                            <button
                              onClick={() =>
                                handleStepChange(
                                  index === 0 ? index : index + 1,
                                  step.key,
                                  option.key,
                                )
                              }
                              type="button"
                              className="quiz-option"
                            >
                              <div className="quiz-option-checkbox">
                                {renderCheckbox(step.key, option.key, index)}
                              </div>
                              <div className="quiz-option-title">
                                {option.text}
                                <InfoCircle
                                  data-tooltip-id={`tooltip-${option.tooltipContent.id}`}
                                />
                              </div>
                            </button>
                          ))}
                        </div>
                      ))}
                    </div>
                    {Object.keys(filters).length === 4 && (
                      <div className="filter-complete">
                        <CheckIcon />
                        <div className="filter-complete-tip">
                          <div className="filter-complete-title">Charts has been filtered</div>
                          <div className="filter-complete-text">
                            Please have an option from the list to view additional details.
                          </div>
                        </div>
                      </div>
                    )}
                    {currentStep > 0 && (
                      <Button
                        color="secondary"
                        className="reset-filters"
                        size="M"
                        type="button"
                        onClick={() => {
                          setFilters({});
                          setCurrentStep(0);
                        }}
                      >
                        <span className="button-name">Reset selections</span>
                        <span className="button-icon">
                          <ResetIcon />
                        </span>
                      </Button>
                    )}
                    {Object.keys(filters).length === 1 && currentStep === 0 && (
                      <Button
                        color="secondary"
                        className="reset-filters"
                        size="M"
                        type="button"
                        onClick={() => {
                          setCurrentStep(1);
                        }}
                      >
                        <span className="button-name">Confirm</span>
                        <span className="button-icon">
                          <ArrowIcon />
                        </span>
                      </Button>
                    )}
                  </div>
                </div>
                <div className="page-content-with-quiz">
                  {Object.keys(filters).length === 0 ? (
                    <>
                      {Object.entries(groupedByType).map(([type, charts]) => (
                        <section key={type}>
                          <h2>{type}</h2>
                          <div className="charts-container">
                            {charts.map((chart) => renderChartPreview(chart))}
                          </div>
                        </section>
                      ))}
                    </>
                  ) : (
                    <div className="charts-container">
                      {filteredCharts.map((chart) => renderChartPreview(chart))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <PaginationStyled previous="Get Started" previousLink="/" />
        </div>
      </div>
    </div>
  );
};

ChartMatchStructure.defaultProps = {
  className: '',
};

const ChartMatch = styled(ChartMatchStructure)`
  ${commonPageContentStyles}

  .sticky {
    position: sticky;
    top: 1rem;
  }

  .quiz {
    width: 21.25rem;
    border: 0.0625rem solid var(--dfe1e4, #dfe1e4);
    border-radius: 0.5rem;
    padding: 1.5rem;
    position: relative;

    > h4 {
      margin-bottom: 0.45rem;
    }

    &-tip {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.25rem;
      margin-bottom: 2rem;
      margin-top: 1.25rem;
      width: 21.25rem;
      letter-spacing: -0.01rem;
      color: var(--5C636E, #5c636e);
    }

    .tooltip-w-shadow {
      box-shadow:
        0rem 0.25rem 0.5rem 0.125rem rgba(20, 28, 44, 0.06),
        0rem 0.125rem 0.25rem 0rem rgba(20, 28, 44, 0.06);
      padding: 1rem;
    }

    .filter-complete {
      display: flex;
      align-items: flex-start;
      gap: 0.5rem;
      padding: 0.75rem;
      background: var(--2685a11f, #2685a11f);
      margin-top: 2rem;
      border-radius: 0.25rem;

      > svg {
        color: var(--006b8a, #006b8a);
        min-width: 1.25rem;
      }

      &-title {
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.25rem;
        color: var(--006b8a, #006b8a);
      }

      &-text {
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem;
      }
    }

    .tooltip-content {
      display: flex;
      max-width: 23rem;
      align-items: flex-start;
      font-size: 0.75rem;
      line-height: 0.875rem;
      font-weight: 400;
      gap: 0.75rem;

      img {
        min-width: 50%;
        width: 50%;
      }

      &.badges {
        gap: 0.375rem;
        flex-wrap: wrap;
        width: auto;
        flex-direction: column;

        .info {
          white-space: nowrap;
          font-weight: 400;
        }

        .badge-row {
          display: flex;
          gap: 0.375rem;
        }
      }
    }

    .reset-filters {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 1.5rem;

      .button-name,
      .button-icon {
        color: var(--5F6877, #5f6877);
      }
    }

    &-text {
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.25rem;
      position: relative;
    }

    .transparent-element {
      position: absolute;
      top: 100%;
      width: 100%;
      height: 5rem;
      transition: opacity 0.3s;
      background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
      pointer-events: none;
    }

    &-steps {
      overflow-y: auto;
    }

    &-step {
      max-height: 0;
      display: none;

      &-title {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-shrink: 0;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 1.25rem;

        > div {
          > svg {
            margin-left: 0.25rem;
            margin-right: 0.25rem;
            vertical-align: middle;
          }
        }

        > span {
          padding: 0.25rem;
          background-color: var(--00143f, #00143f);
          border-radius: 0.25rem;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          color: white;
          min-width: 1.375rem;
          min-height: 1.375rem;
          font-size: 0.75rem;
          font-weight: 500;
          line-height: 0.875rem;
          flex-shrink: 0;
          margin-right: 0.5rem;
        }
      }

      &.active {
        max-height: 40rem;
        display: block;
      }
    }

    &-option {
      display: flex;
      cursor: pointer;
      appearance: none;
      background: transparent;
      border: none;
      width: 100%;
      padding-left: 0;
      padding-right: 0;

      &:not(:first-of-type) {
        padding-top: 0.5rem;
      }

      &-title {
        border-bottom: 1px dashed var(--dfe1e4, #dfe1e4);
        display: flex;
        justify-content: space-between;
        line-height: 1.25rem;
        font-size: 0.875rem;
        font-weight: 400;
        padding-bottom: 0.84rem;
        width: 100%;
        text-align: left;

        svg {
          margin-left: 0.25rem;
          color: var(--2685a1, #2685a1);
        }
      }
      &-checkbox {
        margin-right: 0.5rem;
      }
    }
  }

  .charts-container {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: 1rem;
    border-bottom: 1px dashed var(--dfe1e4, #dfe1e4);
    padding-bottom: 1.4rem;

    @media (min-width: 1620px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .chart-preview {
      position: relative;
      transform: translateY(0);
      transition: transform 0.2s ease-in-out;
      padding-top: 0.5rem;
    }

    .chart-block {
      border-radius: 0.5rem;
      border: 0.0625rem solid var(--dfe1e4, #dfe1e4);
      overflow: hidden;
      background: transparent;
      position: relative;
      padding: 0;

      &:hover {
        .chart-preview {
          transform: translateY(-100%);
        }

        .chart-details {
          top: 0;
        }
      }

      .type-badge {
        padding: 0.375rem;
        font-size: 0.625rem;
        line-height: 0.875rem;
        display: flex;
        align-items: center;
        color: var(--000005, #000005);
        border-radius: 4px;
        background: rgba(95, 104, 119, 0.08);

        svg {
          padding-right: 0.375rem;
          margin-right: 0.375rem;
          border-right: 1px solid var(--dfe1e4, #dfe1e4);
          color: var(--5f6877, #5f6877);
          width: 1.25rem;
        }
      }

      .chart-details {
        position: absolute;
        display: flex;
        flex-direction: column;
        top: 100%;
        //top: 0;
        transition: top 0.2s ease-in-out;
        height: 100%;
        width: 100%;
        z-index: 10;
        background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 32.12%,
            rgba(255, 255, 255, 0.5) 93.2%
          ),
          var(--Neutral-background, rgba(116, 124, 137, 0.08));

        .chart-title {
          margin-top: 0;
          padding-top: 1rem;
          background: transparent;

          .icon-container {
            color: white;
            background-color: var(--2d6e8d, #2d6e8d);
          }
        }

        .chart-description {
          margin-top: 0;
          flex: 1;
          color: var(--5c636e, #5c636e);
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 0.75rem;
          line-height: 0.875rem;
          padding-left: 0.75rem;
          padding-right: 0.75rem;

          .top-buttons {
            display: flex;
            gap: 0.5rem;
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
          }
        }

        .chart-buttons {
          margin-top: 0;
          padding: 1rem 0.75rem;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .right {
            display: flex;
            gap: 0.5rem;
          }

          a {
            font-weight: 500;
            font-size: 0.875rem;
          }
        }
      }

      .chart-img {
        width: 83%;
        height: auto;
        display: block;
        margin: 0 auto 0.5rem;
      }

      .chart-title {
        margin-top: 0.5rem;
        background-color: var(--5f687714, #5f687714);
        padding: 0.5rem 0.75rem;
        display: flex;
        align-items: center;

        .icon-container {
          margin-right: 0.5rem;
          color: var(--2d6e8d, #2d6e8d);
          padding: 0.125rem;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0rem 0.125rem 0.25rem 0.0625rem var(--141c2c0a, #141c2c0a);
          background: white;
          border-radius: 0.25rem;
        }
      }
    }
  }
`;

export default ChartMatch;
